import "../../styles/ManagePage/Enterprise/BrandMode.scss";
import {useEffect, useState} from "react";
import register from "../../service/register/register";
import {Button, Col, Form, Input, Modal, Radio, Row, Upload} from "antd";
import {PlusOutlined,CloseCircleOutlined} from "@ant-design/icons";
import uploadImg from "../../assest/utils/upload";

const getBase64 = (file: RcFile) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

export default (props) => {
  const {brandMode, setBrandMode,setBrandData,brandDict} = props
  const [BrandLibraryMode, setBrandLibraryMode] = useState(false); // 品牌库弹窗
  const [BrandLibraryData, setBrandLibraryData] = useState({});
  const [BrandLibraryVlaue, setBrandLibraryVlaue] = useState(100);
  const [brandLogoImageList, setbrandLogoImageList] = useState([]);
  const [perviewImg, setPerviewImg] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [radioDisabled, setRadioDisabled] = useState(false);
  const [brandForm] = Form.useForm();
  // 选择品牌 确认
  const brandModeOk = () => {
    setBrandData({id: BrandLibraryVlaue,...BrandLibraryData})
    setBrandMode(false)
  }
  // 选择品牌 取消
  const brandModeCancel = () => {
    setBrandMode(false)
  }
  // 申请加入品牌库
  const BrandLibrary = () => {
    setBrandLibraryMode(true)
  }
  const BrandLibraryModeOk = (values) => {
    setBrandLibraryData({
      brandLogo: values.brandLogo.fileList[0].url,
      brandName: values.brandName
    })
    brandForm.resetFields(['brandLogo','brandName'])
    setRadioDisabled(true)
    setBrandLibraryVlaue(100)
    setBrandLibraryMode(false)
  }
  const BrandLibraryCancel = () => {
    setBrandLibraryData({})
    setbrandLogoImageList([])
    setRadioDisabled(false)
    setBrandLibraryVlaue(100)
    setBrandLibraryMode(false)
  }
  // 删除图片
  const delImg = (file, name, {fileData, fileMeth}) => {
    let {uid} = file;
    let filInd = fileData.findIndex((el) => uid === el.uid);
    if (filInd !== -1) {
      let newfile = JSON.parse(JSON.stringify(fileData));
      newfile.splice(filInd, 1);
      fileMeth(newfile);
      brandForm.setFieldsValue({
        [name]: newfile.map((el) => el.url),
      });
    }
  };
  // 预览logo
  const handlePreview = async (name, file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPerviewImg(file.url || file.preview);
    setShowPreview(true);
  };
  // 上传logo预览
  const brandLogoPerview = () => {
    setPerviewImg([BrandLibraryData.brandLogo])
    setShowPreview(true);
  }
  const uploadButton = (
      <div>
        <PlusOutlined/>
      </div>
  );
  // 品牌库logo上传
  const brandLogoListBeforeUpload = async (file) => {
    let data = await uploadImg(file);
    file.uid = data.name.split('/')[1];
    file.url = data.url;
    let arr = JSON.parse(JSON.stringify(brandLogoImageList));
    arr.push(file);
    setbrandLogoImageList(arr);
    return false;
  };
  const delBrandLibraryData = () => {
    setRadioDisabled(false)
    setBrandLibraryVlaue(100)
    setbrandLogoImageList([])
    setBrandLibraryData({});
  }
  const handleCancel = () => setShowPreview(false);
  return (
      <Modal title="选择品牌" width={800} open={brandMode} onOk={brandModeOk} onCancel={brandModeCancel}>
        <Radio.Group onChange={(e) => setBrandLibraryVlaue(e.target.value)} value={BrandLibraryVlaue} disabled={radioDisabled}>
          <Row className="row_brand">
            {brandDict.map((item) =>
            <Col span={11} key={item.itemCode} className="col_brand">
              <Radio value={item.itemCode}>
                <img className={'imgList'} src={item.iconAddress} alt=""/>
              </Radio>
            </Col>)}
          </Row>
        </Radio.Group>
        <div style={{marginTop: '20px', color: 'rgb(247, 176, 83)', fontSize: '16px'}}>
          *以上没有您的品牌，可以选择【独立门店】；如果您的品牌有三个以上的门店或拥有商标权，可申请加入品牌库！
        </div>
        {
          !BrandLibraryData.brandLogo ?
              <Button style={{marginTop: '20px'}} icon={<PlusOutlined/>} onClick={BrandLibrary}>申请加入品牌库</Button> :
              (
                  <div style={{marginTop: '20px'}}>
                    已申请:{BrandLibraryData.brandName} &nbsp;&nbsp;
                    <span onClick={brandLogoPerview} style={{cursor: 'pointer',color: 'rgb(2, 167, 240)'}}>图</span> &nbsp;&nbsp;
                    <CloseCircleOutlined style={{cursor: 'pointer'}} onClick={delBrandLibraryData} />&nbsp;&nbsp;
                    <span style={{color: 'rgb(247, 176, 83)'}}> *您的品牌：默认选择独立门店，相关人员审核通过后，变为"申请品牌"</span>
                  </div>
              )
        }

        <Modal title="申请加入品牌库" width={500} open={BrandLibraryMode}
               footer={null}>
          <div style={{color: 'rgb(247, 176, 83)', marginTop: '20px', marginBottom: '20px'}}>
            *请填写品牌名称，上传图标
          </div>
          <Form
              form={brandForm}
              name="basic"
              labelCol={{
                span: 4,
              }}
              wrapperCol={{
                span: 20,
              }}
              style={{
                maxWidth: 600,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={BrandLibraryModeOk}
              onFinishFailed={BrandLibraryCancel}
              autoComplete="off"
          >
            <Form.Item
                label="品牌名称"
                name="brandName"
                rules={[
                  {
                    required: true,
                    message: '请输入品牌名称',
                  },
                ]}
            >
              <Input/>
            </Form.Item>
            <Form.Item
                label="品牌图标"
                name="brandLogo"
                rules={[
                  {
                    required: true,
                    message: '请上传品牌图标',
                  },
                ]}
            >
              <Upload
                  fileList={brandLogoImageList}
                  beforeUpload={brandLogoListBeforeUpload}
                  onPreview={(file) => handlePreview("brandLogoImageList", file)}
                  onRemove={(file) =>
                      delImg(file, "brandLogoImageList", {
                        fileData: brandLogoImageList,
                        fileMeth: setbrandLogoImageList,
                      })
                  }
                  listType="picture-card"
              >
                {brandLogoImageList.length >= 1 ? null : uploadButton}
              </Upload>
            </Form.Item>
            <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
            >
              <Button htmlType="reset" style={{marginRight: '10px'}} onClick={BrandLibraryCancel}>取消</Button>
              <Button type="primary" htmlType="submit">确认</Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal open={showPreview} title="预览图片" footer={null} onCancel={handleCancel}>
          <img alt="example" style={{width: '100%'}} src={perviewImg}/>
        </Modal>
      </Modal>
  )
}
