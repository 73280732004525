import axios from '../axios'
import request_url from '../../assest/utils/request_url'
let baseUrl = request_url('enterpriseRecord')
export default {
  // 账号密码登录
  logon(data) {
    return axios.post(`${baseUrl}archives/merchant/management/logon`,data)
  },
  // 忘记密码-校验
  forgotPasswordCheck(data) {
    return axios.post(`${baseUrl}archives/merchant/management/forgotPasswordCheck`,data)
  },
  // 忘记密码
  forgotPassword(data) {
    return axios.post(`${baseUrl}archives/merchant/management/forgotPassword`,data)
  },
}
