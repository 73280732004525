// import { useState,useEffect } from "react";
import React from "react";
import "../styles/App.scss";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import ManagePage from '../views/ManagePage'
import Login from './Register/login'
import register from './Register/Register'
import Error404 from '../views/error404'
import {ConfigProvider} from "antd";
import appraise from "./Appraise_H5/appraise";
import userReview from "./Appraise_H5/userReview";
import behavior from "./BehaviorRecords/index";
import Publicity from "./Publicity/index";
import InstitutionalDetails from "./Details/InstitutionalDetails";
import PersonnelDetails from "./Details/PersonnelDetails";
import InstitutionalDetailsH5 from "./Details_H5/InstitutionalDetails";
import PersonnelDetailsH5 from "./Details_H5/PersonnelDetails";

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = '唐山市房中协'
  }

  render() {
    return (
      <>
        <ConfigProvider
          componentSize={'middle'}
          theme={{
            token: {
              // 调整主题色
              colorPrimary: '#b71c3a',
              borderRadius: 0,
              // 派生变量，影响范围小
              // colorBgContainer: '#f6ffed',
            },
          }}>
          <Router>
            <Switch>
              {/* 无token可以访问 外部页面 */}
              {/*行为记录*/}
              <Route path="/behavior" component={behavior}/>
              {/*信息公示*/}
              <Route path="/publicity" component={Publicity}/>
              {/*从业人员信息查询*/}
              <Route path="/appraise" component={appraise}/>
              {/*发表评价*/}
              <Route path="/userReview" component={userReview}/>
              {/*登录*/}
              <Route path="/login/" component={Login}/>
              {/*注册*/}
              <Route path="/register/" component={register}/>
              {/*人员详情 判断是否为移动端*/}
              <Route path="/personnel/:id" exact render={({match}) => (/Mobi|Android|iPhone/i.test(navigator.userAgent)
                  ? <PersonnelDetailsH5 id={match.params.id} /> : <PersonnelDetails id={match.params.id} />)}/>
              {/*机构详情 判断是否为移动端*/}
              <Route path="/institutional/:id" exact render={({match}) => (/Mobi|Android|iPhone/i.test(navigator.userAgent)
                  ? <InstitutionalDetailsH5 id={match.params.id} /> : <InstitutionalDetails id={match.params.id} />)}/>

              {/*需要token*/}
              <Route path="/managePage/" component={ManagePage}/>
              <Route path="/" exact render={() => (
                sessionStorage.getItem('isLogin') === 'true' ? (
                  <Redirect to="/managePage/Personnel"/>) : (<Redirect to="/login"/>))}/>
              <Route path="/error404/" component={Error404}/>
              <Redirect to="/error404"/>
            </Switch>
          </Router>
        </ConfigProvider>
      </>
    );
  }
}

export default App;
