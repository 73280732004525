import practitionersRegistration from "../../service/ManagePage/Personnel/Personnel";
import {useEffect, useRef, useState} from "react";
import '../../styles/BehaviorRecords/BehaviorRecords.scss'
import {Table} from "antd";

export default (props) => {
  const {searchName,onSearch} = props;
  const [agencyList, setAgencyList] = useState([]);// 列表
  const [agencyHead, setAgencyHead] = useState([]);// 表头
  const [tableLoading, setTableLoading] = useState(false)// 表格loading
  const pageInfo = useRef({page: 1, size: 20});
  const [total, setTotal] = useState(0);// 总数
  useEffect(() => {
    getAgencyList();
    getAgencyHead();
  }, []);
  useEffect(() => {
    getAgencyList();
  }, [onSearch]);
  // 获取表头
  const getAgencyHead = async () => {
    let res = await practitionersRegistration.tableHeader({requestParam:'badBehaviorList'});
    res.map((el, ind) => {
      {
        el.key = el.data
        el.dataIndex = el.data
        el.title = el.headerFirst
      }
    })
    setAgencyHead(res);
  }
  // 切换列表
  const onPageChange = (page, size) => {
    pageInfo.current = {page, size};
    getAgencyList();
  }
  // 获取列表
  const getAgencyList = async () => {
    try {
      setTableLoading(true)
      let data = await practitionersRegistration.badBehaviorList({page:pageInfo.current.page,
        size:pageInfo.current.size,keywords: searchName});
      setAgencyList(data.list);
      setTotal(data.totalCount);
      setTableLoading(false);
    } catch (error) {
      setTableLoading(false);
      console.log('error', error);
    }
  }
  return (
    <div id="BadBehavior">
      <div className="organ-util">
        <div className="util-left">
          <div className="title">
            不良行为记录
          </div>
        </div>
        <div className="organ-right"></div>
      </div>
      <div className="organ-contain">
        <div className="organ-table">
          <Table
            columns={agencyHead}
            dataSource={agencyList}
            size="small"
            bordered
            scroll={{x: '100%', y: '520px'}}
            loading={tableLoading}
            rowClassName="organManagement-row"
            pagination={
              {
                showSizeChanger: true,
                defaultPageSize: 20,
                total: total,
                current: pageInfo.page,
                onChange: onPageChange
              }
            }
          />
        </div>
      </div>
    </div>
  );
};
