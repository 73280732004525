import axios from '../axios'
import request_url from '../../assest/utils/request_url'
let baseUrl = request_url('institutionDetail')
export default {
  // 机构详情获取
  institutionDetail(data) {
    return axios.post(`${baseUrl}externalServiceDocking/institutionDetail`,data)
  },
  // 行为记录查询
  behaviorRecords(page,size,data) {
    return axios.post(`${baseUrl}externalServiceDocking/behaviorRecords/${page}/${size}`,data)
  },
  // 行为记录，表头
  behaviorRecordsHeader() {
    return axios.get(`${baseUrl}externalServiceDocking/behaviorRecordsHeader`)
  },

  // 人员详情
  // 行为记录
  behaviorDetails(data) {
    return axios.post(`${baseUrl}externalServiceDocking/foreign/behaviorDetails`,data)
  },
  // 人员信息
  peopleInfo(id) {
    return axios.post(`${baseUrl}externalServiceDocking/foreign/peopleInfo`,id)
  },
}
