import '../../styles/public/baseLoginLayout.scss'
import {Modal, Select} from "antd";
import {useEffect, useState} from "react";
import AMapLoader from "@amap/amap-jsapi-loader";

const { Option } = Select;
let positions = [];
export default (props) => {
  const {mapDisplay,setmapDisplay,AddressOK} = props;
  const [businessAddressCoordinate, setbusinessAddressCoordinate] = useState(); // 坐标
  const [businessAddressText, setbusinessAddressText] = useState('点击地图选择地址'); // 文字地址
  const [dataList, setdataList] = useState([]); // 文字地址
  const [AmapNew, setAmapNew] = useState(); // AMap
  const [container, setcontainer] = useState(); // map
  // const [positions, setpositions] = useState([]);
  useEffect(() => {
    AMapLoader.load({
      key: "dcc00940514d27f6cec30c6dea8646e9", // 申请好的Web端开发者Key，首次调用 load 时必填
      version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: ["AMap.Scale","AMap.Geocoder","AMap.Autocomplete"], //需要使用的的插件列表，如比例尺'AMap.Scale'，支持添加多个如：['...','...']
    })
        .then((AMap) => {
          setAmapNew(AMap);
          let map = new AMap.Map("container", {
            resizeEnable: true,
            // 设置地图容器id
            viewMode: "2D", // 是否为3D地图模式
            zoom: 11, // 初始化地图级别
            center: [118.180123,39.630615], // 初始化地图中心点位置
          });
          setcontainer(map);
          let geocoder = new AMap.Geocoder({
            city: "010", //城市设为北京，默认：“全国”
            radius: 1000 //范围，默认：500
          });
          map.on('click', (e) => {
            geocoder.getAddress([e.lnglat.getLng(), e.lnglat.getLat()],(status, result)=> {
              if (status === 'complete'&&result.regeocode) {
                // setbusinessAddressText(result.regeocode.formattedAddress)
                setbusinessAddressCoordinate(`${e.lnglat.getLng()},${e.lnglat.getLat()}`)
              }else{
                console.log('根据经纬度查询地址失败');
              }
            });
            setMarker(e.lnglat.getLng(), e.lnglat.getLat(),AMap,map)
            // let marker = new AMap.Marker({
            //   position: [e.lnglat.getLng(), e.lnglat.getLat()],
            //   offset: new AMap.Pixel(0, 0)
            // });
            // let arr = []
            // arr.push(...positions,marker)
            // setpositions([...positions,marker])
            // console.log(positions);
            // console.log(arr);
            // positions.forEach((item) => {
            //   map.remove(item)
            // })
            // map.add(marker);
          })
        })
        .catch((e) => {
          console.log(e);
        });
  }, []);
  const setMarker = (lag,lat,AMap,map) => {
    let marker = new AMap.Marker({
      position: [lag,lat],
      offset: new AMap.Pixel(0, 0)
    });
    positions.forEach((item) => {
      map.remove(item)
    })
    positions = [];
    map.setCenter([lag,lat])
    map.add(marker);
    positions.push(marker);
  }
  const businessAddressOK = () => {
    AddressOK({businessAddressCoordinate})
  }
  const searchAddr = (input) => {
    let autoOptions = { city: '全国' }
    let autoComplete = new AmapNew.Autocomplete(autoOptions);
    return new Promise( (res, rej) => {
      autoComplete.search(input, (status, result) =>
          status === 'complete' ? res(result.tips) : rej(status)
      )
    })
  }
  const handleSearch = (value) => {
    searchAddr(value).then(res => {
      setdataList(res.filter( item => item.location !== undefined && item.location !== ''))
    })
  }
  const handleSelect = (value) => {
    setbusinessAddressCoordinate(value)
    value = value.split(',')
    setMarker(value[0],value[1],AmapNew,container)
  }
  return (
      <Modal
          title="经营范围"
          centered
          width={1000}
          open={mapDisplay}
          onOk={businessAddressOK}
          onCancel={() => setmapDisplay(false)}
      >
        <div style={{position:"relative"}}>
          <div
              id="container"
              style={{height: "500px"}}
          ></div>
          <div style={{position: "absolute",top:10,left: 10,width: '300px'}}>
            <Select
                showSearch   //提供输入搜索功能
                placeholder="请输入"
                filterOption={false}    //关闭自动筛选
                optionFilterProp="label"
                defaultActiveFirstOption={false}
                onSearch={handleSearch}    //文本框值变化时回调
                onSelect={handleSelect}   //被选中时的回调
                style={{
                  width: '100%',
                }}>
              {
                dataList.map((item) => (
                    <Option key={item.id} value={`${item.location.lng},${item.location.lat}`}>{item.name}</Option>
                ))
              }
            </Select>
          </div>
        </div>
      </Modal>
  )
}
