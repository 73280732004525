class Lock {
    static timer = null
    static time = 15*60
    static count = 0
    static callback = null
    static addEventFun(dom,event="mousemove"){
        const {addEventFunc} = Lock;
        dom.addEventListener(event, addEventFunc,true);
    }
    static removeEvent(dom,event="mousemove"){
        const {addEventFunc} = Lock;
        dom.removeEventListener(event, addEventFunc,true);
    }
    static addEventFunc(callback){
        callback&&(Lock.callback=callback)
        Lock.countUp();
    }
    static countUp() {
        let {timer,time} = Lock;
        Lock.count = 0;
        clearInterval(timer);
        Lock.timer = setInterval(() => {
            if(Lock.count >= time){
                Lock.callback()
                Lock.destroyLock();
            }
            Lock.count++;
        },1000);
    }
    static destroyLock() {
        let {timer} = Lock;
        timer && clearInterval(timer);
        Lock.timer = null;
    }
}

export default Lock;