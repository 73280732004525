import React, { useCallback, useRef } from 'react';
import Captcha from 'react-captcha-code';

export default  (prop) => {
  const {verifiyCode,changCode} = prop;
  const handleChange = useCallback((captcha) => {
    changCode(captcha)
  }, []);

  const captchaRef = useRef();

  const handleClick = () => {
    // 刷新验证码
    (captchaRef).current.refresh();
  };
  return (
    <>
      <Captcha ref={captchaRef} charNum={4} onChange={handleChange} code={verifiyCode}/>
      {/* <div>
        <button onClick={handleClick}>更换验证码</button>
      </div> */}
    </>
  );
};
