// 从业人员公示
import React, { useState,useEffect,useRef } from "react";
import mitt from '../../assest/utils/mitt'
import EmployeePublicity from "../../service/Publicity/Employee/EmployeePublicity";
import '../../styles/Publicity/Corporat/corporatePublicity.scss'
import DetailEmployeePublicity from '../../component/Publicity/EmployeePublicity/DetailEmployeePublicity'
import EmployeePublicityTable from '../../component/Publicity/EmployeePublicity/EmployeePublicityTable'
import {Button, Form, Input, message, Modal, Rate} from "antd";
import * as rules_vertify from "../../assest/utils/rules_verfity";
import TextArea from "antd/es/input/TextArea";
import practitionersRegistration from "../../service/ManagePage/Personnel/Personnel";
export default (props) => {
  const {searchName, onSearch} = props
  const pageInfo = useRef({page:1,size:20});
  const tableRow = useRef({});
  const [tableLoading,setTableLoading] = useState(false)// 表格loading
  const [personList,setPersonList] = useState([]);// 列表
  const [personHead,setPersonHead] = useState([]);// 表头
  const [total,setTotal] = useState(0);// 总数
  const [showDetailCom,setShowDetailCom] = useState(false);// 详情组件显隐
  const [selectedRowKeys,setSelectedRowKeys] = useState([]) // 选中数据控制，用于清空

  const [appraiseOpen, setAppraiseOpen] = useState(false) // 评价弹窗
  const [newForm] = Form.useForm(); // 评价表单
  const [id, setid] = useState() // 评价人ID
  useEffect(() => {
    getPersonList()
    getPersonHead()
    return (() => {
      mitt.off('onBack', onBack)
      mitt.off('pageDestroy', pagePersonDestroy)
    })
  }, [])
  useEffect(() => {
    getPersonList()
  }, [onSearch])
  const pagePersonDestroy = () => {
    setShowDetailCom(false)
  }
  // 获取表头
  const getPersonHead = async () => {
    let res = await EmployeePublicity.peoplesPublicityTableHeader();
    setPersonHead(res)
  }
  // 获取列表
  const getPersonList = async () => {
    try {
      setTableLoading(true)
      let data = await EmployeePublicity.peoplesPublicity({requestParam: searchName,projectMark: null,page:pageInfo.current.page,size:pageInfo.current.size});
      setPersonList([...data.list]);
      setTotal(data.totalCount)
      setTableLoading(false)
    }catch(error) {
      setTableLoading(false)
    }
  }
  const onBack = (val) => {
    const organPageInfo = {
      modifyPersoPage:modifyPageBack,
      detailPersoPage:detailPageBack
    }
    organPageInfo[val]()
  }
  // 修改返回
  const modifyPageBack = () => {
  }
  // 详情返回
  const detailPageBack = () => {
    setShowDetailCom(false)
  }
  // 修改
  const onModify = (row) => {

  }
  // 发表评价
  const onAppraise = (row) => {
    setid(row.basicId)
    setAppraiseOpen(true)
  }
  const onFinish = async (values) => {
    values.beEvaluatedPersonId = id;
    await practitionersRegistration.postComments(values);
    message.success('评价成功！');
    getPersonList();
    handleCancel();
  }
  const onFinishFailed = () => {

  }
  // 分页
  const onPageChange = (page,size) => {
    pageInfo.current = {page,size};
    getPersonList();
    setSelectedRowKeys([])
  }
  // 修改成功
  const modifySuccess = () => {
    refreshList()
  }
  // 点击详情
  const onDetail = (row) => {
    tableRow.current = row;
    setShowDetailCom(true)
  }
  // 详情取消
  const onCancleDetail = () => {
    setShowDetailCom(false)
  }
  // 刷新列表
  const refreshList = (isResetPage = true) => {
    if(isResetPage) {
      pageInfo.current = {page:1,size:pageInfo.current.size}
    }
    getPersonList();
    setSelectedRowKeys([])
  }
  // 关闭评价弹窗
  const handleCancel = () => {
    setAppraiseOpen(false)
    newForm.resetFields();
  }
  return (
    <div id="CorporatePublicity">
      <div className="organ-contain">
        <EmployeePublicityTable
          total={total}
          onModify={onModify}
          onDetail={onDetail}
          loading={tableLoading}
          pageInfo={pageInfo.current}
          onPageChange={onPageChange}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          personList={personList}
          onAppraise={onAppraise}
          personHead={personHead}></EmployeePublicityTable>
      </div>
      {/*详情*/}
      {showDetailCom ? <DetailEmployeePublicity tableRow={tableRow.current}  onCancleDetail={onCancleDetail}></DetailEmployeePublicity> : null}
      <Modal title="发表评价" open={appraiseOpen} className="modalAppraise" footer={null}>
        <Form
          style={{padding: 30}}
          size={'default'}
          form={newForm}
          className="form"
          labelAlign="right"
          autoComplete="off"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="评价人姓名"
            name="evaluatorName"
            rules={[{required: true, message: '请输入姓名'}]}
          >
            <Input placeholder="请输入姓名"/>
          </Form.Item>
          <Form.Item
            label="手机号"
            name="phone"
            validateTrigger="onBlur"
            rules={[{required: true, message: '请输入手机号'},
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!rules_vertify.PHONE_NUM.test(value)) {
                    return Promise.reject(new Error('手机号格式错误'));
                  }
                  return Promise.resolve();
                },
              })]}
          >
            <Input placeholder="请输入手机号"/>
          </Form.Item>
          <Form.Item
            label="综合评分"
            name="comprehensiveScore"
          >
            <Rate/>
          </Form.Item>
          <Form.Item
            label="服务态度"
            name="serviceAttitude"
          >
            <Rate/>
          </Form.Item>
          <Form.Item
            label="专业程度"
            name="professionalLevel"
          >
            <Rate/>
          </Form.Item>
          <Form.Item
            label=""
            name="overallEvaluation"
          >
            <TextArea
              showCount
              maxLength={500}
              placeholder="多个角度评价，可以帮助经纪人提高服务质量，激励我们做的更好"
              style={{height: 120, resize: 'none'}}
            />
          </Form.Item>
          <div style={{width: '100%',display: "flex",justifyContent: "center"}}>
            <Button type="primary" htmlType="submit">发布评价</Button>
            <Button style={{marginLeft: '20px'}} onClick={handleCancel} >取消</Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};
