import '../../styles/appraise/appraise.scss'
import {Button, Col, Empty, Rate, Row, Spin} from "antd";
import {useHistory} from "react-router-dom";
import practitionersRegistration from "../../service/ManagePage/Personnel/Personnel";
import {useEffect, useState} from "react";

export default () => {
  let history = useHistory();
  const [detail, setDetail] = useState({});// 详情
  const [id, setid] = useState(history.location.pathname.split('/')[history.location.pathname.split('/').length - 1]);// 详情
  const [state, setstate] = useState(
    []
  );// 状态排序数组
  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    let data = await practitionersRegistration.employeesInfo({requestParam:id});
    setDetail({...data})
  }
  // 根据等级状态，和人员状态 展示最高级状态
  const states = (status, registrationStatus) => {
    let map = new Map([
      ['禁止从业', {color: '#de001b', superior: 0, name: '禁止从业'}],
      ['解除从业', {color: '#de001b', superior: 1, name: '解除从业'}],
      ['已过期（未提交）', {color: '#f59a23', superior: 2, name: '已过期（未提交）'}],
      ['暂停从业', {color: '#f59a23', superior: 3, name: '暂停从业'}],
      ['正常', {color: '#4b7900', superior: 4, name: '正常'}],
    ])
    let stateName
    if (map.get(registrationStatus)) {
      stateName = map.get(registrationStatus).superior < map.get(status).superior ? map.get(registrationStatus) : map.get(status);
    } else {
      stateName = map.get(status);
    }
    if (stateName) {
      return <div style={{color: stateName.color}}>{stateName.name}</div>
    }
  }

  return (
    <div id="appraise">
      <div className="navigation">
        <span>从业人员信息查询</span>
      </div>
      <div style={{padding:10}}>
        {
          detail !== undefined ?
            <>
              <div className="state">
                {states(detail.status, detail.registrationStatus)}
              </div>
              <Row className="rowTable">
                <Col className="col border_bottom text_right border_right background_color" span={9}>姓名</Col>
                <Col className="col border_bottom background_color" span={15}>{detail.name || '-'}</Col>

                <Col className="col border_bottom text_right border_right" span={9}>性别</Col>
                <Col className="col border_bottom" span={15}>{detail.sex || '-'}</Col>

                <Col className="col border_bottom text_right border_right background_color" span={9}>人员类型</Col>
                <Col className="col border_bottom background_color" span={15}>{detail.postType || '-'}</Col>

                <Col className="col border_bottom text_right border_right" span={9}>资格证号</Col>
                <Col className="col border_bottom" span={15}>{detail.certificateCode || '-'}</Col>

                <Col className="col border_bottom text_right border_right background_color" span={9}>信息卡号</Col>
                <Col className="col border_bottom background_color" span={15}>{detail.registrationCode || '-'}</Col>

                <Col className="col border_bottom text_right border_right" span={9}>登记有效期</Col>
                <Col className="col border_bottom" span={15}>{detail.entryTime || '-'}</Col>

                <Col className="col border_bottom text_right border_right background_color" span={9}>从业机构</Col>
                <Col className="col border_bottom background_color" span={15}>{detail.dept || '-'}</Col>

                <Col className="col border_bottom text_right border_right" span={9}>诚信等级</Col>
                <Col className="col border_bottom" span={15}><Rate disabled defaultValue={detail.creditRating || 3}/></Col>
              </Row>
              {detail.credit ?
                <>
                  <div className="title">
                    诚信记录
                  </div>
                  {
                    detail.credit.map(item =>
                      <Row className="rowTable">
                        <Col className="col border_bottom text_right border_right background_color" span={9}>行为类型</Col>
                        <Col className="col border_bottom background_color" span={15}>{item.behaviorType || '-'}</Col>

                        <Col className="col border_bottom text_right border_right" span={9}>认定依据</Col>
                        <Col className="col border_bottom" span={15}>{item.believeBasis || '-'}</Col>

                        <Col className="col border_bottom text_right border_right background_color" span={9}>行为事实</Col>
                        <Col className="col border_bottom background_color" span={15}>{item.factualBehavior || '-'}</Col>

                        <Col className="col border_bottom text_right border_right" span={9}>禁业时限/公示时限</Col>
                        <Col className="col border_bottom" span={15}>{item.publicityTime || '-'}</Col>

                        <Col className="col border_bottom text_right border_right background_color" span={9}>认定机构</Col>
                        <Col className="col border_bottom background_color" span={15}>{item.believeAgency || '-'}</Col>

                        <Col className="col border_bottom text_right border_right" span={9}>认定日期</Col>
                        <Col className="col border_bottom" span={15}>{item.believeDate || '-'}</Col>
                      </Row>
                    )
                  }
                </> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              }
            </> : <Spin size="large"/>
        }
      </div>
      <div className="btn">
        <Button onClick={() => {
          history.push({pathname: `/userReview/${id}`,})
        }}>发表评价</Button>
      </div>
    </div>
  )
}
