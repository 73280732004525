export const PHONE_NUM = /^1[3456789]\d{9}$/ // 电话号码

export const ID_CARD_NUMBER = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9X]$/ // 身份证号

export const PURE_NUM = /^\d{0,10}$/ // 纯数字 

export const PASSWORD = /(?!^[a-zA-Z]+$)(?!^[0-9]+$)^[a-zA-Z0-9]{6,20}$/ // 6-20 字母与数字组合

export const LICENSE_NUM = /(?!^[a-zA-Z]+$)(?!^[0-9]+$)^[a-zA-Z0-9]{17}$/ // 17 字母与数字组合

export const EMAIL_REG = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/ // 邮箱

export const EMAIL_REG_NEW = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/

export const CN_EN_NUM_UNLINE = /^[\u4E00-\u9FA5A-Za-z0-9]+$/ //中文、英文、数字但不包括下划线等符号--护照

export const NUM_LETTER_18 = /^[A-Za-z0-9]{18}$/ //18位阿拉伯数字和大小写英文字母

export const NAME_REX = /^([\u4e00-\u9fa5\·]{1,100})$/ //姓名正则

export const NUM_LETTER_ALL = /^[A-Za-z0-9]+$/ // 阿拉伯数字和大小写英文字母不限制位数

export const PHONE_NUM_AREA = /(^1(?:3[0-9]|4[5-9]|5[0-9]|6[12456]|7[0-8]|8[0-9]|9[0-9])[0-9]{8}$)|(^0[1-9][0-9]{1,2}[2-8][0-9]{6,7}$)/ // 手机号包括0315那样的

export const CN_EN_NUM_PARENTHESES = /^[\u4E00-\u9FA5A-Za-z0-9（）]+$/ //中文、英文、数字和括号
