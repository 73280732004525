// 机构详情
import React, {useState, useEffect, useRef} from "react";
import '../../styles/Details/InstitutionalDetails.scss'
import {Col, Rate, Row, Spin,Image} from "antd";
import InstitutionalTabTable from "../../component/Details/institutionalTabTable";
import Details from "../../service/Details/InstitutionalDetails";
import member_img from "../../assest/image/member.png";
import abnormal from "../../assest/image/abnormal.png";
import normal from "../../assest/image/normal.png";

export default (props) => {
  const {id} = props;
  // 机构详情数据
  const [detail, setDetail] = useState({});
  // 详情加载数据
  const [detailShow, setDetailShow] = useState(false);

  // 获取详情
  useEffect(() => {
    getDetail()
  }, [])

  const getDetail = async () => {
    setDetailShow(false)
    let res = await Details.institutionDetail({institutionId: id})
    setDetail(res)
    setDetailShow(true)
  }
  return (
      <div id="InstitutionalDetails">
        {
          detailShow ? <div>
            <header>机构登记备案档案</header>
            <main>
              <div className='cardDetails'>
                <h4 className="cardDetails_title">
                  {detail.archivesName}
                  <div>
                    {
                      detail.statusName === '正常' ?
                      <span className="abnormal">
                        <img src={normal} alt=""/>
                        正常
                      </span> :
                      <span className="normal">
                        <img src={abnormal} alt=""/>
                        异常
                      </span>
                    }
                  </div>
                </h4>
                <div className="cardDetails_mechanismInfo">
                  <div style={{width: '65%'}}>
                    <Row>
                      <Col span={12}>经营模式<span>{detail.managementModelName}</span></Col>
                      <Col span={12}>统一社会信用代码<span>{detail.businessLicenseNumber}</span></Col>

                      <Col span={12}>品牌<span>{detail.brandName}</span></Col>
                      <Col span={12}>注册资本<span>{detail.registerCapital}万元</span></Col>

                      <Col span={12}>所属地区<span>{detail.administrativeArea}</span></Col>
                      <Col span={12}>法定代表人<span>{detail.legalPersonName}</span></Col>

                      <Col span={12}>成立日期<span>{detail.registerTime}</span></Col>
                      <Col span={12}>联系人<span>{detail.contactPeople}</span></Col>

                      <Col span={12}>联系电话<span>{detail.complaintsHotline}</span></Col>
                      <Col span={12}>登记备案有效期<span>{detail.deadlineForFilingValidity}</span></Col>

                      <Col span={12}>登记备案编号<span>{detail.recordCertificateNumber}</span></Col>

                      <Col span={24}>机构住所<span>{detail.registerAddress}</span></Col>

                      <Col span={24}>经营范围<span>{detail.businessScope}</span></Col>
                    </Row>
                  </div>
                  <div className="cardDetails_mechanismInfo_right">
                    <span style={{display: 'flex', marginBottom: '20px', alignItems: "center", height: '21px'}}>经营模式<Rate
                        disabled defaultValue={detail.star}/></span>
                    <span style={{display: 'block', marginBottom: '10px'}}>
                       <Image
                           width="90%"
                           src={'https://qjprod-images.oss-cn-beijing.aliyuncs.com/tsfzx_institution/' + detail.certificateOfFixedBusinessPremises}
                           alt=""/>
                      <span style={{display: "block", textAlign: "center",marginTop: '10px'}}>店内经营场所照片</span>
                    </span>
                    <span>
                        <Image
                            width="90%"
                            src={'https://qjprod-images.oss-cn-beijing.aliyuncs.com/tsfzx_institution/' + detail.photosOfBusinessPremises}
                            alt=""/>
                      <span style={{display: "block", textAlign: "center",marginTop: '10px'}}>店外经营场所照片</span>
                    </span>
                  </div>
                  {
                    detail.memberName !== '非会员' ? < img className="member" src={member_img} alt=""/> : null
                  }
                </div>
              </div>
              <div className='cardDetails'>
                <h4 className="cardDetails_title">行为记录</h4>
                <div className="cardDetails_table">
                  <InstitutionalTabTable id={id}></InstitutionalTabTable>
                </div>
              </div>
            </main>
            <div style={{height: '120px'}}></div>
            <footer>
              <div>
                <span>地址: 唐山铭扬国际4层</span>
                <span className="footer_left">电话: 0315-8080089</span>
              </div>
              <div style={{marginTop: '10px'}}>
                <span>Copyright @ 2024 唐山市房地产中介服务行业协会 版权所有</span>
                <span className="footer_left">ICP备案编号: 冀ICP备2023040514</span>
              </div>
            </footer>
          </div> : <div
              style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Spin size="large"/>
          </div>
        }
      </div>
  );
};

