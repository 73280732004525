import axios from '../../axios'
import request_url from '../../../assest/utils/request_url'
let baseUrl = request_url('practitionersRegistration')
let baseUrl1 = request_url('enterpriseRecord')
export default {
  // 获取表头
  personHeader() {
    return axios.get(`${baseUrl}basic/query/tableHeader/personHeader`)
  },
  // 获取表格数据
  peoplesList(page,size,data) {
    return axios.post(`${baseUrl}organization/peoples/${page}/${size}`, data)
  },
  // 获取详情
  peopleDetails(id) {
    return axios.get(`${baseUrl}organization/basic/people/${id}`)
  },
  // 从业人员详情公示
  getDetails(data) {
    return axios.post(`${baseUrl}organization/people/details`, data)
  },
  // 获取审批记录
  approvalHistory(id) {
    return axios.get(`${baseUrl}organization/approvalHistory/${id}`)
  },
  // 获取性别字典
  getSexDict() {
    return axios.get(`${baseUrl}people/dictionary/sex`)
  },
  // 获取政治面貌
  getPoliticalFaceDict() {
    return axios.get(`${baseUrl}organization/people/politicalFace`)
  },
  // 获取从业人员类型
  queryPostType() {
    return axios.get(`${baseUrl}organization/people/queryPostType`)
  },
  // 获取学历字典
  getEducationDict() {
    return axios.get(`${baseUrl}organization/people/certificateType/education`)
  },
  // 职务
  dictionaryList(id) {
    return axios.get(`${baseUrl}basic/people/position/dictionaryList?postId=${id}`)
  },
  // 修改保存
  peoplePut(data) {
    return axios.put(`${baseUrl}organization/basic/people`,data)
  },
  // 新增
  peoplePost(data) {
    return axios.post(`${baseUrl}organization/basic/people`,data)
  },
  // 注销
  resign(data) {
    return axios.put(`${baseUrl}organization/people/resign`,data)
  },
  // 获取房产经纪字典
  houseAgent() {
    return axios.get(`${baseUrl}organization/people/certificateType/houseAgent`)
  },

  // 行为记录
  // 不良行为列表
  badBehaviorList(data) {
    return axios.post(`${baseUrl}organization/badBehaviorList`,data)
  },
  // 黑名单行为列表
  blackListBehaviorList(data) {
    return axios.post(`${baseUrl}organization/blackListBehaviorList`,data)
  },
  // 良好行为列表
  goodBehaviorList(data) {
    return axios.post(`${baseUrl}organization/goodBehaviorList`,data)
  },
  // 详情页面内行为记录
  detailsBehaviorList(data) {
    return axios.post(`${baseUrl}organization/detailsBehaviorList`,data)
  },
  // 获取表头
  tableHeader(tableTypeCode) {
    return axios.post(`${baseUrl}organization/tableHeader`, tableTypeCode)
  },

  // H5页面
  // 从业人员信息查询
  employeesInfo(data) {
    return axios.post(`${baseUrl}organization/employeesInfo`,data)
  },
  // 发表评价
  postComments(data) {
    return axios.post(`${baseUrl}organization/postComments`,data)
  },
  // 领取方式
  receiveWay() {
    return axios.get(`${baseUrl}organization/people/receiveWay`)
  },
  // 所在地区
  location() {
    return axios.get(`${baseUrl}organization/registrationCardProcess/location`)
  },
  // 查询人员能否申请制卡,外部调用判断使用(true能，false不能)
  allowCardMaking(data) {
    return axios.post(`${baseUrl}organization/registrationCardProcess/allowCardMaking`,data)
  },
  // 查询会员类型
  peopleCardDetails(id) {
    return axios.get(`${baseUrl}organization/basic/peopleCardDetails/${id}`)
  },
  // 查询会员类型
  firmInfo(data) {
    return axios.post(`${baseUrl1}archives/merchant/management/firmInfo`,data)
  },
  // 申请制卡(外部使用)
  external(data) {
    return axios.post(`${baseUrl}organization/registrationCardProcess/applyForCardMaking/external`,data)
  },
  // 获取付款二维码链接
  generateQRCode(data) {
    return axios.post(`${baseUrl}organization/registrationCardProcess/generateQRCode`,data)
  },
  // 查询支付状态
  paymentStatus(data) {
    return axios.post(`${baseUrl}organization/registrationCardProcess/paymentStatus`,data)
  },
}
