import registerApi from '../../api/register/register'
class register {
  static getInstance() {
    if(!this.login) {
      this.login = new register()
    }
    return this.login
  }
  // 企业类型 字典
  async industryCategory() {
    return registerApi.industryCategory()
  }
  // 协会会员等级 字典
  async getMember() {
    return registerApi.getMember()
  }
  // 经营模式-字典查询
  async operatingMode() {
    return registerApi.operatingMode()
  }
  // 品牌-字典查询
  async brandDictionary() {
    return registerApi.brandDictionary()
  }
  // 上级机构 搜索 字典
  async deptSearch(deptName) {
    return registerApi.deptSearch(deptName)
  }
  // 所在县区 搜索 字典
  async searchCnare(deptName) {
    return registerApi.searchCnare(deptName)
  }
  // 注册账号
  async institutionRegistration(data) {
    return registerApi.institutionRegistration(data)
  }
  // 账号查重
  async checkAccount(data) {
    return registerApi.checkAccount(data)
  }
  // 完善信息
  async institutionPerfect(data) {
    return registerApi.institutionPerfect(data)
  }
  // 完善信息
  async isNeedToImprove(data) {
    return registerApi.isNeedToImprove(data)
  }
  // 获取验证码
  async sendVerificationCode(data) {
    return registerApi.sendVerificationCode(data)
  }
  // 运转模式字典
  async getOperationMode() {
    return registerApi.getOperationMode()
  }
  // 申请加入品牌库
  async applyBrandLibrary(data) {
    return registerApi.applyBrandLibrary(data)
  }
}
export default register.getInstance()
