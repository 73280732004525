// 从业人员公示
import React, { useState,useEffect,useRef } from "react";
import mitt from '../../assest/utils/mitt'
import '../../styles/ManagePage/Card/Card.scss'
import Card from "../../service/ManagePage/Card/Card";
import {Button, Col, Modal, Row, Spin, Table} from "antd";
import QRCode from "qrcode.react";
import logo from "../../assest/image/logo.png";
import zfb from "../../assest/image/zfb.png";
import wx from "../../assest/image/wx.png";
import yl from "../../assest/image/yl.png";
import practitionersRegistration from "../../service/ManagePage/Personnel/Personnel";
import DownloadCard from "../../component/ManagePage/Personnel/DownloadCard";
export default (props) => {
  const {} = props
  const pageInfo = useRef({page:1,size:20});
  // 表头数据
  const [personHead,setPersonHead] = useState([])
  // 表格数据
  const [personData,setPersonData] = useState([])
  const [total,setTotal] = useState(0)
  // 预览弹窗
  const [previewVisible,setPreviewVisible] = useState(false)
  // 预览url
  const [previewUrl,setPreviewUrl] = useState('')
  // 预览加载
  const [previewLoading,setPreviewLoading] = useState(false)
  // 邮寄信息查看弹窗
  const [viewVisible,setViewVisible] = useState(false)
  // 邮寄信息数据
  const [viewData,setViewData] = useState({})
  // 是否为自取
  const [isPickUp,setIsPickUp] = useState(false)
  // 支付弹窗
  const [payVisible,setPayVisible] = useState(false)
  // 支付数据
  const [countDown, setCountDown] = useState("5:0");
  // 登记卡数据
  const [cardMakingData,setCardMakingData] = useState({})

  const [codeUrl, setCodeUrl] = useState({qrUrl: "", qrInfoSaveVO: {id: ""}, tradingFlowId: ""});

  const [isModalOpen, setIsModalOpen] = useState(false);
  let time;
  useEffect(() => {
    getPersonList()
    getPersonHead()
  }, [])
  // 打开预览弹窗
  const previewClick = async (row) => {
    setCardMakingData(row)
    setPreviewLoading(false)
    // let res = await Card.preview(row.id)
    // setPreviewUrl(res)
    setPreviewVisible(true)
    setTimeout(()=> {
      setPreviewLoading(true)
    },1000)
  }
  const viewClick = async (row) => {
    if(row.receiveWay === '自取') {
      setIsPickUp(true)
      setViewVisible(true)
      return
    }
    let res = await Card.mailingInfo(row.id)
    setViewData(res)
    setViewVisible(true)
  }
  // 获取表头
  const getPersonHead = async () => {
    let res = await Card.registrationCardProcessList();
    let data = []
    res.forEach(item => {
      if (item.data === 'mailingInformation') {
        data.push({
          title: '邮寄/领取信息',
          dataIndex: item.data,
          key: item.data,
          align: 'center',
          render: (text) => {
            return <div>{text}</div>
          }
        })
      }else {
        data.push({
          title: item.headerFirst,
          dataIndex: item.data,
          key: item.data,
          align: 'center',
          render: (text) => {
            return <div>{text}</div>
          }
        })
      }
    })
    data[7].render = (text,row) => {
      return row.receiveWay !== '' ? <Button type="link" onClick={()=> viewClick(row)}>查看</Button> : '-'
    }
    data[1].width = '230px'
    data.unshift({
          title: '序号',
          dataIndex: 'key',
          rowScope: 'row',
          width: 50,
          render: (text) => {
            return <div style={{textAlign: "center"}}>{text}</div>
          }
        })
    data.push({
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (text,record) => {
        return <div>
          { record.costStatus !== '已支付' ?   <Button type="link" onClick={()=> payClick(record)} size={'small'}>支付</Button> : null }
          { record.registrationStatus === '已通过' ?  <Button onClick={()=> previewClick(record)} type="link" size={'small'}>预览</Button> : null}
        </div>
      }
    })
    setPersonHead(data)
  }
  const payClick = async (row) => {
    let url = await practitionersRegistration.generateQRCode({basicId: row.basicId, inDeptId: row.inDeptId,customerName: row.name})
    // setCodeUrl({
    //   qrUrl: "https://ibsbjstar.ccb.com.cn/CCBIS/QR?QRCODE=CCB9980001709387396912649&1721099786233",
    //   qrInfoSaveVO: {
    //     id: "c963a59b-4321-11ef-80ee-0242ac120002"
    //   },
    //   amountPayable: '20',
    //   tradingFlowId: "c963a59b-4321-11ef-80ee-0242ac120002"
    // })
    setCodeUrl(url)
    // 倒计时五分钟
    clearInterval(time)
    let timeNuM = 5 * 60
    time = setInterval(() => {
      const minutes = Math.floor(timeNuM / 60)
      const seconds = timeNuM % 60
      setCountDown(`${parseInt(minutes)}:${parseInt(seconds)}`)
      timeNuM--
      if (minutes === 0 && seconds === 0) {
        clearInterval(time)
        ConfirmCancel()
      }
    }, 1000)
    setPayVisible(true)
  }
  // 获取列表
  const getPersonList = async () => {
    try {
      let inDeptId = JSON.parse(sessionStorage.getItem("info")).deptId
      let res = await Card.registrationCardProcess(pageInfo.current.page,pageInfo.current.size,{deptId: inDeptId})
      setTotal(res.totalCount)
      res.list.forEach((item,index) => {
        item.key = index + 1
      })
      setPersonData(res.list)
    } catch (error) {
      console.log('error', error);
    }
  }
  const viewVisibleCancel = () => {
    setViewVisible(false)
    setViewData({})
    setIsPickUp(false)
  }
  // 页脚
  const onPageChange = (page,size) => {
    pageInfo.current = {page,size};
    getPersonList();
  }
  const close = () => {
    setPreviewUrl('')
    setPreviewVisible(false)
  }

  const payVisibleCancel = () => {
    setPayVisible(false)
    setCountDown("5:0")
    clearInterval(time)
  }

  const ConfirmCancel = () => {
    setIsModalOpen(false)
    setPayVisible(false)
    setCountDown("5:0")
    clearInterval(time)
  }
  const isModalOpenCancel = () => {
    setIsModalOpen(false)
  }
  const onCancleCardMaking = () => {
    setIsModalOpen(true)
  }
  return (
      <div id="Card">
        <div className="btn">
          {/*<Button type="primary">登记卡进度</Button>*/}
        </div>
        <Table
            columns={personHead}
            dataSource={personData}
            bordered
            size={'small'}
            scroll={{x: '100%', y: '550px'}}
            pagination={
              {
                showSizeChanger: true,
                defaultPageSize: pageInfo.current.size,
                total: total,
                onChange: onPageChange,
                current: pageInfo.current.page
              }
            }
        />
      {/* 预览弹窗 */}
        <Modal
          title="预览"
          visible={previewVisible}
          onCancel={() => setPreviewVisible(false)}
          footer={null}
          width={'600px'}
        >
          {
            previewLoading ? <DownloadCard id={cardMakingData.basicId} inDeptId={cardMakingData.inDeptId} watermark={true}></DownloadCard> :
            // previewLoading ? <DownloadCard id={1298} inDeptId={853} watermark={true}></DownloadCard> :
                <div style={{width: '100%',height:'560px',display: "flex",justifyContent: "center",alignItems:"center"}}>
                  <Spin size="large" />
                </div>
          }
          {/*{*/}
          {/*  previewLoading ? <img src={previewUrl} style={{width: '70%', margin: 'auto', display: "block"}} alt=""/> :*/}
          {/*      <div style={{width: '100%',height:'560px',display: "flex",justifyContent: "center",alignItems:"center"}}>*/}
          {/*        <Spin size="large" />*/}
          {/*      </div>*/}
          {/*}*/}
          {/* 按钮 */}
          <div className="btnClose">
            <Button type="primary" onClick={close}>关闭</Button>
          </div>
        </Modal>
      {/* 邮寄信息弹窗 */}
      <Modal
        title="邮寄信息"
        visible={viewVisible}
        onCancel={viewVisibleCancel}
        footer={null}
        width={'600px'}
      >
        {
          isPickUp ? <div>
            <Row className="border">
              <Col span={6} className="title">领取地址</Col>
              <Col span={18} className="info">唐山市路北区友谊路铭洋国际3层，唐山市房中协秘书处</Col>

              <Col span={6} className="title">领取时间</Col>
              <Col span={18} className="info">
                <div style={{width: '100%',textAlign: 'center'}}>周一至周日</div>
                <div style={{width: '100%',textAlign: 'center'}}>上午：8：30-11：30&nbsp;&nbsp;&nbsp;下午：2：00-6：00</div>
              </Col>

              <Col span={6} className="title" style={{borderBottom: "none"}}>客服电话</Col>
              <Col span={18} className="info" style={{borderBottom: "none"}}>
                0315-8080089
              </Col>
            </Row>
          </div> : <div>
            <Row className="border">
              <Col span={6} className="title">收件人</Col>
              <Col span={18} className="info">{viewData.recipients}</Col>

              <Col span={6} className="title">手机号</Col>
              <Col span={18} className="info">{viewData.phone}</Col>

              <Col span={6} className="title">所在地区</Col>
              <Col span={18} className="info">{viewData.location}</Col>

              <Col span={6} className="title" style={{borderBottom: "none"}}>详细地址</Col>
              <Col span={18} className="info" style={{borderBottom: "none"}}>{viewData.detailedAddress}</Col>
            </Row>
          </div>
        }
      </Modal>
      {/* 支付弹窗 */}
        <Modal
            title="支付"
            visible={payVisible}
            closable={false}
            keyboard={false}
            maskClosable={false}
            footer={null}
            width={'600px'}
        >
          <div>
            <span className="title_card">登记卡工本费 &nbsp; {codeUrl.amountPayable} &nbsp; 元</span>
            <h4 className="title_card_code">￥{codeUrl.amountPayable}</h4>
            <div style={{
              display: "flex",
              justifyContent: "center",
            }}>
              {/* 付款二维码*/}
              <QRCode value={codeUrl.qrUrl}
                      fgColor='#1fb19e'
                      size={150} level={'H'} className="border_code"
                      imageSettings={{src: logo, width: 30, excavate: true, height: 30}}
              ></QRCode>
            </div>
            <span className="payment">{countDown}s后二维码过期</span>
            <div style={{width: '100%', textAlign: "center", marginTop: '20px', marginBottom: '10px'}}>
              <div className="pay_logo">
                <img src={zfb} alt=""/>
                <img src={wx} alt=""/>
                <img src={yl} alt=""/>
              </div>
              <span style={{color: '#5c5c5c'}}>支持支付宝，微信，银联扫码付款</span>
            </div>
            <div style={{width: '100%', display: "flex", justifyContent: "center"}}>
              {/*<Button type="primary" onClick={()=> setPayVisible(false)}>确认</Button>*/}
              <Button type="primary" onClick={onCancleCardMaking}>关闭</Button>
            </div>
          </div>
        </Modal>
        <Modal title="是否支付" open={isModalOpen}
               keyboard={false}
               maskClosable={false}
               closable={false}
               closeIcon={null}
               footer={null}
        >
          如果您尚未支付，关闭后15-20分钟内不能再次支付~
          <div style={{width: '100%', display: "flex", justifyContent: "center",marginTop: '30px'}}>
            <Button type="primary" onClick={isModalOpenCancel} >
              继续支付
            </Button>
            <Button onClick={ConfirmCancel} style={{marginLeft: '30px'}}>
              确认取消/已完成支付
            </Button>
          </div>
        </Modal>
      </div>
  );
};
