import enterpriseRecordApi from '../../../api/ManagePage/Enterprise/Enterprise'
class Enterprise {
  // 创建单例
  static getInstance() {
    if(!this.enterpriseRecord) {
      this.enterpriseRecord = new Enterprise()
    }
    return this.enterpriseRecord
  }
  // 获取 表头
  filingRecordsHeader() {
    return enterpriseRecordApi.filingRecordsHeader()
  }
  // 获取 表格数据
  filingRecordsQuery(id,page,size) {
    return enterpriseRecordApi.filingRecordsQuery(id,page,size)
  }
  // 获取 表格数据
  institutionFilingStatus(data) {
    return enterpriseRecordApi.institutionFilingStatus(data)
  }
  // 获取 详情
  filingRecordsDetail(data) {
    return enterpriseRecordApi.filingRecordsDetail(data)
  }
  // 获取审批记录
  filingAuditHistory(id) {
    return enterpriseRecordApi.filingAuditHistory(id)
  }
  // 变更 / 申请备案
  repeatedReview(data) {
    return enterpriseRecordApi.repeatedReview(data)
  }
  // 通过商户ID 获取部门ID
  obtainDeptThroughMerchants(archivesId) {
    return enterpriseRecordApi.obtainDeptThroughMerchants(archivesId)
  }
}
export default Enterprise.getInstance()
