import {useState, useEffect} from "react";
import "../../../styles/ManagePage/Personnel/registerForm.scss";
import uploadImg from "../../../assest/utils/upload";
import register from "../../../service/register/register";
import {
  Form,
  Input,
  Button,
  Row,
  Select,
  Upload,
  message,
  Modal, Col, DatePicker, Radio,
} from "antd";
import {PlusOutlined, CheckCircleFilled,SendOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import moment from "moment";
import * as rules_vertify from "../../../assest/utils/rules_verfity";
import Amap from '../../public/amap'
import BrandMode from "../../public/BrandMode";

const getBase64 = (file: RcFile) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export default (prop) => {
  let history = useHistory();
  const {current, setCurrent, btnLoading} = prop;
  const [stepsName, setStepsName] = useState(current); // 步骤条切换名字
  const [newForm] = Form.useForm();
  const [formValue, setFormValue] = useState({branchOrNot: false}); // 表单默认值
  const [archivesId, setArchivesId] = useState({}); // 企业id
  const [codeLoading, setcodeLoading] = useState(false); // 发送验证码按钮加载
  let [countdown, setCountdown] = useState(60); // 发送验证码按钮倒计时

  const [perviewImg, setPerviewImg] = useState([]); // 预览图片数组
  const [idCardImageList, setidCardImageList] = useState([]); // 法人身份证正反面
  const [licenseImageList, setlicenseImageList] = useState([]); // 法人身份证正反面
  const [othersImageList, setothersImageList] = useState([]); // 法人身份证正反面
  const [certificateOfFixedBusinessPremisesImageList, setCertificateOfFixedBusinessPremisesImageList] = useState([]); // 固定经营场所证明--店内经营场所照片
  const [photosOfBusinessPremisesImageList, setPhotosOfBusinessPremisesImageList] = useState([]); // 经营场所照片--店外经营场所照片
  const [showPreview, setShowPreview] = useState(false); // 预览图片弹窗
  const [branchOrNot, setbranchOrNot] = useState(false); // 预览图片弹窗
  const [mapDisplay, setmapDisplay] = useState(false); // 预览图片弹窗
  const [businessAddressDisabled, setbusinessAddressDisabled] = useState(true); // 营业地址

  const [industryCategory, setIndustryCategory] = useState([]); // 企业类型 字典
  const [memberDict, setMemberDict] = useState([]); // 协会会员等级 字典
  const [operatingModeDict, setOperatingModeDict] = useState([]); // 经营模式 字典
  const [fatherDeptDict, setFatherDeptDict] = useState([]); // 经营模式 字典
  const [administrativeArea, setAdministrativeArea] = useState([]); // 所属地区 字典
  const [options, setoptions] = useState([]); // 文字地址
  const [operationMode, setOperationMode] = useState(false); // 运转模式字典
  const [brandMode, setBrandMode] = useState(false); // 品牌弹窗
  const [brandData, setBrandData] = useState(); // 品牌数据
  const [brandDict, setBrandDict] = useState([]); // 品牌 字典
  const [industryCategoryData, setIndustryCategoryData] = useState();
  // 初始化字典
  useEffect(() => {
    getDict();
    // getRefineDict();
  }, []);
  const fieldNames = {label: "name", value: "areaCode", children: "children"};
  const uploadButton = (
    <div>
      <PlusOutlined/>
    </div>
  );
  const businessAddressOK = (data) => {
    setmapDisplay(false);
    setbusinessAddressDisabled(false);
    // newForm.setFieldsValue({businessAddress:data.businessAddressText});
    newForm.setFieldsValue({businessAddressCoordinate:data.businessAddressCoordinate});
  }
  // 经营范围地图选择
  const mapClick = () => {
    setmapDisplay(true);
  }
  // 获取默认字典 无需token
  const getDict = async () => {
    // 获取企业类型
    let data = await register.industryCategory();
    setIndustryCategory(data)
    // 获取企业类型
    data = await register.getMember();
    setMemberDict(data)
    // 获取运转模式字典
    let res = await register.getOperationMode()
    let isFilingFirst = false
    if(res && res[0]) {
      isFilingFirst = res[0].itemCode == 1
    }
    setOperationMode(isFilingFirst)
  };
  // 获取完善资料页面字典 需要token
  const getRefineDict = async () => {
    // 获取经营模式
    let data = await register.operatingMode();
    setOperatingModeDict(data)

    data = await register.brandDictionary();
    setBrandDict(data)
    administrativeAreaIdSearch('');
  };
  // 上传图片 法人身份证正反面
  const beforeUpload = async (file) => {
    let data = await uploadImg(file);
    file.uid = data.name.split('/')[1];
    file.url = data.url;
    let arr = JSON.parse(JSON.stringify(idCardImageList));
    arr.push(file);
    setidCardImageList(arr);
    return false;
  };
  // 上传图片校验
  const checkUser = (rule, value) => {
    if (!value) {
      return Promise.reject();
    }
    // 判断是否是数组，因为修改带入的数据是数组的形式
    if(value.constructor===Array) {
      if(value.length > 0) {
        return Promise.resolve();
      }else {
        return Promise.reject();
      }
    }else if(value.constructor===String){
      // value是单张字符串的情况
      if(value){
        return Promise.resolve()
      }
    }else {
      // value是file对象的情况
      if (!value.file.status) {
        return Promise.resolve();
      }
    }
    return Promise.reject();
  };
  // 上传图片 企业营业执照副本
  const licenseImageListBeforeUpload = async (file) => {
    let data = await uploadImg(file);
    file.uid = data.name.split('/')[1];
    file.url = data.url;
    let arr = JSON.parse(JSON.stringify(licenseImageList));
    arr.push(file);
    setlicenseImageList(arr);
    return false;
  };
  // 上传图片 机构备案证书
  const othersImageListBeforeUpload = async (file) => {
    let data = await uploadImg(file);
    file.uid = data.name.split('/')[1];
    file.url = data.url;
    let arr = JSON.parse(JSON.stringify(othersImageList));
    arr.push(file);
    setothersImageList(arr);
    return false;
  };
  // 上传图片 固定经营场所证明--店内经营场所照片
  const certificateOfFixedBusinessPremisesImageListBeforeUpload = async (file) => {
    let data = await uploadImg(file);
    file.uid = data.name.split('/')[1];
    file.url = data.url;
    let arr = JSON.parse(JSON.stringify(certificateOfFixedBusinessPremisesImageList));
    arr.push(file);
    setCertificateOfFixedBusinessPremisesImageList(arr);
    return false;
  };
  // 上传图片 经营场所照片--店外经营场所照片
  const photosOfBusinessPremisesImageListBeforeUpload = async (file) => {
    let data = await uploadImg(file);
    file.uid = data.name.split('/')[1];
    file.url = data.url;
    let arr = JSON.parse(JSON.stringify(photosOfBusinessPremisesImageList));
    arr.push(file);
    setPhotosOfBusinessPremisesImageList(arr);
    return false;
  };
  // 上级机构远程搜索
  const handleSearch = async (newValue: string) => {
    if (newValue) {
      let data = await register.deptSearch(newValue)
      setFatherDeptDict(data)
    } else {
      setFatherDeptDict([]);
    }
  };
  // 所属地区远程搜索
  const administrativeAreaIdSearch = async (newValue: string) => {
    let data = await register.searchCnare({name: newValue})
    setAdministrativeArea(data)
  };
  // 表单校验成功
  const onFinish = async (value) => {
    let newObj = JSON.parse(JSON.stringify(value))
    // 删除确认密码字段
    delete newObj.confirmPassword
    // 注册 存储token
    if (stepsName === 0) {
      let state = await register.checkAccount({account: newObj.account})
      if (state) return message.error('当前账号已注册，请勿重复操作');
      let data = await register.institutionRegistration(value);
      // 存储企业id
      setArchivesId(data.archivesId);
      // 存储token
      sessionStorage.setItem('token', data.token);
      await getRefineDict();
      setIndustryCategoryData(newObj.industryCategory)
    }
    // 完善资料
    if (stepsName === 1) {
      let nowDate = moment(new Date().getTime()).format('YYYY-MM-DD')
      newObj.registerTime = moment(newObj.registerTime).format('YYYY-MM-DD');
      newObj.deadlineForFilingValidity = moment(newObj.deadlineForFilingValidity).format('YYYY-MM-DD');
      if(newObj.deadlineForFilingValidity === nowDate)  { return message.error('请核对备案有效期是否正确');}
      if(newObj.registerTime === nowDate)  { return message.error('请核对执照成立日期是否正确');}
      newObj.archivesId = archivesId;
      // 格式化文件上传，只需要上传uid 最新上传
      newObj.idCardImageList = newObj.idCardImageList.fileList ? newObj.idCardImageList.fileList.map(item => item.uid) : newObj.idCardImageList
      newObj.licenseImageList = newObj.licenseImageList.fileList ? newObj.licenseImageList.fileList.map(item => item.uid) : newObj.licenseImageList
      newObj.othersImageList = newObj.othersImageList.fileList ? newObj.othersImageList.fileList.map(item => item.uid) : newObj.othersImageList
      newObj.certificateOfFixedBusinessPremises = newObj.certificateOfFixedBusinessPremises.fileList ?newObj.certificateOfFixedBusinessPremises.fileList.map(item => item.uid): newObj.certificateOfFixedBusinessPremises
      newObj.photosOfBusinessPremises = newObj.photosOfBusinessPremises.fileList ? newObj.photosOfBusinessPremises.fileList.map(item => item.uid): newObj.photosOfBusinessPremises
      // 格式化品牌
      // newObj.brand = newObj.brand[0]
      await register.institutionPerfect(newObj);
      message.success('恭喜您，您的注册申请已成功提交！');
      if(brandData.brandLogo){
        await register.applyBrandLibrary(brandData);
        message.success('品牌申请已提交');
      }
    }
    onSubmitClick(stepsName + 1)
  };
  // 表单校验失败回调
  const onFinishFailed = (value) => {
    console.log("onFinishFailed",value);
    // console.log(value);
    // getRefineDict();
    // onSubmitClick(stepsName + 1)
  };
  // 图片预览
  const handlePreview = async (name, file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPerviewImg(file.url || file.preview);
    setShowPreview(true);
  };
  // 关闭预览图片弹窗
  const handleCancel = () => setShowPreview(false);
  const delImg = (file, name, {fileData, fileMeth}) => {
    let {uid} = file;
    let filInd = fileData.findIndex((el) => uid === el.uid);
    if (filInd !== -1) {
      let newfile = JSON.parse(JSON.stringify(fileData));
      newfile.splice(filInd, 1);
      fileMeth(newfile);
      newForm.setFieldsValue({
        [name]: newfile.map((el) => el.url),
      });
    }
  };
  // 行业类型改变
  const industryCategoryChange = (e) => {
    setIndustryCategoryData(e);
  }
  const onSubmitClick = (value) => {
    setCurrent(value);
    setStepsName(value);
  }
  const onBack = () => {
    history.go(-1);
  }
  // 是否分支机构改变
  const branchOrNotChange = (e) => {
    setbranchOrNot(e.target.value);
  }
  // 获取验证码
  const getVerificationCode = () => {
    newForm.validateFields(['complaintsHotline']).then( async (values) => {
      setcodeLoading(true);
      setCountdown(60);
      await register.sendVerificationCode({phone: values.complaintsHotline});
      let time = 60;
       let count = setInterval(() => {
        setCountdown(time--);
        if(time === 0) {
          clearInterval(count)
          setcodeLoading(false);
        }
      },1000)
      message.success('发送成功')
    }).catch(() => {
      message.error('请检查联系人手机号是否正确')
    })
  }

  // 品牌弹窗
  const brandClick = () => {
    setBrandMode(true)
  }
  const setBrandDataForm = (value) => {
    setBrandData({brandName:value.brandName, brandLogo: value.brandLogo,archivesBusinessId:archivesId})
    newForm.setFieldsValue({brand:value.id});
  }
  // 切换表单
  const changeForm = (value) => {
    if (value === 0) {
      return <>
        <div style={{width: '500px', marginTop: '10%'}}>
          <Form.Item
            label="企业全称"
            validateFirst={true}
            name="archivesName"
            rules={[{required: true, message: "请输入企业全称"}]}
          >
            <Input
              allowClear={true}
              showCount
              maxLength={50}
              placeholder="请输入企业全称"
            />
          </Form.Item>
          <Form.Item
            label="统一社会信用代码"
            name="businessLicenseNumber"
            rules={[{required: true, message: "请输入统一社会信用代码"},
              ({getFieldValue}) => ({
                validator(_, value) {
                  if (!/[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g.test(value)) {
                    return Promise.reject(new Error('请输入正确统一社会信用代码'));
                  }
                  return Promise.resolve();
                },
              })]}
          >
            <Input
              allowClear={true}
              showCount
              maxLength={18}
              placeholder="请输入统一社会信用代码"
            />
          </Form.Item>
          <Form.Item
            label="企业类型"
            validateFirst={true}
            name="industryCategory"
            rules={[{required: true, message: "请选择企业类型"}]}
          >
            <Select style={{width: 120}} allowClear={true} placeholder="请输入企业类型">
              {industryCategory.map((el) => {
                return (
                  <Select.Option key={el.itemCode} value={el.itemCode}>
                    {el.itemValue}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="协会会员等级"
            rules={[{required: true, message: "请选择协会会员等级"}]}
            name="member">
            <Select style={{width: 120}} allowClear={true} placeholder="请输入协会会员等级">
              {memberDict.map((el) => {
                return (
                  <Select.Option key={el.itemCode} value={el.itemCode}>
                    {el.itemValue}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="联系人姓名"
            name="contactPeople"
            rules={[{required: true, message: "请输入联系人姓名"},
              ({getFieldValue}) => ({
                validator(_, value) {
                  if (!rules_vertify.NAME_REX.test(value)) {
                    return Promise.reject(new Error('请输入正确姓名'));
                  }
                  return Promise.resolve();
                },
              })]}
          >
            <Input
              allowClear={true}
              showCount
              maxLength={10}
              placeholder="请输入联系人姓名"
            />
          </Form.Item>
          <Form.Item
            label="联系人手机号"
            validateFirst={true}
            name="complaintsHotline"
            rules={[{required: true, message: "请输入联系人手机号"},
              ({getFieldValue}) => ({
                validator(_, value) {
                  if (!rules_vertify.PHONE_NUM.test(value)) {
                    return Promise.reject(new Error('联系电话格式错误'));
                  }
                  return Promise.resolve();
                },
              })]}
          >
            <Input
              allowClear={true}
              placeholder="请输入联系人手机号"
            />
          </Form.Item>
          <Form.Item
            label="账号"
            validateFirst={true}
            name="account"
            validateTrigger="onBlur"
            rules={[{required: true, message: "请输入账号"},
              {
                validator: (_, value) => {
                  // 使用正则表达式来校验密码 6-12位 字母或者数字组合
                  const passwordPattern = /^[0-9a-zA-Z]{6,12}$/;
                  if (!passwordPattern.test(value)) {
                    return Promise.reject('请输入6-12位字母或数字组合，区分大小写!');
                  }
                  return Promise.resolve();
                },
              },]}
          >
            <Input
              allowClear={true}
              placeholder="请输入账号"
            />
          </Form.Item>
          <Form.Item
            label="密码"
            validateFirst={true}
            name="password"
            validateTrigger="onBlur"
            rules={[{required: true, message: "请输入密码"},
              {
                validator: (_, value) => {
                  // 使用正则表达式来校验密码 6-12位 字母或者数字组合
                  const passwordPattern = /^[0-9a-zA-Z]{6,12}$/;
                  if (!passwordPattern.test(value)) {
                    return Promise.reject('请输入6-12位字母或数字组合，区分大小写!');
                  }
                  return Promise.resolve();
                },
              },]}
          >
            <Input.Password
              allowClear={true}
              placeholder="请输入密码"
            />
          </Form.Item>
          <Form.Item
            label="确认密码"
            name="confirmPassword"
            dependencies={['password']} // 修改密码时从新校验
            validateTrigger="onBlur"
            rules={[{required: true, message: "请确认密码"},
              ({getFieldValue}) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('两次密码不一致,请重新输入!');
                },
              })]}
          >
            <Input.Password
              allowClear={true}
              placeholder="请确认密码"
            />
          </Form.Item>
          <Form.Item
            label="验证码"
            name="verificationCode"
            rules={[{required: true, message: "请输入验证码"}]}
          >
            <div style={{display: "flex"}}>
              <Input
                allowClear={true}
                placeholder="请输入验证码"
              />
              <Button
                type="primary"
                style={{marginLeft: '30px'}}
                onClick={getVerificationCode}
                disabled={codeLoading}>
                发送验证码 {codeLoading ?  <span style={{marginLeft: 10}}>({countdown})</span> : null}
              </Button>
            </div>
          </Form.Item>
          <Form.Item style={{display: "flex", justifyContent: "center"}}>
            <Button
              type="primary"
              htmlType="submit"
              style={{width: '150px', height: '35px'}}
              loading={btnLoading}>
              完善资料
            </Button>
          </Form.Item>
        </div>
      </>
    }
    if (value === 1) {
      return <>
        <Row gutter={16} style={{width: '1000px'}}>
          <Col className="gutter-row" span={24} style={{marginBottom: '20px'}}>
            <span className="title">基本信息</span>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="企业全称"
              validateFirst={true}
              name="archivesName"
              rules={[{required: true, message: "请输入企业全称"}]}
            >
              <Input
                allowClear={true}
                disabled={true}
                placeholder="请输入企业全称"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label="统一社会信用代码"
                       name="businessLicenseNumber"
                       rules={[{required: true, message: "请输入统一社会信用代码"},
                         ({getFieldValue}) => ({
                           validator(_, value) {
                             if (!/[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g.test(value)) {
                               return Promise.reject(new Error('请输入正确统一社会信用代码'));
                             }
                             return Promise.resolve();
                           },
                         })]}
            >
              <Input
                allowClear={true}
                disabled={true}
                placeholder="请输入统一社会信用代码"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="行业类型"
              validateFirst={true}
              name="industryCategory"
              rules={[{required: true, message: "请选择行业类型"}]}
            >
              <Select disabled={true} style={{width: 120}} onChange={industryCategoryChange} allowClear={true} placeholder="请输入行业类型">
                {industryCategory.map((el) => {
                  return (
                    <Select.Option key={el.itemCode} value={el.itemCode}>
                      {el.itemValue}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="协会会员等级"
              rules={[{required: true, message: "请选择协会会员等级"}]}
              name="member">
              <Select disabled={true} style={{width: 120}} allowClear={true} placeholder="请输入协会会员等级">
                {memberDict.map((el) => {
                  return (
                    <Select.Option key={el.itemCode} value={el.itemCode}>
                      {el.itemValue}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="注册资本(万元)"
              validateFirst={true}
              name="registerCapital"
              rules={[{required: true, message: "请输入注册资本(万元)"},
              {
                validator: (_, value) => {
                  // 使用正则表达式来校验密码 6-12位 字母或者数字组合
                  const reg = /^-?\d*(.\d+)?$/;
                  if (!reg.test(value)) {
                    return Promise.reject('请输入数字格式');
                  }
                  return Promise.resolve();
                },
              }]}
            >
              <Input
                allowClear={true}
                placeholder="请输入注册资本(万元)"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="所在县区"
              validateFirst={true}
              name="administrativeAreaId"
              rules={[{required: true, message: "请选择关键字搜索"}]}
            >
              <Select style={{width: 120}} allowClear={true} placeholder="请选择关键字搜索">
                {administrativeArea.map((el) => {
                  return (
                    <Select.Option key={el.id} value={el.id}>
                      {el.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="经营模式"
              validateFirst={true}
              name="managementModel"
              rules={[{required: true, message: "请输入经营模式"}]}
            >
              <Select style={{width: 120}} allowClear={true} placeholder="请输入经营模式">
                {operatingModeDict.map((el) => {
                  return (
                    <Select.Option key={el.itemCode} value={el.itemCode}>
                      {el.itemValue}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={9}>
            <Form.Item
              label="品牌"
              validateFirst={true}
              name="brand"
              rules={[{required: true, message: "请选择品牌"}]}
            >
                <Select style={{width: 120}} allowClear={true}  placeholder="请选择品牌" disabled={true}>
                  {brandDict.map((el) => {
                    return (
                        <Select.Option key={el.itemCode} value={el.itemCode}>
                          {el.itemValue}
                        </Select.Option>
                    );
                  })}
                </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={3}>
              <Button type="primary" style={{width: '100%'}} onClick={brandClick}>请选择</Button>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="是否分支机构"
              validateFirst={true}
              name="branchOrNot"
              rules={[{required: true, message: "请选择上级机构"}]}
            >
              <Radio.Group onChange={branchOrNotChange}>
                <Radio value={true}>是</Radio>
                <Radio value={false}>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          {branchOrNot ? (<Col className="gutter-row" span={12}>
            <Form.Item
              label="上级机构"
              validateFirst={true}
              name="fatherDeptId"
              rules={[{required: true, message: "请选择上级机构"}]}
            >
              <Select
                showSearch
                placeholder="请输入关键字搜索"
                allowClear={true}
                filterOption={false}
                onSearch={handleSearch}
                options={(fatherDeptDict || []).map(d => ({
                  value: d.id,
                  label: d.name,
                }))}
              />
            </Form.Item>
          </Col>) : null}
          {operationMode? ( <Col className="gutter-row" span={12}>
            <Form.Item
              label="备案编号"
              validateFirst={true}
              name="filingsNumber"
              rules={industryCategoryData !== 2 ? [{required: true, message: "请输入备案编号"}] : []}
            >
              <Input
                allowClear={true}
                placeholder="请输入备案编号"
              />
            </Form.Item>
          </Col>) : null}
          {operationMode ? (<Col className="gutter-row" span={12}>
            <Form.Item
              label="备案有效期"
              validateFirst={true}
              name="deadlineForFilingValidity"
              rules={[{required: true, message: "请选择备案有效期"}]}
            >
              <DatePicker allowClear={true} format="YYYY-MM-DD"/>
            </Form.Item>
          </Col>) : null}
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="执照成立日期"
              validateFirst={true}
              name="registerTime"
              rules={[{required: true, message: "请输入执照成立日期"}]}
            >
              <DatePicker allowClear={true} format="YYYY-MM-DD"/>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24}>
            <Form.Item
              label="机构住所"
              validateFirst={true}
              name="registerAddress"
              rules={[{required: true, message: "请输入机构住所"}]}
            >
              <Input
                allowClear={true}
                placeholder="请输入机构住所"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24}>
            <Form.Item
              label="经营范围"
              validateFirst={true}
              name="businessScope"
              rules={[{required: true, message: "请选择经营范围"}]}
            >
              <Input
                  allowClear={true}
                  placeholder="请选择经营范围"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={23}>
            <Form.Item
              label="营业地址坐标"
              validateFirst={true}
              name="businessAddressCoordinate"
              rules={[{required: true, message: "请选择营业地址坐标"}]}
            >
              <Input
                  disabled
                  placeholder="请在后方选择营业地址地图坐标"
              />
            </Form.Item>
          </Col>
          <Col span={1}>
            <Button icon={<SendOutlined />} onClick={mapClick} style={{marginLeft: -8}}/>
          </Col>
          <Col className="gutter-row" span={24}>
            <Form.Item
                label="营业地址"
                validateFirst={true}
                name="businessAddress"
                rules={[{required: true, message: "请输入营业地址"}]}
            >
              <Input
                  placeholder="请输入营业地址"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="法人姓名"
              validateFirst={true}
              name="legalPersonName"
              rules={[{required: true, message: "请输入法人姓名"},
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (!rules_vertify.NAME_REX.test(value)) {
                      return Promise.reject(new Error('请输入正确姓名'));
                    }
                    return Promise.resolve();
                  },
                })]}
            >
              <Input
                allowClear={true}
                placeholder="请输入法人姓名"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="身份证号码"
              validateFirst={true}
              name="idCard"
              validateTrigger="onBlur"
              rules={[{required: true, message: "请输入身份证号码"},
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (!rules_vertify.ID_CARD_NUMBER.test(value)) {
                      return Promise.reject(new Error('身份证号码错误'));
                    }
                    return Promise.resolve();
                  },
                })]}
            >
              <Input
                allowClear={true}
                placeholder="请输入身份证号码"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="法人手机号"
              validateFirst={true}
              name="phone"
              validateTrigger="onBlur"
              rules={[{required: true, message: "请输入法人手机号"},
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (!rules_vertify.PHONE_NUM.test(value)) {
                      return Promise.reject(new Error('手机号格式错误'));
                    }
                    return Promise.resolve();
                  },
                })]}
            >
              <Input
                allowClear={true}
                placeholder="请输入法人手机号"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="联系人"
              name="contactPeople"
              rules={[{required: true, message: "请输入联系人"},
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (!rules_vertify.NAME_REX.test(value)) {
                      return Promise.reject(new Error('请输入正确姓名'));
                    }
                    return Promise.resolve();
                  },
                })]}
            >
              <Input
                allowClear={true}
                disabled={true}
                placeholder="请输入联系人"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="联系人手机号"
              name="complaintsHotline"
              rules={[{required: true, message: "请输入联系人手机号"},
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (!rules_vertify.PHONE_NUM.test(value)) {
                      return Promise.reject(new Error('联系电话格式错误'));
                    }
                    return Promise.resolve();
                  },
                })]}
            >
              <Input
                allowClear={true}
                disabled={true}
                placeholder="请输入联系人手机号"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="账号"
              name="account"
              rules={[{required: true, message: "请输入账号"}]}
            >
              <Input
                allowClear={true}
                disabled={true}
                placeholder="请输入账号"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="密码"
              name="password"
              rules={[{required: true, message: "请输入密码"}]}
            >
              <Input.Password
                allowClear={true}
                disabled={true}
                placeholder="请输入密码"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24} style={{marginBottom: '20px'}}>
            <span className="title">相关证件</span>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="法人身份证正反面"
              className="businessLicense"
              name="idCardImageList"
              rules={[{required: true, message: "请上传法人身份证正反面",validator: checkUser}]}
            >
              <Upload
                fileList={idCardImageList}
                beforeUpload={beforeUpload}
                onPreview={(file) => handlePreview("idCardImageList", file)}
                onRemove={(file) =>
                  delImg(file, "idCardImageList", {
                    fileData: idCardImageList,
                    fileMeth: setidCardImageList,
                  })
                }
                listType="picture-card"
              >
                {idCardImageList.length >= 2 ? null : uploadButton}
              </Upload>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="企业营业执照副本"
              className="businessLicense"
              name="licenseImageList"
              rules={[{required: true, message: "请上传企业营业执照副本",validator: checkUser}]}
            >
              <Upload
                fileList={licenseImageList}
                beforeUpload={licenseImageListBeforeUpload}
                onPreview={(file) => handlePreview("licenseImageList", file)}
                onRemove={(file) =>
                  delImg(file, "licenseImageList", {
                    fileData: licenseImageList,
                    fileMeth: setlicenseImageList,
                  })
                }
                listType="picture-card"
              >
                {licenseImageList.length >= 2 ? null : uploadButton}
              </Upload>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="机构备案证书"
              className="businessLicense"
              name="othersImageList"
              rules={industryCategoryData !== 2 ? [{required: true, message: "请上传机构备案证书", validator: checkUser}] : []}
            >
              <Upload
                fileList={othersImageList}
                beforeUpload={othersImageListBeforeUpload}
                onPreview={(file) => handlePreview("othersImageList", file)}
                onRemove={(file) =>
                  delImg(file, "othersImageList", {
                    fileData: othersImageList,
                    fileMeth: setothersImageList,
                  })
                }
                listType="picture-card"
              >
                {othersImageList.length >= 2 ? null : uploadButton}
              </Upload>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="店内经营场所照片"
              className="businessLicense"
              name="certificateOfFixedBusinessPremises"
              rules={[{required: true, message: "请上传机构店内经营场所照片",validator: checkUser}]}
            >
              <Upload
                fileList={certificateOfFixedBusinessPremisesImageList}
                beforeUpload={certificateOfFixedBusinessPremisesImageListBeforeUpload}
                onPreview={(file) => handlePreview("certificateOfFixedBusinessPremisesImageList", file)}
                onRemove={(file) =>
                  delImg(file, "certificateOfFixedBusinessPremisesImageList", {
                    fileData: certificateOfFixedBusinessPremisesImageList,
                    fileMeth: setCertificateOfFixedBusinessPremisesImageList,
                  })
                }
                listType="picture-card"
              >
                {certificateOfFixedBusinessPremisesImageList.length >= 1 ? null : uploadButton}
              </Upload>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="店外经营场所照片"
              className="businessLicense"
              name="photosOfBusinessPremises"
              rules={[{required: true, message: "请上传机构店外经营场所照片",validator: checkUser}]}
            >
              <Upload
                fileList={photosOfBusinessPremisesImageList}
                beforeUpload={photosOfBusinessPremisesImageListBeforeUpload}
                onPreview={(file) => handlePreview("photosOfBusinessPremisesImageList", file)}
                onRemove={(file) =>
                  delImg(file, "photosOfBusinessPremisesImageList", {
                    fileData: photosOfBusinessPremisesImageList,
                    fileMeth: setPhotosOfBusinessPremisesImageList,
                  })
                }
                listType="picture-card"
              >
                {photosOfBusinessPremisesImageList.length >= 1 ? null : uploadButton}
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{display: "flex", justifyContent: "center"}}>
          <Button
            type="primary"
            htmlType="submit"
            style={{width: '150px', height: '35px'}}
            loading={btnLoading}>
            提交
          </Button>
        </Form.Item>
      </>
    }
    if (value === 2) {
      return <>
        <div className="review">
          <CheckCircleFilled style={{fontSize: '100px', color: 'rgb(75, 121, 0)', marginTop: '-20px'}}/>
          <div>
            <div className="review-title">
              恭喜您，您的注册申请已成功提交！
            </div>
            <div className="review-content">
              我们的工作人员将在收到您完整的申请资料后进行审核确认，确认时间一般为 <span
              className="yellow-text">3-5个工作日</span>
            </div>
            <div className="review-content">
              审核期间，您可以通过‘账号’登录网站查看提交的资料和审核进度。
              如有疑问，请拨打咨询电话：<span className="yellow-text">0315-8080089</span>
            </div>
          </div>
        </div>
        <div className="review-back">
          <Button
            type="primary"
            htmlType="submit"
            onClick={onBack}>
            返回登录
          </Button>
        </div>
      </>
    }
  }
  return (
    <div className="registerForm">
      <Form
        name="basic"
        form={newForm}
        initialValues={formValue}
        colon={false}
        className="form"
        onFinish={onFinish}
        size={'default'}
        labelAlign="right"
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        {changeForm(stepsName)}
      </Form>
      <Modal open={showPreview} title="预览图片" footer={null} onCancel={handleCancel}>
        <img alt="example" style={{width: '100%'}} src={perviewImg}/>
      </Modal>
      <BrandMode brandMode={brandMode}
                 setBrandMode={setBrandMode}
                 setBrandData={setBrandDataForm}
                 brandDict={brandDict}
      ></BrandMode>
      {mapDisplay ? (
          <Amap mapDisplay={mapDisplay} setmapDisplay={setmapDisplay}
                AddressOK={businessAddressOK} options={options} setoptions={setoptions}
          >
          </Amap>
          ) : null
      }
    </div>
  );
};
