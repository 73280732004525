import cardManagementRecordApi from '../../../api/ManagePage/Card/Card'
class Card {
  // 创建单例
  static getInstance() {
    if(!this.cardManagement) {
      this.cardManagement = new Card()
    }
    return this.cardManagement
  }
  // 获取 表头
  registrationCardProcessList() {
    return cardManagementRecordApi.registrationCardProcessList()
  }
  // 获取表格数据
  registrationCardProcess(page,size,data) {
    return cardManagementRecordApi.registrationCardProcess(page,size,data)
  }
  // 获取表格数据
  preview(id) {
    return cardManagementRecordApi.preview(id)
  }
  // 获取表格数据
  mailingInfo(id) {
    return cardManagementRecordApi.mailingInfo(id)
  }
}
export default Card.getInstance()
