import React, {useState, useEffect} from "react";
import '../../../styles/Publicity/Corporat/addPersonnelRegister.scss'
import {Button, Col, Row, Spin, Image} from 'antd'
import mitt from '../../../assest/utils/mitt'
import EnterpriseRecord from "../../../service/ManagePage/Enterprise/Enterprise";
// 引入图片路径
import request_url from '../../../assest/utils/request_url'
import TabTable from "../tabTable";
import member_img from "../../../assest/image/member.png";
import unmember_img from "../../../assest/image/unmember.png";
let baseUrl = request_url('imgUrl')
export default (prop) => {
  const {tableRow, onCancleDetail} = prop;
  const [detail, setDetail] = useState({});//详情信息
  useEffect(() => {
    // 获取详情
    getDetail()
  }, []);
  useEffect(() => {
    // console.log(detail);
  }, [detail]);
  useEffect(() => {
    mitt.on('refreshDetailPage', mittRefreshDetail)
    return () => {
      mitt.off('refreshDetailPage', mittRefreshDetail);
    }
  }, [])
  const mittRefreshDetail = () => { // 刷新页面
    getDetail();
  }
  // 返回列表
  const onBack = () => {
    onCancleDetail()
  }
  //获取详情
  const getDetail = async () => {
    let {archivesId} = tableRow;
    let res = await EnterpriseRecord.filingRecordsDetail({history: false, archivesBusinessId: archivesId})
    setDetail(res)
  }
  return (
    <div id="AddPersonnelRegister" className="detailPersonnelRegister">
      {detail !== {} ?
        <div className="info">
          <div className="title">
            基本信息
          </div>
          <Row className="row">
            <Col style={{marginTop: 0}} span={14}>企业名称：
              <div className="member_logo">
                {detail.archivesName || '-'}
                {
                  detail.memberName !== '非会员' ? <img style={{width: '75px',pointerEvents: "none"}} src={member_img} alt=""/> :
                      <img style={{width: '75px',pointerEvents: "none"}} src={unmember_img} alt=""/>
                }
              </div>
            </Col>
            <Col style={{marginTop: 0}} span={10}>统一信用代码：<span>{detail.businessLicenseNumber || '-'}</span></Col>

            <Col span={14}>行业类型：<span>{detail.industryCategoryName || '-'}</span></Col>
            <Col span={10}>协会会员：<span>{detail.memberName || '-'}</span></Col>

            <Col span={14}>经营模式：<span>{detail.managementModelName || '-'}</span></Col>
            <Col span={10}>品牌：<span>{detail.brandName || '-'}</span></Col>

            <Col span={14}>注册资本：<span>{detail.registerCapital || '-'}</span></Col>
            <Col span={10}>所属地区：<span>{detail.administrativeArea || '-'}</span></Col>

            <Col span={14}>成立日期：<span>{detail.registerTime || '-'}</span></Col>
            <Col span={10}>上级机构：<span>{detail.fatherDept || '-'}</span></Col>

            <Col span={14}>机构住所：<span>{detail.registerAddress || '-'}</span></Col>
            <Col span={10}>法人姓名：<span>{detail.legalPersonName || '-'}</span></Col>

            <Col span={24}>营业地址：<span>{detail.businessAddress || '-'}</span></Col>
            <Col span={24}>经营范围：<span>{detail.businessScope || '-'}</span></Col>

            <Col span={14}>联系人：<span>{detail.contactPeople || '-'}</span></Col>
            <Col span={10}>投诉电话：<span>{detail.complaintsHotline || '-'}</span></Col>
            <Col style={{marginBottom: 0}} span={14}>备案编号：<span>{detail.recordCertificateNumber || '-'}</span></Col>
            <Col style={{marginBottom: 0}}
                 span={10}>登记有效期：<span>{detail.deadlineForFilingValidity || '-'}</span></Col>
          </Row>
          <div className="title">
            相关证件
          </div>
          <Row className="row">
            <Col span={24}>
              <div className="label">机构备案证书：</div>
              {detail.othersImageList !== undefined ?
                detail.othersImageList.map(item => <Image width={102}
                  style={{width: '102px', height: '102px', verticalAlign: 'text-top', marginRight: '20px'}}
                  src={baseUrl + item} alt=""/>)
                : '-'}
            </Col>
          </Row>
        </div> : <Spin size="large"/>}
      <div className="dividing"></div>
      <div className="info">
        <div className="title">
          行为记录
        </div>
        <TabTable subjectId={tableRow.archivesId} subjectType={131}></TabTable>
      </div>
      <Button className="onBack" style={{marginBottom: 20}} onClick={onBack}>返回</Button>
    </div>
  );
};
