import '../../styles/appraise/appraise.scss'
import {LeftOutlined} from "@ant-design/icons";
import {Button, Form, Input, message, Rate} from "antd";
import practitionersRegistration from "../../service/ManagePage/Personnel/Personnel";
import TextArea from "antd/es/input/TextArea";
import {useHistory} from "react-router-dom";
import {useState} from "react";
import * as rules_vertify from "../../assest/utils/rules_verfity";

export default () => {
  let history = useHistory();
  const [id, setid] = useState(history.location.pathname.split('/')[history.location.pathname.split('/').length - 1]);// 详情

  const onFinish = async (values) => {
    values.beEvaluatedPersonId = id;
    await practitionersRegistration.postComments(values);
    message.success('评价成功！');
    history.push({pathname: `/appraise/${id}`})
  }
  const onFinishFailed = () => {

  }
  return (
    <div id="userReview">
      <div className="navigation">
        <LeftOutlined onClick={() => {
          history.push({pathname: `/appraise/${id}`})
        }}/>
        <span>发表评价</span>
        <div></div>
      </div>
      <Form
        style={{padding: 30}}
        size={'default'}
        className="form"
        labelAlign="right"
        autoComplete="off"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="评价人姓名"
          name="evaluatorName"
          rules={[{required: true, message: '请输入姓名'}]}
        >
          <Input placeholder="请输入姓名"/>
        </Form.Item>
        <Form.Item
          label="手机号"
          name="phone"
          rules={[{required: true, message: '请输入手机号'},
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!rules_vertify.PHONE_NUM.test(value)) {
                  return Promise.reject(new Error('手机号格式错误'));
                }
                return Promise.resolve();
              },
            })]}
        >
          <Input placeholder="请输入手机号"/>
        </Form.Item>
        <Form.Item
          label="综合评分"
          name="comprehensiveScore"
        >
          <Rate/>
        </Form.Item>
        <Form.Item
          label="服务态度"
          name="serviceAttitude"
        >
          <Rate/>
        </Form.Item>
        <Form.Item
          label="专业程度"
          name="professionalLevel"
        >
          <Rate/>
        </Form.Item>
        <Form.Item
          label=""
          name="overallEvaluation"
        >
          <TextArea
            showCount
            maxLength={500}
            placeholder="多个角度评价，可以帮助经纪人提高服务质量，激励我们做的更好"
            style={{height: 120, resize: 'none'}}
          />
        </Form.Item>
        <Form.Item wrapperCol={{offset: 8, span: 16}}>
          <Button type="primary" htmlType="submit">发布评价</Button>
        </Form.Item>
      </Form>
    </div>
  )
}
