import practitionersRegistration from "../../service/ManagePage/Personnel/Personnel";
import {useEffect, useRef, useState} from "react";
import '../../styles/BehaviorRecords/BehaviorRecords.scss'
import {Table} from "antd";

export default (props) => {
  const {searchName,onSearch} = props;
  const [agencyList, setAgencyList] = useState([]);// 列表
  const [agencyHead, setAgencyHead] = useState([]);// 表头
  const [tableLoading, setTableLoading] = useState(false)// 表格loading
  const pageInfo = useRef({page: 1, size: 20});
  const [total, setTotal] = useState(0);// 总数
  useEffect(() => {
    getAgencyList();
    getAgencyHead();
  }, []);
  useEffect(() => {
    getAgencyList();
  }, [onSearch]);
  // 获取表头
  const getAgencyHead = async () => {
    let res = await practitionersRegistration.tableHeader({requestParam:'blackListBehaviorList'});
    res.map((el, ind) => {
      {
        el.key = el.data
        el.dataIndex = el.data
        el.title = el.headerFirst
      }
    })
    setAgencyHead(res);
  }
  // 切换列表
  const onPageChange = (page, size) => {
    pageInfo.current = {page, size};
    getAgencyList();
  }
  // 获取列表
  const getAgencyList = async () => {
    try {
      setTableLoading(true)
      let data = await practitionersRegistration.blackListBehaviorList({page:pageInfo.current.page,
        size:pageInfo.current.size,keywords: searchName});
      setAgencyList(data.list);
      setTotal(data.totalCount);
      setTableLoading(false);
    } catch (error) {
      setTableLoading(false);
      console.log('error', error);
    }
  }
  return (
    <div id="BlackList">
      <div className="organ-util">
        <div className="util-left">
          <div className="title">
            黑名单行为记录
          </div>
          <p className="description">
            被行业禁业黑名单曝光人员或机构，禁止任何经纪机构、开发企业、代理机构、房地产互联网服务机构、房地产金融服务机构与其进行合作、聘用、服务等事项，否则将依照《房地产经纪管理办法》、《唐山市房地产交易管理条例》、《互联网信息服务管理办法》、《唐山市房地产中介服务行业信用管理办法》等相关规定，进行责令停业整顿、暂时关闭网站直至关闭网站、发证机关吊销经营许可证、停止网签、罚款等处罚，构成犯罪的，依法追究刑事责任。
          </p>
        </div>
        <div className="organ-right"></div>
      </div>
      <div className="organ-contain">
        <div className="organ-table">
          <Table
            columns={agencyHead}
            dataSource={agencyList}
            size="small"
            bordered
            scroll={{x: '100%', y: '520px'}}
            loading={tableLoading}
            rowClassName="organManagement-row"
            pagination={
              {
                showSizeChanger: true,
                defaultPageSize: 20,
                total: total,
                current: pageInfo.page,
                onChange: onPageChange
              }
            }
          />
        </div>
      </div>
    </div>
  );
};
