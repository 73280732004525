import corporatePublicity from '../../../api/Publicity/Corporate/corporatePublicity'
class CorporatePublicity {
  // 创建单例
  static getInstance() {
    if(!this.enterpriseRecord) {
      this.enterpriseRecord = new CorporatePublicity()
    }
    return this.enterpriseRecord
  }
  // 获取表格数据
  institutionalPublicityQuery(data) {
    return corporatePublicity.institutionalPublicityQuery(data)
  }
  // 获取表头
  institutionalPublicityQueryHeader() {
    return corporatePublicity.institutionalPublicityQueryHeader()
  }
}
export default CorporatePublicity.getInstance()
