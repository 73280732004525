import React, {useState, useEffect, useRef} from "react";
import "../../../styles/ManagePage/Enterprise/addOrgan.scss";
import practitionersRegistration from "../../../service/ManagePage/Personnel/Personnel";
import EnterpriseRecord from "../../../service/ManagePage/Enterprise/Enterprise";
import {Button, Cascader, Col, DatePicker, Form, Input, message, Modal, Radio, Row, Select, Upload} from "antd";
import moment from 'moment'
import uploadImg from "../../../assest/utils/upload";
import {PlusOutlined} from "@ant-design/icons";
import * as rules_vertify from "../../../assest/utils/rules_verfity";
import ImgCrop from 'antd-img-crop';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default (prop) => {
  const {addSuccess, onCancleAdd} = prop;
  const [btnLoading, setBtnLoading] = useState(false); // 按钮loading

  const [perviewImg, setPerviewImg] = useState([]); // 预览图片数组
  const [faceImgUrlList, setfaceImgUrlList] = useState([]); // 免冠证件照
  const [contractImgUrl, setcontractImgUrl] = useState([]); // 身份证正反面
  const [qualification, setqualification] = useState([]); // 资格证书
  const [registrationCertificate, setregistrationCertificate] = useState([]); // 登记证书
  const [showPreview, setShowPreview] = useState(false); // 预览图片弹窗
  const [certificateCode, setcertificateCode] = useState(false); // 资格证编号是否显示
  const [inDeptId, setinDeptId] = useState(0); // 资格证编号是否显示
  const [houseAgentId, sethouseAgentId] = useState(null); // 房产经济

  const [sex, setsex] = useState([]); // 性别字典
  const [politicalFace, setpoliticalFace] = useState([]); // 政治面貌字典
  const [postType, setpostType] = useState([]); // 从业人员类型字典
  const [education, seteducation] = useState([]); // 学历字典
  const [dictionaryList, setdictionaryList] = useState([]); // 职务字典
  const [houseAgentDict, sethouseAgentDict] = useState([]); // 房产经济字典

  const [newForm] = Form.useForm();
  const [formValue, setFormValue] = useState({branchOrNot: false}); // 表单默认值
  useEffect(() => {
    getDict();
  }, []);
  // 获取字典
  const getDict = async () => {
    let data = await practitionersRegistration.getSexDict();
    setsex(data)
    data = await practitionersRegistration.getPoliticalFaceDict();
    setpoliticalFace(data)
    data = await practitionersRegistration.queryPostType();
    setpostType(data)
    data = await practitionersRegistration.getEducationDict();
    seteducation(data)
    data = await practitionersRegistration.houseAgent();
    sethouseAgentDict(data)
    data = await practitionersRegistration.dictionaryList(0);
    setdictionaryList(data)
    data = await EnterpriseRecord.obtainDeptThroughMerchants(JSON.parse(sessionStorage.getItem('info')).archivesId)
    setinDeptId(data)
  };
  // 表单校验成功
  const onFinish = async (value) => {
    let {
      idCard, name, phone, sex, politicalFace,
      contractImgUrl, qualification, registrationCertificate,
      certificateCode, inPostId, postType, faceImgUrlList, educationId
    } = value
    // 格式化字段
    contractImgUrl = contractImgUrl.fileList.map(item => item.url)
    // faceImgUrlList = faceImgUrlList.fileList.map(item => item.url)

    // 根据从业人员类型判断照片是否需要存在
    if (postType !== 3) {
      if (registrationCertificate) registrationCertificate = registrationCertificate.fileList.map(item => item.url)
      qualification = qualification.fileList.map(item => item.url)
    }


    // 调整字段位置
    let data = {
      faceImgs: [],
      idCardImage: '',
      remarkDTOList: [],
      basicPeopleDTO: {
        idCard,
        name,
        phone,
        politicalFace,
        sex,
        idCardType: 1
      }, // idCard name phone sex politicalFace idCardType(默认身分之 传1)
      contractDTO: {
        contractImgUrl,
        qualification,
        registrationCertificate
      }, // contractImgUrl qualification registrationCertificate
      head: faceImgUrlList,
      houseAgentDTO: {
        certificateCode,
        houseAgentId
      }, // certificateCode houseAgentId
      educationDTO: {educationId}, // educationId
      peopleRelationDTO: {
        name: null,
        relationPhone: null,
        relationType: null
      },
      workPeopleDTO: {
        inPostId,
        postType,
        startTime: moment().format("YYYY-MM-DD"),
        inDeptId,
        paymentType: 1,
        workPhone: phone,
        levelId: 1,
      }, // inPostId inDeptId(需要拿接口用id请求) postType startTime(获取当天日期 "2023-10-25")
    }
    await practitionersRegistration.peoplePost(data);
    addSuccess()
  };
  // 上传图片校验
  const checkUser = (rule, value) => {
    if (!value) {
      return Promise.reject();
    }
    // 判断是否是数组，因为修改带入的数据是数组的形式
    if(value.constructor===Array) {
      if(value.length > 0) {
        return Promise.resolve();
      }else {
        return Promise.reject();
      }
    }else if(value.constructor===String){
      // value是单张字符串的情况
      if(value){
        return Promise.resolve()
      }
    }else {
      // value是file对象的情况
      if (!value.file.status) {
        return Promise.resolve();
      }
    }
    return Promise.reject();
  };
  // 表单校验成功
  const onFinishFailed = async (value) => {
    console.log(value.values);
    // onBack()
  };
  // 关闭预览图片弹窗
  const handleCancel = () => setShowPreview(false);
  // 返回列表
  const onBack = async (value) => {
    onCancleAdd();
  };
  // 控制证件编号显示
  const postTypeChange = async (value) => {
    setcertificateCode(value !== 3);
    if (value === 3) {
      newForm.setFieldValue('certificateCode', null);
      sethouseAgentId(null);
      setqualification([])
      setregistrationCertificate([])
    } else {
      let name = value === 1 ? '经纪人' : '协理'
      sethouseAgentId(houseAgentDict.find(item => item.uname === name).uvalue);
    }
  };
  // 上传图片 面馆证件照片
  const beforeUpload = async (file) => {
    let data = await uploadImg(file);
    file.uid = data.name.split('/')[1];
    file.url = data.url;
    let arr = JSON.parse(JSON.stringify(faceImgUrlList));
    arr.push(file);
    setfaceImgUrlList(arr);
    newForm.setFieldValue('faceImgUrlList',file.url)
    return false;
  };
  // 上传图片 身份证正反面
  const licenseImageListBeforeUpload = async (file) => {
    let data = await uploadImg(file);
    file.uid = data.name.split('/')[1];
    file.url = data.url;
    let arr = JSON.parse(JSON.stringify(contractImgUrl));
    arr.push(file);
    setcontractImgUrl(arr);
    return false;
  };
  // 上传图片 资格证书
  const othersImageListBeforeUpload = async (file) => {
    let data = await uploadImg(file);
    file.uid = data.name.split('/')[1];
    file.url = data.url;
    let arr = JSON.parse(JSON.stringify(qualification));
    arr.push(file);
    setqualification(arr);
    return false;
  };
  // 上传图片 登记证书
  const registrationCertificateBeforeUpload = async (file) => {
    let data = await uploadImg(file);
    file.uid = data.name.split('/')[1];
    file.url = data.url;
    let arr = JSON.parse(JSON.stringify(registrationCertificate));
    arr.push(file);
    setregistrationCertificate(arr);
    return false;
  };
  // 图片预览
  const handlePreview = async (name, file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPerviewImg(file.url || file.preview);
    setShowPreview(true);
  };
  const delImg = (file, name, {fileData, fileMeth}) => {
    let {uid} = file;
    let filInd = fileData.findIndex((el) => uid === el.uid);
    if (filInd !== -1) {
      let newfile = JSON.parse(JSON.stringify(fileData));
      newfile.splice(filInd, 1);
      fileMeth(newfile);
      newForm.setFieldsValue({
        [name]: newfile.map((el) => el.url),
      });
    }
  };
  const uploadButton = (
    <div>
      <PlusOutlined/>
    </div>
  );
  return (
    <div id="addOrgan" style={{zIndex: 10}}>
      <Form
        name="basic"
        form={newForm}
        initialValues={formValue}
        colon={false}
        className="form"
        onFinish={onFinish}
        size={'default'}
        labelAlign="right"
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row gutter={16} style={{width: '100%'}}>
          <Col className="gutter-row" span={24}>
            <div className="title">
              基本信息
            </div>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="姓名"
              validateFirst={true}
              name="name" // peopleBasicInfoVo
              rules={[{required: true, message: "请输入姓名"},
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (!rules_vertify.NAME_REX.test(value)) {
                      return Promise.reject(new Error('请输入正确姓名'));
                    }
                    return Promise.resolve();
                  },
                })]}
            >
              <Input
                allowClear={true}
                placeholder="请输入姓名"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item label="性别"
                       name="sex" // peopleBasicInfoVo
                       rules={[{required: true, message: "请输入性别"}]}
            >
              <Radio.Group>
                {
                  sex.map(item => (<Radio value={item.value}>{item.label}</Radio>))
                }
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="学历"
              validateFirst={true}
              name="educationId" // educationDTO
              rules={[{required: true, message: "请选择学历"}]}
            >
              <Select style={{width: 120}} allowClear={true} placeholder="请选择学历">
                {education.map((el) => {
                  return (
                    <Select.Option key={el.uvalue} value={el.uvalue}>
                      {el.uname}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="身份证号码"
              validateTrigger="onBlur"
              rules={[{required: true, message: "请输入身份证号码"},
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (!rules_vertify.ID_CARD_NUMBER.test(value)) {
                      return Promise.reject(new Error('身份证号码错误'));
                    }
                    return Promise.resolve();
                  },
                })]}
              name="idCard"// peopleBasicInfoVo
            >
              <Input
                allowClear={true}
                placeholder="请输入身份证号码"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="政治面貌"
              validateFirst={true}
              name="politicalFace" // peopleBasicInfoVo
              rules={[{required: true, message: "请输入政治面貌"}]}
            >
              <Select style={{width: 120}} allowClear={true} placeholder="请输入协会会员等级">
                {politicalFace.map((el) => {
                  return (
                    <Select.Option key={el.itemCode} value={el.itemCode}>
                      {el.itemValue}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="手机号码"
              validateFirst={true}
              name="phone" // peopleBasicInfoVo
              validateTrigger="onBlur"
              rules={[{required: true, message: "请输入手机号码"},
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (!rules_vertify.PHONE_NUM.test(value)) {
                      return Promise.reject(new Error('联系电话格式错误'));
                    }
                    return Promise.resolve();
                  },
                })]}
            >
              <Input
                allowClear={true}
                placeholder="请输入手机号码"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="从业人员类型"
              validateFirst={true}
              name="postType" // peopleBasicInfoVo
              rules={[{required: true, message: "请输入从业人员类型"}]}
            >
              <Select style={{width: 120}} onChange={postTypeChange} allowClear={true} placeholder="请选择从业人员类型">
                {postType.map((el) => {
                  return (
                    <Select.Option key={el.itemCode} value={el.itemCode}>
                      {el.itemValue}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          {/*<Col className="gutter-row" span={8}>*/}
          {/*  <Form.Item*/}
          {/*    label="职务"*/}
          {/*    validateFirst={true}*/}
          {/*    name="inPostId" // peopleBasicInfoVo*/}
          {/*    rules={[{required: true, message: "请输入职务"}]}*/}
          {/*  >*/}
          {/*    <Select style={{width: 120}} allowClear={true} placeholder="请选择职务">*/}
          {/*      {dictionaryList.map((el) => {*/}
          {/*        return (*/}
          {/*          <Select.Option key={el.value} value={el.value}>*/}
          {/*            {el.label}*/}
          {/*          </Select.Option>*/}
          {/*        );*/}
          {/*      })}*/}
          {/*    </Select>*/}
          {/*  </Form.Item>*/}
          {/*</Col>*/}
          {
            certificateCode ? (
              <Col className="gutter-row" span={8}>
                <Form.Item
                  label="资格证编号"
                  validateFirst={true}
                  name="certificateCode" // houseAgentDTO
                  rules={[{required: true, message: "请输入资格证编号"}]}
                >
                  <Input
                    allowClear={true}
                    placeholder="请输入资格证编号"
                  />
                </Form.Item>
              </Col>
            ) : null
          }
          <Col className="gutter-row" span={24}>
            <div className="title">
              相关证件
            </div>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="免冠证件照"
              className="businessLicense"
              name="faceImgUrlList" // peopleBasicImgUrlVo
              rules={[{required: true, message: "请上传白底免冠证件照"}]}
            >
              <div class="remark-message">（白底免冠证件照）</div>
              <ImgCrop rotationSlider quality={1} aspect={2.2 / 3} modalClassName='imgCrop'>
                <Upload
                    fileList={faceImgUrlList}
                    beforeUpload={beforeUpload}
                    onPreview={(file) => handlePreview("faceImgUrlList", file)}
                    onRemove={(file) =>
                        delImg(file, "faceImgUrlList", {
                          fileData: faceImgUrlList,
                          fileMeth: setfaceImgUrlList,
                        })
                    }
                    listType="picture-card"
                >
                  {faceImgUrlList.length >= 1 ? null : uploadButton}
                </Upload>
              </ImgCrop>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="身份证正反面"
              className="businessLicense"
              name="contractImgUrl" // contractInfoVo
              rules={[{ required: true, validator: checkUser, message: "请上传身份证正反面" }]}
            >
              <Upload
                fileList={contractImgUrl}
                beforeUpload={licenseImageListBeforeUpload}
                onPreview={(file) => handlePreview("contractImgUrl", file)}
                onRemove={(file) =>
                  delImg(file, "contractImgUrl", {
                    fileData: contractImgUrl,
                    fileMeth: setcontractImgUrl,
                  })
                }
                listType="picture-card"
              >
                {contractImgUrl.length >= 2 ? null : uploadButton}
              </Upload>
            </Form.Item>
          </Col>
          {
            certificateCode ? (
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label="资格证书"
                  className="businessLicense"
                  name="qualification" // contractInfoVo
                  rules={[{required: true, message: "请上传资格证书", validator: checkUser}]}
                >
                  <Upload
                    fileList={qualification}
                    beforeUpload={othersImageListBeforeUpload}
                    onPreview={(file) => handlePreview("qualification", file)}
                    onRemove={(file) =>
                      delImg(file, "qualification", {
                        fileData: qualification,
                        fileMeth: setqualification,
                      })
                    }
                    listType="picture-card"
                  >
                    {qualification.length >= 2 ? null : uploadButton}
                  </Upload>
                </Form.Item>
              </Col>
            ) : null
          }
          {
            certificateCode ? (
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label="登记证书"
                  className="businessLicense"
                  name="registrationCertificate" // contractInfoVo
                  // rules={[{required: true, message: "请上传登记证书"}]}
                >
                  <Upload
                    fileList={registrationCertificate}
                    beforeUpload={registrationCertificateBeforeUpload}
                    onPreview={(file) => handlePreview("registrationCertificate", file)}
                    onRemove={(file) =>
                      delImg(file, "registrationCertificate", {
                        fileData: registrationCertificate,
                        fileMeth: setregistrationCertificate,
                      })
                    }
                    listType="picture-card"
                  >
                    {registrationCertificate.length >= 2 ? null : uploadButton}
                  </Upload>
                </Form.Item>
              </Col>
            ) : null
          }
        </Row>
        <Form.Item style={{display: "flex", justifyContent: "center", marginTop: '50px'}}>
          <Button
            type="primary"
            htmlType="submit"
            style={{width: '150px', height: '35px'}}
            loading={btnLoading}>
            提交
          </Button>
          <Button
            htmlType="submit"
            style={{width: '150px', height: '35px', marginLeft: '20px'}}
            onClick={onBack}>
            返回
          </Button>
        </Form.Item>
      </Form>
      <Modal open={showPreview} title="预览图片" footer={null} onCancel={handleCancel}>
        <img alt="example" style={{width: '50%',margin: "auto",display: "block"}} src={perviewImg}/>
      </Modal>
    </div>
  );
};
