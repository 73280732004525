const initialState = {
  platformInfo:{},// 平台信息
}
export default (state = initialState,action) => {
  switch(action.type) {
      case 'changePlat' :
          state.platformInfo = action.value
          return state
      default:
          return state
  }
}
