import axios from '../axios'
import request_url from '../../assest/utils/request_url'
let baseUrl = request_url('enterpriseRecord')
export default {
  // 企业类型-字典查询
  industryCategory() {
    return axios.get(`${baseUrl}archives/merchant/dictionary/industryCategory`)
  },
  // 行业类别-字典查询
  getMember() {
    return axios.get(`${baseUrl}archives/merchant/dictionary/member`)
  },
  // 经营模式-字典查询
  operatingMode() {
    return axios.get(`${baseUrl}archives/merchant/dictionary/operatingMode`)
  },
  // 品牌-字典查询
  brandDictionary() {
    return axios.get(`${baseUrl}archives/merchant/dictionary/brandDictionary`)
  },
  // 上级机构 搜索 字典
  deptSearch(deptName) {
    return axios.get(`${baseUrl}archives/merchant/keyWord/deptSearch/${deptName}`)
  },
  // 所在县区 搜索 字典
  searchCnare(data) {
    return axios.post(`${baseUrl}archives/merchant/query/searchCnare`,data)
  },
  // 机构账号注册
  institutionRegistration(data) {
    return axios.post(`${baseUrl}archives/merchant/management/institutionRegistration`,data)
  },
  // 账号查重
  checkAccount(data) {
    return axios.post(`${baseUrl}archives/merchant/management/checkAccount`,data)
  },
  // 完善机构信息
  institutionPerfect(data) {
    return axios.post(`${baseUrl}archives/merchant/management/institutionPerfect`,data)
  },
  // 登录后判断是否已完善信息
  isNeedToImprove(archivesBusinessId) {
    return axios.get(`${baseUrl}archives/merchant/management/isNeedToImprove/${archivesBusinessId}`)
  },
  // 获取验证码
  sendVerificationCode(data) {
    return axios.post(`${baseUrl}archives/merchant/management/sendVerificationCode`, data)
  },
  // 运转模式字典
  getOperationMode() {
    return axios.get(`${baseUrl}archives/merchant/dictionary/operationMode`)
  },
  // 品牌申请状态-字典
  applyBrandStatus() {
    return axios.get(`${baseUrl}archives/merchant/dictionary/applyBrandStatus`)
  },
  // 品牌申请，获取未处理数，显示到按钮上
  applyBrandCount() {
    return axios.get(`${baseUrl}archives/merchant/query/applyBrandCount`)
  },
  // 申请加入品牌库
  applyBrandLibrary(data) {
    return axios.post(`${baseUrl}archives/merchant/management/applyBrandLibrary`,data)
  },
}
