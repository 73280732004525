import { useState,useEffect } from "react";
import { Table, Space, Button } from 'antd';
import '../../../styles/ManagePage/Enterprise/enterpriseRecordTable.scss'
export default (prop) => {
  const {pageInfo,agencyList,agencyHead,total,onPageChange,onModify,loading,onDetail,onEntryQuit,onCardMaking} = prop;
  const [head,setHead] = useState([])
  useEffect(() => {
    handleHead()
  }, [agencyHead,agencyList])
  const columns = [
    {
      title: '操作',
      key: 'action',
      width: 250,
      // width: 150,
      fixed:'right',
      className:'organ-action',
      render: (text, row) => {
        return (
            <Space size="middle">
              <Button type="link" onClick={() => onDetail(row)}>详情</Button>
              <Button type="link" onClick={() => onEntryQuit(row)}>注销</Button>
              <Button type="link" onClick={() => onCardMaking(row)}>申请制卡</Button>
            </Space>
        )
      },
    },
  ];
  const handleHead = () => {
    let arr = JSON.parse(JSON.stringify(agencyHead));
    arr.map((el,ind) => {
      if (el.headerFirst === '机构名称') {
        el.key = 'postType'
        el.dataIndex = 'postType'
        el.title = '从业人员类型'
      }else if (el.headerFirst === '职务') {
        el.key = 'certificateCode'
        el.dataIndex = 'certificateCode'
        el.title = '资格证号'
      }else if (el.headerFirst === '登记时间') {
        el.key = el.data
        el.dataIndex = el.data
        el.title = '申请日期'
      }else if (el.headerFirst === '状态') {
        el.key = 'registrationCode'
        el.dataIndex = 'registrationCode'
        el.title = '实名登记编号'
      }else if (el.headerFirst === '状态变更时间') {
        delete el.data
        delete el.headerFirst
      }else {
        {
          el.key = el.data
          el.dataIndex = el.data
          el.title = el.headerFirst
        }
      }
    })
    arr = [...arr.filter(item => item.dataIndex !== undefined),...columns]
    setHead(arr)
  }
  return (
    <div className="organ-table">
      <Table
        scroll={{ x: '100%', y: '520px'}}
        columns={[...head]}
        loading={loading}
        bordered
        size={'small'}
        dataSource={agencyList}
        rowClassName="organManagement-row"
        pagination={
          {
            showSizeChanger:true,
            defaultPageSize:20,
            total:total,
            current:pageInfo.page,
            onChange:onPageChange
          }
        }/>
    </div>
  )
}
