import '../../styles/public/baseLoginLayout.scss'
import yx_logo from '../../assest/image/yx_logo.png'
import {
    WindowsFilled
    } from "@ant-design/icons";
export default (props) => {
  const {slot} = props;
  const downloadExe = () => {
      window.open('https://qj-apk.oss-cn-beijing.aliyuncs.com/MisExe/FangXie/ZhiZhe_Setup(fx).exe')
  }
  return (
    <div id="login-layout">
      <div className='login-container'>
        <div className="login-left">
          <div className="title">
            <div>
              唐山市房地产中介服务行业
            </div>
            <div>
              实名登记与诚信记录系统
            </div>
            <div className="introduce">
              行业机构 / 从业人员 / 失信客户
            </div>
          </div>
        </div>
        <div className="login-right">
          {slot}
        </div>
      </div>
      <div className="download" onClick={downloadExe}>
          <WindowsFilled  style={{marginRight: '10px'}}/>协会/住建管理端下载
      </div>
      <div className="foot">
        <span style={{color: '#ac1a3d'}}>云享赋能平台提供技术支持(yxfn.net)</span>
        <span style={{color: '#3d3c3d'}}> Copyright@2023唐山市房地产中介服务行业协会 版权所有 &nbsp;&nbsp;ICP备案编号：冀ICP备2023040514号</span>
      </div>
    </div>
  )
}
