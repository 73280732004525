import axios from '../../axios'
import request_url from '../../../assest/utils/request_url'
let baseUrl = request_url('practitionersRegistration')
export default {
  // 获取表格数据
  registrationCardProcessList() {
    return axios.get(`${baseUrl}basic/query/tableHeader/registrationCardProcessList`)
  },
  // 获取表格数据
  registrationCardProcess(page,size,data) {
    return axios.post(`${baseUrl}organization/registrationCardProcess/${page}/${size}`, data)
  },
  // 获取表格数据
  preview(id) {
    return axios.get(`${baseUrl}organization/registrationCardProcess/preview/${id}`)
  },
  // 获取表格数据
  mailingInfo(id) {
    return axios.get(`${baseUrl}organization/registrationCardProcess/mailingInfo/${id}`)
  }
}
