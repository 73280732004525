// 登录页面
import BaseLoginLayout from "../../component/public/baseLoginLayout";
import LoginIndex from '../../component/Login/LoginIndex'
import "../../styles/login/login.scss";
const Login = () => {
  return (
    <>
      <BaseLoginLayout
        slot={<LoginIndex changeComName={'login'}></LoginIndex>}
      ></BaseLoginLayout>
    </>
  );
};
export default Login
