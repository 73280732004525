// 企业备案复核页面
import { useState,useEffect,useRef } from "react";
import {Button, message as antdMessage} from 'antd'
import mitt from '../../assest/utils/mitt'
import EnterpriseRecord from "../../service/ManagePage/Enterprise/Enterprise";
import ModifyPersonnelRegister from '../../component/ManagePage/Enterprise/ModifyPersonnelRegister'
import '../../styles/ManagePage/Personnel/practitionersRegistration.scss'
import DetailPersonnelRegister from '../../component/ManagePage/Enterprise/DetailPersonnelRegister'
import EnterpriseRecordTable from '../../component/ManagePage/Enterprise/EnterpriseRecordTable'
import dayjs from "dayjs";
export default () => {
  const pageInfo = useRef({page:1,size:20});
  const tableRow = useRef({});
  const [tableLoading,setTableLoading] = useState(false)// 表格loading
  const [personList,setPersonList] = useState([]);// 列表
  const [personHead,setPersonHead] = useState([]);// 表头
  const [total,setTotal] = useState(0);// 总数

  const [showModifyCom,setShowModifyCom] = useState(false);//修改组件显隐
  const [showDetailCom,setShowDetailCom] = useState(false);// 详情组件显隐
  const [selectedRowKeys,setSelectedRowKeys] = useState([]) // 选中数据控制，用于清空
  useEffect(() => {
    getPersonList()
    getPersonHead()
    return (() => {
      mitt.off('onBack', onBack)
      mitt.off('pageDestroy', pagePersonDestroy)
    })
  }, [])
  const pagePersonDestroy = () => {
    setShowModifyCom(false)
    setShowDetailCom(false)
  }
  // 获取表头
  const getPersonHead = async () => {
    let res = await EnterpriseRecord.filingRecordsHeader();
    setPersonHead(res)
  }
  // 获取列表
  const getPersonList = async () => {
    try {
      setTableLoading(true)
      let id =JSON.parse(sessionStorage.getItem("info")).archivesId
      let data = await EnterpriseRecord.filingRecordsQuery(id,pageInfo.current.page,pageInfo.current.size);
      setPersonList([...data.list]);
      setTotal(data.count)
      setTableLoading(false)
    }catch(error) {
      setTableLoading(false)
    }
  }
  const onBack = (val) => {
    const organPageInfo = {
        modifyPersoPage:modifyPageBack,
        detailPersoPage:detailPageBack
    }
    organPageInfo[val]()
  }
  // 修改返回
  const modifyPageBack = () => {
    setShowModifyCom(false)
  }
  // 详情返回
  const detailPageBack = () => {
    setShowDetailCom(false)
  }
  // 修改 / 申请备案复核 、
  const onModify =  async (row) => {
    if (row.archivesBusinessId) {
      tableRow.current = row
      setShowModifyCom(true)
    }else {
      let archivesBusinessId = JSON.parse(sessionStorage.getItem('info')).archivesId
      tableRow.current = {archivesBusinessId,history: false};
      let res = await EnterpriseRecord.filingRecordsDetail({history:false, archivesBusinessId})
      // 到期前一个月
      let BeFoExpirationTime = dayjs(res.deadlineForFilingValidity).subtract('30',"day")
      // 当前时间
      let date = dayjs()
      // 判断备案状态是否为通过  有效期是否在前一个月
      if (res.record && date.isAfter(BeFoExpirationTime)) {
        setShowModifyCom(true)
      }else {
        antdMessage.error('请在到期前一个月内，进行下周期的登记/复核操作')
      }
    }
  }
  // 分页
  const onPageChange = (page,size) => {
    pageInfo.current = {page,size};
    getPersonList();
    setSelectedRowKeys([])
  }
  // 修改成功
  const modifySuccess = () => {
    setShowModifyCom(false)
    refreshList()
  }
  // 点击修改取消
  const onCancleModify = () => {
    setShowModifyCom(false)
  }
  // 点击详情
  const onDetail = (row) => {
    tableRow.current = row;
    setShowDetailCom(true)
  }
  // 详情取消
  const onCancleDetail = () => {
    setShowDetailCom(false)
  }
  // 刷新列表
  const refreshList = (isResetPage = true) => {
    if(isResetPage) {
      pageInfo.current = {page:1,size:pageInfo.current.size}
    }
    getPersonList();
    setSelectedRowKeys([])
  }
  return (
    <div id="personManagement">
      <div className="organ-util">
        <div className="util-left"></div>
        <div className="organ-right">
          <Button
            type="primary"
            onClick={onModify}>
            申请登记/复核
          </Button>
        </div>
      </div>
      <div className="organ-contain">
          <EnterpriseRecordTable
            total={total}
            onModify={onModify}
            onDetail={onDetail}
            loading={tableLoading}
            pageInfo={pageInfo.current}
            onPageChange={onPageChange}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            personList={personList}
            personHead={personHead}></EnterpriseRecordTable>
      </div>
      {/*修改/新增*/}
      {showModifyCom ? <ModifyPersonnelRegister tableRow={tableRow.current} modifySuccess={modifySuccess} onCancleModify={onCancleModify}></ModifyPersonnelRegister> : null}
      {/*详情*/}
      {showDetailCom ? <DetailPersonnelRegister tableRow={tableRow.current}  onCancleDetail={onCancleDetail}></DetailPersonnelRegister> : null}
    </div>
  );
};
