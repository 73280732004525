import detailsPublicity from '../../api/Details/details'
class PersonnelDetails {
  // 创建单例
  static getInstance() {
    if(!this.detailsRecord) {
      this.detailsRecord = new PersonnelDetails()
    }
    return this.detailsRecord
  }
  // 行为记录
  behaviorDetails(data) {
    return detailsPublicity.behaviorDetails(data)
  }
  // 人员信息
  peopleInfo(id) {
    return detailsPublicity.peopleInfo(id)
  }
  // 行为记录
  behaviorRecordsHeader() {
    return detailsPublicity.behaviorRecordsHeader()
  }
}
export default PersonnelDetails.getInstance()
