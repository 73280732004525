// 机构信息公示
import { useState,useEffect,useRef } from "react";
import mitt from '../../assest/utils/mitt'
import CorporatePublicity from "../../service/Publicity/Corporate/CorporatePublicity";
import '../../styles/Publicity/Corporat/corporatePublicity.scss'
import DetailCorporatePublicity from '../../component/Publicity/CorporatePublicity/DetailCorporatePublicity'
import CorporatePublicityTable from '../../component/Publicity/CorporatePublicity/CorporatePublicityTable'
import {Button, Input} from "antd";
export default (props) => {
  const {searchName, onSearch} = props
  const pageInfo = useRef({page:1,size:20});
  const tableRow = useRef({});
  const [tableLoading,setTableLoading] = useState(false)// 表格loading
  const [personList,setPersonList] = useState([]);// 列表
  const [personHead,setPersonHead] = useState([]);// 表头
  const [total,setTotal] = useState(0);// 总数
  const [showDetailCom,setShowDetailCom] = useState(false);// 详情组件显隐
  const [selectedRowKeys,setSelectedRowKeys] = useState([]) // 选中数据控制，用于清空
  useEffect(() => {
    getPersonList()
    getPersonHead()
    return (() => {
      mitt.off('onBack', onBack)
      mitt.off('pageDestroy', pagePersonDestroy)
    })
  }, [])
  useEffect(() => {
    getPersonList()
  }, [onSearch])
  const pagePersonDestroy = () => {
    setShowDetailCom(false)
  }
  // 获取表头
  const getPersonHead = async () => {
    let res = await CorporatePublicity.institutionalPublicityQueryHeader();
    setPersonHead(res)
  }
  // 获取列表
  const getPersonList = async () => {
    try {
      setTableLoading(true)
      let data = await CorporatePublicity.institutionalPublicityQuery({
        page:pageInfo.current.page,size:pageInfo.current.size,archivesName: searchName
      });
      setPersonList([...data.list]);
      setTotal(data.totalElements)
      setTableLoading(false)
    }catch(error) {
      setTableLoading(false)
    }
  }
  const onBack = (val) => {
    const organPageInfo = {
      modifyPersoPage:modifyPageBack,
      detailPersoPage:detailPageBack
    }
    organPageInfo[val]()
  }
  // 修改返回
  const modifyPageBack = () => {
  }
  // 详情返回
  const detailPageBack = () => {
    setShowDetailCom(false)
  }
  // 修改
  const onModify = (row) => {

  }
  // 分页
  const onPageChange = (page,size) => {
    pageInfo.current = {page,size};
    getPersonList();
    setSelectedRowKeys([])
  }
  // 修改成功
  const modifySuccess = () => {
    refreshList()
  }
  // 点击详情
  const onDetail = (row) => {
    tableRow.current = row;
    setShowDetailCom(true)
  }
  // 详情取消
  const onCancleDetail = () => {
    setShowDetailCom(false)
  }
  // 刷新列表
  const refreshList = (isResetPage = true) => {
    if(isResetPage) {
      pageInfo.current = {page:1,size:pageInfo.current.size}
    }
    getPersonList();
    setSelectedRowKeys([])
  }
  return (
    <div id="CorporatePublicity">
      <div className="organ-contain">
        <CorporatePublicityTable
          total={total}
          onModify={onModify}
          onDetail={onDetail}
          loading={tableLoading}
          pageInfo={pageInfo.current}
          onPageChange={onPageChange}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          personList={personList}
          personHead={personHead}></CorporatePublicityTable>
      </div>
      {/*详情*/}
      {showDetailCom ? <DetailCorporatePublicity tableRow={tableRow.current}  onCancleDetail={onCancleDetail}></DetailCorporatePublicity> : null}
    </div>
  );
};
