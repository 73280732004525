/*
 * @Author: 吴雪川
 * @information:
 * @Date: 2020-12-04 11:17:00
 */
const OSS = require('ali-oss')
const co = require('co')
// 生成随即32uuid的包
let uuid = require('uuid');
let OSSConfig
if (process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'pred') {
  OSSConfig = {
    uploadHost: 'https://qjdev-pred-images.oss-cn-beijing.aliyuncs.com', // OSS上传地址
    type: 'scs',
    ossParams: {
      path: 'merchant/',
      region: 'oss-cn-beijing',
      success_action_status: '200', // 默认200
      accessKeyId: 'LTAI4FsNBxqEYS7jqxtqs2dh',
      accessKeySecret: '2gQMdlBOg2uPJukkn1tuxVyqs4IuCk',
      bucket: 'qjdev-pred-images'
    }
  }
} else if(process.env.REACT_APP_ENV=== 'prod'){
  OSSConfig = {
    uploadHost: 'https://qjprod-images.oss-cn-beijing.aliyuncs.com', // OSS上传地址
    type: 'scs',
    ossParams: {
      path: 'tsfzx_institution/',
      region: 'oss-cn-beijing',
      success_action_status: '200', // 默认200
      accessKeyId: 'LTAIuBMF9MOPd8um',
      accessKeySecret: 'xfMahp9yACASmfcHrRGyLDvL3HX8T8',
      bucket: 'qjprod-images'
    }
  }
}

function upload (event) {
  return new Promise((resolve, reject) => {
    let client = new OSS({
      region: OSSConfig.ossParams.region,
      accessKeyId: OSSConfig.ossParams.accessKeyId,
      accessKeySecret: OSSConfig.ossParams.accessKeySecret,
      bucket: OSSConfig.ossParams.bucket
    })
    let file = event
    let randomName = `${OSSConfig.ossParams.path}${uuid.v1()}`
    co(function* () {
      let result = yield client.multipartUpload(randomName, file)
      resolve({url:`${OSSConfig.uploadHost}/${result.name}`,name: result.name})
    }).catch(function (err) {
      reject(err)
    })
  })
}

export default upload
