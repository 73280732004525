import detailsPublicity from '../../api/Details/details'
class InstitutionalDetails {
  // 创建单例
  static getInstance() {
    if(!this.detailsRecord) {
      this.detailsRecord = new InstitutionalDetails()
    }
    return this.detailsRecord
  }
  // 获取详情
  institutionDetail(data) {
    return detailsPublicity.institutionDetail(data)
  }
  // 行为记录查询
  behaviorRecords(page,size,data) {
    return detailsPublicity.behaviorRecords(page,size,data)
  }
  // 行为记录，表头
  behaviorRecordsHeader() {
    return detailsPublicity.behaviorRecordsHeader()
  }
}
export default InstitutionalDetails.getInstance()
