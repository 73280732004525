import {useState, useEffect} from "react";
import {Table, Space, Button, Image, Rate} from 'antd';
import '../../../styles/ManagePage/Personnel/practitionersRegistrationTable.scss'
import member_img from "../../../assest/image/member.png";
import unmember_img from "../../../assest/image/unmember.png";
export default (prop) => {
  const {
    pageInfo,
    setSelectRow,
    setSelectedRowKeys,
    personList,
    personHead,
    total,
    onPageChange,
    onModify,
    loading,
    onDetail,
    onAppraise
  } = prop;
  const [head, setHead] = useState([])
  useEffect(() => {
    handleHead()
  }, [personHead, personList])
  const columns = [
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      width: 80,
      className: 'person-action',
      render: (text, row) => {
        return (
          <>
            <div style={{display: "flex",flexDirection: "column"}}>
              <Button type="link" onClick={() => onDetail(row)}>查看</Button>
              {/*<span style={{color: '#d9d9d9'}}>|</span>*/}
              <Button type="link" onClick={() => onAppraise(row)}>评价</Button>
              {/*<span style={{color: '#d9d9d9'}}>|</span>*/}
              <Button type="link" onClick={() => onModify(row)}>投诉</Button>
            </div>
          </>
        )
      },
    },
  ];
  const handleHead = () => {
    let arr = JSON.parse(JSON.stringify(personHead))
    arr.map((el, ind) => {
      if (el.headerFirst === '人员信息') {
        el.title = el.headerFirst
        el.dataIndex = el.data
        el.width = 400
        el.render = (text,record) => (
            <div style={{display: "flex", position: 'relative'}}>
              <Image style={{width: '102px', height: '100%', padding: '10px',pointerEvents: "none"}} preview={false} src={record.head} alt=""/>
              <div style={{paddingTop: '10px'}}>
                <div>{record.name}  {record.sex ? `（${record.sex}）`: null} </div>
                <div style={{marginTop: 5}}>信息卡号：{record.registrationCode}</div>
                <div style={{marginTop: 5}}>人员类型：{record.postType}</div>
                <div style={{marginTop: 5}}>从业机构：{record.dept}</div>
              </div>
              <img style={{position: 'absolute', left: '48%', top: "9px", width: '155px',pointerEvents: "none"}} src={record.iconAddress} alt=""/>
              {record.memberUnitType === "非会员" ? <img style={{position: 'absolute', right: '0', width: '75px',pointerEvents: "none"}} src={unmember_img} alt=""/> : <img style={{position: 'absolute', right: '0', width: '75px',pointerEvents: "none"}} src={member_img} alt=""/>}
            </div>
        );
      } else if (el.headerFirst === '操作') {
        el.dataIndex = undefined
      }else if(el.headerFirst === '诚信星级'){
        el.title = el.headerFirst
        el.dataIndex = el.data
        el.width = 150
        el.render = (text,record) => (
          <Rate disabled defaultValue={record.creditRating || 3} />
        );
      }else {
        el.title = el.headerFirst
        el.dataIndex = el.data
      }
      delete el.headerFirst
      delete el.data
    })
    arr = [...arr.filter(item => item.dataIndex !== undefined), ...columns]
    setHead(arr)
  }
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectRow(selectedRows);
      setSelectedRowKeys(selectedRowKeys)
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  };
  return (
    <div className="person-table">
      <Table
        scroll={{x: '100%',y: '100%'}}
        columns={[...head]}
        loading={loading}
        bordered
        size={'small'}
        dataSource={personList}
        pagination={
          {
            showTotal: (total, range) => `共 ${total} 条数据`,
            showSizeChanger: true,
            defaultPageSize: 20,
            total: total,
            onChange: onPageChange,
            current: pageInfo.page
          }
        }/>
    </div>
  )
}
