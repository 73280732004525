import {useState} from 'react';
import {PASSWORD} from '../../assest/utils/rules_verfity'
import {useHistory} from 'react-router-dom'
import loginInstan from '../../service/login/login'
import '../../styles/login/setPassword.scss'
import {Form, Button, Input, message} from 'antd'

export default (prop) => {   // 设置新密码
  let history = useHistory();
  const {onFinishPass} = prop;
  const [confimLoading, setConfimLoading] = useState(false)
  const onFinish = async (values) => {
    try {
      setConfimLoading(true)
      let res = await loginInstan.setPassword(values.password)
      setConfimLoading(false)
      message.success('设置成功！')
      sessionStorage.setItem('updatePassword', 'false')
      onFinishPass()
    } catch (error) {
      setConfimLoading(false)
    }
  }
  return (
    <div id="setPassword">
      <div className="title1">设置新密码</div>
      <div className="title2">（限制字数为6-20个字符，由数字+字母组成）</div>
      <Form
        name="basic"
        onFinish={onFinish}
        autoComplete="off">
        <Form.Item
          label=""
          validateFirst={true}
          name="password"
          rules={[{required: true, message: "请输入新密码"},
            ({getFieldValue}) => ({
              validator(_, value) {
                if (!PASSWORD.test(value)) {
                  return Promise.reject(new Error("限制字数为6-20个字符，由数字+字母组成"));
                }
                return Promise.resolve();
              },
            }),]}>
          <Input.Password style={{width: '300px', height: '40px'}} placeholder="请输入密码"/>
        </Form.Item>
        <Form.Item
          label=""
          validateFirst={true}
          name="confirmPassword"
          rules={[
            {required: true, message: "请再次输入密码"},
            ({getFieldValue}) => ({
              validator(_, value) {
                if (value !== getFieldValue('password')) {
                  return Promise.reject(new Error('输入错误'));
                }
                return Promise.resolve();
              },
            })
          ]}
        >
          <Input.Password style={{width: '300px', height: '40px'}} placeholder="再次输入"/>
        </Form.Item>
        <Form.Item>
          <Button style={{width: '300px', height: '40px'}} type="primary" htmlType="submit" loading={confimLoading}>
            确定
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
