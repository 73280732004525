import {Table, Tabs} from 'antd';
import React, {useEffect, useRef, useState} from "react";
import InstitutionalDetails from "../../service/Details/InstitutionalDetails";
import '../../styles/ManagePage/Card/Card.scss'
import mitt from "../../assest/utils/mitt";

export default (props) => {
  const { subjectId, subjectType, id } = props
  const [personList, setPersonList] = useState([]);// 列表
  const [personHead, setPersonHead] = useState([]);// 表头
  const [tableLoading, setTableLoading] = useState(false)// 表格loading
  const [total, setTotal] = useState(20);// 总数
  let tabIndex = 1;// 总数
  const pageInfo = useRef({page: 1, size: 20});
  useEffect(() => {
    getList();
    getHeader();
  }, [])
  let map = new Map([
    [1, {header: 'blackListBehaviorList', list: 110}],
    [2, {header: 'badBehaviorList', list: 111}],
    [3, {header: 'goodBehaviorList', list: 112}]
  ])
  const items = [
    {
      key: 1,
      label: '黑名单',
    },
    {
      key: 2,
      label: '不良行为',
    },
    {
      key: 3,
      label: '良好行为',
    },
  ];
  // tab 切换
  const onChange = async (key) => {
    // settabIndex(key)
    tabIndex = key
    getHeader();
    getList();
  };
  const getHeader = async () => {
    let res = await InstitutionalDetails.behaviorRecordsHeader();
    res.map((el, ind) => {
      {
        el.key = el.property
        el.dataIndex = el.property
        el.title = el.label
      }
    })
    setPersonHead(res)
  }
  const getList = async () => {
    let type = map.get(tabIndex).list
    let res = await InstitutionalDetails.behaviorRecords(pageInfo.current.page,pageInfo.current.size,{
          behaviorTypeId: type, // 行为类型 行为类型(黑名单:110,不良行为:111,良好行为:112)
          institutionId: id, // 人或机构ID
        });
    setPersonList(res.list)
    setTotal(res.count);
  }
  // 分页
  const onPageChange = (page, size) => {
    pageInfo.current = {page, size};
    getList();
  }
  return (
      <div id="institutionalTable">
        <Tabs defaultActiveKey={tabIndex} items={items} onChange={onChange}/>
        <Table columns={personHead}
               bordered
               scroll={{x: '100%', y: '550px'}}
               loading={tableLoading}
               pagination={
                 {
                   showSizeChanger: true,
                   defaultPageSize: 20,
                   total: total,
                   onChange: onPageChange,
                   current: pageInfo.current.page
                 }
               }
               dataSource={personList}/>
      </div>
  )
}
