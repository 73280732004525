export const changePermission = (val) => { // 更改权限
  return({
      type:'permission',
      value:val
  }) 
}

export const changePlat = (val) => {
  return {
    type:'platform',
    value:val
  }
}