// 从业人员实名登记
import {useEffect, useRef, useState} from "react";
import {Button, DatePicker, Form, Input, message, Modal, Select, Steps} from 'antd'
import mitt from '../../assest/utils/mitt'
import PractitionersRegistrationTable from '../../component/ManagePage/Personnel/PractitionersRegistrationTable'
import AddOrgan from '../../component/ManagePage/Personnel/AddOrgan'
import ModifyOragn from '../../component/ManagePage/Personnel/ModifyOragn'
import DetailOrgan from '../../component/ManagePage/Personnel/DetailOrgan'
import EnterpriseRecord from '../../service/ManagePage/Enterprise/Enterprise'
import '../../styles/ManagePage/Enterprise/enterpriseRecord.scss'
import {PlusSquareOutlined, CloseOutlined} from '@ant-design/icons';
import practitionersRegistration from "../../service/ManagePage/Personnel/Personnel";
import DownloadCard from "../../component/ManagePage/Personnel/DownloadCard";
import TextArea from "antd/es/input/TextArea";
import dayjs from 'dayjs';
import * as rules_vertify from "../../assest/utils/rules_verfity";
import logo from '../../assest/image/logo.png'
import wx from '../../assest/image/wx.png'
import yl from '../../assest/image/yl.png'
import zfb from '../../assest/image/zfb.png'
import QRCode from 'qrcode.react'

const {Option} = Select;

export default () => {
  const pageInfo = useRef({page: 1, size: 20});
  const tableRow = useRef({});
  const [newForm] = Form.useForm();
  const [tableLoading, setTableLoading] = useState(false)// 表格loading
  const [id, setid] = useState(false)// 表格行id
  const [showAddCom, setShowAddCom] = useState(false);//新增组件显隐
  const [showModifyCom, setShowModifyCom] = useState(false);//修改组件显隐
  const [showDetailCom, setShowDetailCom] = useState(false);// 详情组件显隐
  const [entryQuit, setEntryQuit] = useState(false);// 解除从业弹窗
  const [cardMaking, setCardMaking] = useState(false);// 申请制卡弹窗
  const [cardMakingForm] = Form.useForm();
  const [StepCurrent, setStepCurrent] = useState(0);
  const [receiveWayDict, setReceiveWayDict] = useState([]);
  const [locationDict, setLocationDict] = useState([]);
  const [isReceiveWay, setIsReceiveWay] = useState(false); // 是否为邮寄

  const [agencyList, setAgencyList] = useState([]);// 列表
  const [agencyHead, setAgencyHead] = useState([]);// 表头
  const [total, setTotal] = useState(0);// 总数
  const [selectedRowKeys, setSelectedRowKeys] = useState([]) // 选中数据控制，用于清空
  const [CardMakingData, setCardMakingData] = useState({}) // 选中数据控制，用于清空
  // 倒计时存储
  const [countDown, setCountDown] = useState('5:00');
  const [time, setTime] = useState();
  const [codeUrl, setCodeUrl] = useState({qrUrl: "", qrInfoSaveVO: {id: ""}, tradingFlowId: ""});

  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    getAgencyList();
    getAgencyHead();
    getDict();
  }, []);
  useEffect(() => {
    return (() => {
      mitt.off('onBack', onBack);
    })
  }, []);
  useEffect(() => {
    return (() => {
      mitt.off('pageDestroy', pageOrganDestroy);
    })
  }, []);
  // 页面销毁
  const pageOrganDestroy = () => {
    setShowModifyCom(false);
    setShowDetailCom(false);
  }
  // 获取表头
  const getAgencyHead = async () => {
    let res = await practitionersRegistration.personHeader();
    setAgencyHead(res);
  }
  // 获取列表
  const getAgencyList = async () => {
    try {
      setTableLoading(true)
      let inDeptId = JSON.parse(sessionStorage.getItem("info")).deptId
      let data = await practitionersRegistration.peoplesList(pageInfo.current.page, pageInfo.current.size, {
        enterpriseSide: true,
        inDeptId
      });
      setAgencyList(data.list);
      setTotal(data.totalCount);
      setTableLoading(false);
    } catch (error) {
      setTableLoading(false);
      console.log('error', error);
    }
  }
  // 返回
  const onBack = (val) => {
    const organPageInfo = {
      addPage: addPageBack,
      modifyPage: modifyPageBack,
      detailPage: detailPageBack
    };
    organPageInfo[val]();
  }
  // 新增返回
  const addPageBack = () => {
    setShowAddCom(false);
  }
  // 修改返回
  const modifyPageBack = () => {
    setShowModifyCom(false);
  }
  // 详情返回
  const detailPageBack = () => {
    setShowDetailCom(false);
  }
  // 新增
  const onAddOrgan = async () => {
    let res = await EnterpriseRecord.institutionFilingStatus({archivesId: JSON.parse(sessionStorage.getItem("info")).archivesId});
    if (!res) return message.warning('您的企业信息尚未审核通过，请稍后再试!');
    setShowAddCom(true);
  }
  // 取消新增
  const onCancleAdd = () => {
    setShowAddCom(false);
  }
  // 新增成功
  const addSuccess = () => {
    setShowAddCom(false);
    pageInfo.current = {page: 1, size: pageInfo.current.size};
    getAgencyList();
  }
  // 详情取消
  const onCancleDetail = () => {
    setShowDetailCom(false);
  }
  // 页面跳转
  const onPageChange = (page, size) => {
    pageInfo.current = {page, size};
    getAgencyList();
    setSelectedRowKeys([]);
  }
  // 点击修改
  const onModify = (row) => {
    tableRow.current = row;
    setShowModifyCom(true);
  }
  // 解除从业
  const onEntryQuit = async (row) => {
    let {basicId} = row;
    setid(basicId)
    setEntryQuit(true);
  }
  // 解除从业 表单确认
  const handleOk = async (value) => {
    value.id = id
    // 格式化日期为字符串
    value.runTime = dayjs(value.runTime).format('YYYY-MM-DD');
    await practitionersRegistration.resign(value);
    newForm.resetFields();
    setEntryQuit(false);
    getAgencyList();
  }
  // 解除从业 关闭表单清空值
  const handleCancel = (value) => {
    newForm.resetFields();
    setEntryQuit(false);
  }
  // 点击详情
  const onDetail = (row) => {
    tableRow.current = row;
    setShowDetailCom(true)
  }
  // 修改成功
  const modifySuccess = () => {
    setShowModifyCom(false)
    pageInfo.current = {page: 1, size: pageInfo.current.size};
    getAgencyList();
  }
  // 点击修改取消
  const onCancleModify = () => {
    setShowModifyCom(false)
  }
  const disabledDate = (current) => {
    return current && current > dayjs().endOf('day');
  }

  // 获取字典
  const getDict = async () => {
    let res = await practitionersRegistration.receiveWay();
    setReceiveWayDict(res)
    res = await practitionersRegistration.location();
    setLocationDict(res)
  }

  const items = [
    {
      description: '预览并核对登记卡',
    },
    {
      description: '申请制卡',
    },
    {
      description: '确认付款',
    },
  ];
  // 申请制卡
  const onCardMaking = async (row) => {
    if (row.registrationStatus === '已过期' || row.registrationStatus === '已驳回') return message.error('当前人员处于已过期或已驳回状态，不支持该操作')
    let res = await practitionersRegistration.allowCardMaking({basicId: row.basicId,inDeptId: row.inDeptId})
    if(!res) return message.error('已申请制卡，请前往【登记卡进度】进行查看、付款等')
    setCardMakingData(row)
    setCardMaking(true)
  }
  // 关闭付款弹窗
  const onCancleCardMaking = async () => {
    if (StepCurrent === 2) {
      setIsModalOpen(true)
    }else {
      isModalOpenOk()
    }
  }
  // 领取方式-下一步按钮
  const cardMakingHandleOk = async (value) => {
    value.basicId = CardMakingData.basicId
    value.inDeptId = CardMakingData.inDeptId
    await practitionersRegistration.external(value);
    let url = await practitionersRegistration.generateQRCode({basicId: value.basicId, inDeptId: value.inDeptId,customerName: CardMakingData.name})
    // setCodeUrl({
    //   qrUrl: "https://ibsbjstar.ccb.com.cn/CCBIS/QR?QRCODE=CCB9980001709387396912649&1721099786233",
    //   qrInfoSaveVO: {
    //     id: "c963a59b-4321-11ef-80ee-0242ac120002"
    //   },
    //   amountPayable: '20',
    //   tradingFlowId: "c963a59b-4321-11ef-80ee-0242ac120002"
    // })
    setCodeUrl(url)
    // 倒计时五分钟
    clearInterval(time)
    let timeNuM = 5 * 60
    setTime(
        setInterval(() => {
          const minutes = Math.floor(timeNuM / 60)
          const seconds = timeNuM % 60
          setCountDown(`${parseInt(minutes)}:${parseInt(seconds)}`)
          timeNuM--
          if (minutes === 0 && seconds === 0) {
            clearInterval(time)
            onCancleCardMaking()
          }
        }, 1000)
    )
    setStepCurrent(2)
  }

  const receiveWayChange = (value) => {
    if (value === 150) {
      setIsReceiveWay(true)
    } else {
      setIsReceiveWay(false)
    }
    cardMakingForm.setFieldsValue({
      recipients: null,
      recipientsPhone: null,
      location: null,
      detailedAddress: null,
    });
  }
  const ConfirmCancel = () => {
    setIsModalOpen(false)
    isModalOpenOk()
  }

  const isModalOpenCancel = () => {
    setIsModalOpen(false)
  }

  const isModalOpenOk = () => {
    setIsModalOpen(false);
    clearInterval(time)
    setCountDown('5:0')
    setStepCurrent(0)
    setCardMaking(false)
    cardMakingForm.setFieldsValue({
      receiveWay: 151,
      recipients: null,
      recipientsPhone: null,
      location: null,
      detailedAddress: null,
    });
    receiveWayChange(151)
  }
  // 申请制卡步骤组件
  const onStepChange = (current) => {
    if (current === 0) {
      return <div className="current_0">
        <DownloadCard id={CardMakingData.basicId} inDeptId={CardMakingData.inDeptId} watermark={true}></DownloadCard>
        <div style={{width: '100%', display: "flex", justifyContent: "center", alignItems: "center", marginTop: "0px"}}>
          {/* 下一步按钮 */}
          <Button type="primary" onClick={() => setStepCurrent(1)}>下一步</Button>
          {/* 取消按钮 关闭弹窗 清空步骤组件 */}
          <Button style={{marginLeft: '20px'}} onClick={onCancleCardMaking}>取消</Button>
        </div>
      </div>
    } else if (current === 1) {
      return <div style={{marginTop: '20px'}}>
        <Form
            form={cardMakingForm}
            name="cardMakingForm"
            labelCol={{span: 5}}
            wrapperCol={{span: 18}}
            initialValues={{remember: true}}
            autoComplete="off"
            onFinish={cardMakingHandleOk}
            onFinishFailed={handleCancel}
        >
          <Form.Item
              label="领取方式"
              name="receiveWay"
              initialValue={151}
              rules={[{required: true, message: '请选择'}]}
          >
            <Select placeholder="请选择" onChange={receiveWayChange}>
              {
                receiveWayDict.map(item => (
                    <Option key={item.itemCode} value={item.itemCode}>{item.itemValue}</Option>))
              }
            </Select>
          </Form.Item>
          {
            isReceiveWay ?
                <div>
                  <Form.Item
                      label="收件人"
                      name="recipients"
                      rules={[{required: true, message: '请选择'},
                        ({getFieldValue}) => ({
                          validator(_, value) {
                            if (!rules_vertify.NAME_REX.test(value)) {
                              return Promise.reject(new Error('请输入正确姓名'));
                            }
                            return Promise.resolve();
                          },
                        })
                      ]}
                  >
                    <Input
                        placeholder="请输入"
                    />
                  </Form.Item>
                  <Form.Item
                      label="收件人手机号"
                      name="recipientsPhone"
                      rules={[{required: true, message: '请选择'},
                        ({getFieldValue}) => ({
                          validator(_, value) {
                            if (!rules_vertify.PHONE_NUM.test(value)) {
                              return Promise.reject(new Error('手机号格式错误'));
                            }
                            return Promise.resolve();
                          },
                        })
                      ]}
                  >
                    <Input
                        placeholder="请输入"
                    />
                  </Form.Item>
                  <Form.Item
                      label="所在地区"
                      name="location"
                      rules={[{required: true, message: '请选择'}]}
                  >
                    <Select placeholder="请选择">
                      {
                        locationDict.map(item => (<Option key={item.id} value={item.id}>{item.name}</Option>))
                      }
                    </Select>
                  </Form.Item>
                  <Form.Item
                      label="详细地址"
                      name="detailedAddress"
                      rules={[{required: true, message: '请选择'}]}
                  >
                    <Input.TextArea
                        placeholder="请输入"
                    />
                  </Form.Item>
                  <div style={{
                    width: '100%',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "0px"
                  }}>
                    *邮寄将需到付，邮寄费用自行承担
                  </div>
                </div>
                : <div className="isReceiveWay">
                  <span>*登记卡进度=【待领取/邮寄】时，您可前往以下地址进行领取</span>
                  <span>领取地址：唐山市路北区友谊北路铭洋国际3层，唐山市房中协秘书处</span>
                  <span>领取时间：周一至周日</span>
                  <span style={{textAlign: "center"}}>上午：8:30-11:30 下午：2:00-6:00</span>
                </div>
          }
          <Form.Item wrapperCol={{span: 24}}>
            <div style={{
              width: '100%',
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px"
            }}>
              {/* 下一步按钮 */}
              <Button type="primary" htmlType="submit">下一步</Button>
              {/* 取消按钮 关闭弹窗 清空步骤组件 */}
              <Button style={{marginLeft: '20px'}} onClick={onCancleCardMaking}>取消</Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    } else if (current === 2) {
      return <div>
        <span className="title_card">登记卡工本费 &nbsp; {codeUrl.amountPayable} &nbsp; 元</span>
        <h4 className="title_card_code">￥{codeUrl.amountPayable}</h4>
        <div style={{
          display: "flex",
          justifyContent: "center",
        }}>
          {/* 付款二维码*/}
          <QRCode value={codeUrl.qrUrl} fgColor='#1fb19e'
                  size={150} level={'H'} className="border_code"
                  imageSettings={{src: logo, width: 30, excavate: true, height: 30}}
          ></QRCode>
        </div>
        <span className="payment">{countDown}s后二维码过期</span>
        <div style={{width: '100%', textAlign: "center", marginTop: '20px', marginBottom: '10px'}}>
          <div className="pay_logo">
            <img src={zfb} alt=""/>
            <img src={wx} alt=""/>
            <img src={yl} alt=""/>
          </div>
          <span style={{color: '#5c5c5c'}}>支持支付宝，微信，银联扫码付款</span>
        </div>
        <div style={{width: '100%', display: "flex", justifyContent: "center"}}>
          {/*<Button type="primary" onClick={isModalOpenOk}>确认</Button>*/}
          <Button type="primary" onClick={onCancleCardMaking}>关闭</Button>
        </div>
      </div>
    }
  }
  return (
      <div id="organManagement">
        <div className="organ-util">
          <div className="util-left"></div>
          <div className="organ-right">
            <Button type="primary" onClick={onAddOrgan} icon={<PlusSquareOutlined/>}>添加从业人员</Button>
          </div>
        </div>
        <div className="organ-contain">
          <PractitionersRegistrationTable
              total={total}
              setSelectedRowKeys={setSelectedRowKeys}
              loading={tableLoading}
              onModify={onModify}
              onEntryQuit={onEntryQuit}
              onDetail={onDetail}
              onCardMaking={onCardMaking}
              pageInfo={pageInfo.current}
              onPageChange={onPageChange}
              selectedRowKeys={selectedRowKeys}
              agencyList={agencyList}
              agencyHead={agencyHead}></PractitionersRegistrationTable>
        </div>
        {showAddCom ? <AddOrgan addSuccess={addSuccess} onCancleAdd={onCancleAdd}></AddOrgan> : null}
        {showModifyCom ?
            <ModifyOragn tableRow={tableRow.current} modifySuccess={modifySuccess} onCancleModify={onCancleModify}>
            </ModifyOragn> : null}
        {showDetailCom ? <DetailOrgan tableRow={tableRow.current} onCancleDetail={onCancleDetail} jumpModify={onModify}>
        </DetailOrgan> : null}
        <Modal
            title="解除从业"
            open={entryQuit}
            onCancel={handleCancel}
            footer={null}
        >
          <Form
              name="basic"
              form={newForm}
              style={{paddingTop: '20px'}}
              onFinish={handleOk}
              autoComplete="off"
          >
            <Form.Item
                label="离职时间"
                name="runTime"
                rules={[{required: true, message: '请选择离职时间'}]}
            >
              <DatePicker disabledDate={disabledDate} style={{width: '100%'}} format={'YYYY-MM-DD'}/>
            </Form.Item>

            <Form.Item
                label="离职原因"
                name="reason"
                rules={[{required: true, message: '请输入离职原因'}]}
            >
              <TextArea rows={4} placeholder="请输入离职原因"/>
            </Form.Item>

            <div style={{width: '100%', display: "flex", justifyContent: "center"}}>
              <Button type="primary" htmlType="submit">
                确认
              </Button>
              <Button onClick={handleCancel} style={{marginLeft: '30px'}}>
                取消
              </Button>
            </div>
          </Form>
        </Modal>
        {/* 申请制卡弹窗 */}
        <Modal
            title="申请登记卡"
            width={650}
            className='cardMaking'
            open={cardMaking}
            closable={true}
            closeIcon={<CloseOutlined/>}
            keyboard={false}
            maskClosable={false}
            onCancel={onCancleCardMaking}
            footer={null}
        >
          <Steps current={StepCurrent} style={{marginTop: '30px'}} labelPlacement="vertical" items={items}/>
          {onStepChange(StepCurrent)}
        </Modal>
        <Modal title="是否支付" open={isModalOpen}
               keyboard={false}
               maskClosable={false}
               closable={false}
               closeIcon={null}
               footer={null}
        >
          如果您尚未支付，关闭后15-20分钟内不能再次支付~
          <div style={{width: '100%', display: "flex", justifyContent: "center",marginTop: '30px'}}>
            <Button type="primary" onClick={isModalOpenCancel} >
              继续支付
            </Button>
            <Button onClick={ConfirmCancel} style={{marginLeft: '30px'}}>
              确认取消/已完成支付
            </Button>
          </div>
        </Modal>
      </div>
  )
}
