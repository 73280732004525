// 人员详情
import React, {useState, useEffect, useRef} from "react";
import '../../styles/Details/PersonnelDetails.scss';
import {Col, Image, Modal, Rate, Row, Spin} from "antd";
import personnel from '../../assest/image/personnel.svg';
import mechanism from '../../assest/image/mechanism.svg';
import qualification from '../../assest/image/qualification.svg';
import mechanismInfo from '../../assest/image/mechanismInfo.svg';
import Details from "../../service/Details/PersonnelDetails";
import PersonnelTabTable from "../../component/Details/PersonnelTabTable";
import member_img from "../../assest/image/member.png";
import normal from "../../assest/image/normal.png";
import abnormal from "../../assest/image/abnormal.png";

export default (props) => {
  const {id} = props
  // 人员信息数据
  const [personnelInfo, setPersonnelInfo] = useState({});
  // 人员信息加载动画
  const [personnelLoading, setPersonnelLoading] = useState(false);
  const [recordCertificateOpen, setRecordCertificateOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    getPersonnelDetail()
  }, [])

  // 获取人员详情
  const getPersonnelDetail = async () => {
    setPersonnelLoading(false)
    let res = await Details.peopleInfo({basicId: id})
    setPersonnelInfo(res)
    setPersonnelLoading(true)
  }

  const handleOk = () => {
    setRecordCertificateOpen(false);
    setIsModalOpen(false);
  }
  const handleCancel = () => {
    setRecordCertificateOpen(false);
    setIsModalOpen(false);
  }

  return (
      <div id="PersonnelDetails">
        {
          personnelLoading ? <div>
            <header>从业人员个人档案</header>
            <main>
              <div className='cardDetails'>
                <h4 className="cardDetails_title">人员信息</h4>
                <div className="cardDetails_main">
                  <div className="cardDetails_main_card" style={{flexDirection: 'initial'}}>
                    <img
                        src={personnelInfo.photoUrl}
                        alt=""/>
                    <div style={{
                      marginLeft: '20px',
                      display: "flex",
                      justifyContent: 'space-around',
                      flexDirection: "column"
                    }}>
                      <h3 style={{margin: '0'}}>
                        {personnelInfo.name}
                        <div>
                          {
                            personnelInfo.processedState === '正常' ?
                              <span className="abnormal">
                                <img src={normal} alt=""/>
                                正常
                              </span> :
                              <span className="normal">
                                <img src={abnormal} alt=""/>
                                异常
                              </span>
                          }
                        </div>
                      </h3>
                      <span style={{display: "flex", alignItems: "center"}}>诚信星级<Rate disabled allowHalf
                                                                                          defaultValue={personnelInfo.star}
                                                                                          style={{marginLeft: '10px'}}/></span>
                    </div>
                  </div>
                  <div className="cardDetails_main_card">
                    <span>从业类型<span className="cardDetails_main_info">{personnelInfo.postType}</span></span>
                    <span>资格证编号<span
                        className="cardDetails_main_info">{personnelInfo.qualificationsNumber}</span></span>
                  </div>
                  <div className="cardDetails_main_card">
                    <span>信息卡号<span className="cardDetails_main_info">{personnelInfo.registrationNumber}</span></span>
                    <span>从业机构<span className="cardDetails_main_info">{personnelInfo.archivesName}</span></span>
                  </div>
                </div>
                {personnelInfo.memberName !== "非会员" ? <img className="member" src={member_img} alt=""/> : null}
              </div>
              <div className='cardDetails'>
                <h4 className="cardDetails_title">证件照片</h4>
                <div className="cardDetails_image">
                  <div className="cardDetails_image_left">
                    <div className="cardDetails_image_left_box"  style={ personnelInfo.cardUrl === "" ? {background: 'rgb(215, 215, 215)'} : {background: 'rgb(31, 177, 158)'}}>
                      <Row justify="start" className="row" >
                        <Col span={2}>
                          <img src={personnel} alt=""/>
                        </Col>
                        <Col span={7} style={{justifyContent: "left"}}>
                          <h3 style={{marginLeft: '10px'}}>人员实名登记卡</h3>
                        </Col>
                        <Col span={11}>
                          {personnelInfo.cardUrl === "" ? '暂无' : `有效期： ${personnelInfo.entryTime}`}
                        </Col>
                        <Col span={4}>
                          {
                            personnelInfo.cardUrl === "" ? '暂无' :
                                <span className="btn_check" onClick={() => setIsModalOpen(true)}>点击查看</span>
                          }
                        </Col>
                      </Row>
                    </div>
                    <div className="cardDetails_image_left_box" style={personnelInfo.postType === "业务员" ? {background: 'rgb(215, 215, 215)'} :  {background: 'rgb(31, 177, 158)'}}>
                      <Row justify="start" className="row">
                        <Col span={2}>
                          <img src={mechanism} alt=""/>
                        </Col>
                        <Col span={7} style={{justifyContent: "left"}}>
                          <h3 style={{marginLeft: '10px'}}>资格认证</h3>
                        </Col>
                        <Col span={11}>
                          {
                              personnelInfo.postType === "业务员" ? '暂无' : '暂无'
                          }
                        </Col>
                        <Col span={4}>
                          {
                            personnelInfo.postType === "业务员" ? '暂无' : '已获取证书'
                          }
                        </Col>
                      </Row>
                    </div>
                    <div className="cardDetails_image_left_box" style={{background: 'rgb(2, 167, 240)'}}>
                      <Row justify="start" className="row">
                        <Col span={2}>
                          <img src={qualification} alt=""/>
                        </Col>
                        <Col span={7} style={{justifyContent: "left"}}>
                          <h3 style={{marginLeft: '10px'}}>机构登记备案</h3>
                        </Col>
                        <Col span={11}>
                          有效期： {personnelInfo.deadlineForFilingValidity}
                        </Col>
                        <Col span={4}>
                          <span className="btn_check" onClick={() => setRecordCertificateOpen(true)}>点击查看</span>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="cardDetails_image_center">
                    <Image
                        src={'https://qjprod-images.oss-cn-beijing.aliyuncs.com/tsfzx_institution/' + personnelInfo.certificateOfFixedBusinessPremises}
                        alt=""/>
                    店内经营场所照片
                  </div>
                  <div className="cardDetails_image_right">
                    <Image
                        src={'https://qjprod-images.oss-cn-beijing.aliyuncs.com/tsfzx_institution/' + personnelInfo.photosOfBusinessPremises}
                        alt=""/>
                    店外经营场所照片
                  </div>
                </div>
              </div>
              <div className='cardDetails'>
                <h4 className="cardDetails_title">机构信息</h4>
                <div className="cardDetails_mechanismInfo">
                  <div className="title">
                    <img src={mechanismInfo} alt=""/>
                    <h4>{personnelInfo.archivesName}</h4>
                  </div>
                  <Row className="mechanism">
                    <Col span={8}>经营模式<span>{personnelInfo.managementModelName}</span></Col>
                    <Col span={8}>统一社会信用代码<span>{personnelInfo.businessLicenseNumber}</span></Col>
                    <Col span={8}>品牌<span>{personnelInfo.brandName}</span></Col>

                    <Col span={8}>注册资本<span>{personnelInfo.registerCapital}万元</span></Col>
                    <Col span={8}>所属地区<span>{personnelInfo.administrativeArea}</span></Col>
                    <Col span={8}>法定代表人<span>{personnelInfo.legalPersonName}</span></Col>

                    <Col span={8}>成立日期<span>{personnelInfo.registerTime}</span></Col>
                    <Col span={8}>联系人<span>{personnelInfo.contactPeople}</span></Col>
                    <Col span={8}>联系电话<span>{personnelInfo.complaintsHotline}</span></Col>

                    <Col span={8}>登记备案编号<span>{personnelInfo.recordCertificateNumber}</span></Col>
                    <Col span={8}>登记备案有效期<span>{personnelInfo.deadlineForFilingValidity}</span></Col>
                    <Col span={8}></Col>

                    <Col span={24}>机构住所<span>{personnelInfo.registerAddress}</span></Col>

                    <Col span={24}>经营范围<span>{personnelInfo.businessScope}</span></Col>
                  </Row>
                </div>
              </div>
              <div className='cardDetails'>
                <h4 className="cardDetails_title">行为记录</h4>
                <div className="cardDetails_table">
                  <PersonnelTabTable id={id}></PersonnelTabTable>
                </div>
              </div>
            </main>
            <footer>
              <div>
                <span>地址: 唐山铭扬国际4层</span>
                <span className="footer_left">电话: 0315-8080089</span>
              </div>
              <div style={{marginTop: '10px'}}>
                <span>Copyright @ 2024 唐山市房地产中介服务行业协会 版权所有</span>
                <span className="footer_left">ICP备案编号: 冀ICP备2023040514</span>
              </div>
            </footer>
          </div> : <div
              style={{width: '100%', display: "flex", alignItems: "center", justifyContent: "center", height: '100vh'}}>
            <Spin size="large"/>
          </div>
        }
        {/* 登记卡预览弹窗 */}
        <Modal
            title="登记卡预览"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            width={'500px'}
            style={{top: '100px'}}
            footer={null}
        >
          <Image
              preview={false}
              src={`${personnelInfo.cardUrl}?x-oss-process=image/watermark,text_6aKE6KeI77yM5LiN5pSv5oyB5omT5Y2w5LiL6L29,color_dbd6d1,size_100,rotate_40,t_70,g_sw/watermark,text_6aKE6KeI77yM5LiN5pSv5oyB5omT5Y2w5LiL6L29,color_dbd6d1,size_100,rotate_40,t_70,g_ne`}
              alt=""/>
        </Modal>
        <Modal
            title="备案证书"
            open={recordCertificateOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            width={'500px'}
            style={{top: '100px'}}
            footer={null}
        >
          <Image
              preview={false}
              src={'https://qjprod-images.oss-cn-beijing.aliyuncs.com/tsfzx_institution/' + personnelInfo.othersImageList}
              alt=""/>
        </Modal>
      </div>
  );
};
