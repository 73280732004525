const initialState = {
  buttonResourceVOList:[],
  pageResourceVOList:[],
  permissionObj:{},
}
export default (state = initialState,action) => {
  switch(action.type) {
      case 'permission':
          let {pageResourceVOList,buttonResourceVOList,permissionObj} = action.value;
          state.buttonResourceVOList = buttonResourceVOList;
          state.pageResourceVOList = pageResourceVOList;
          state.permissionObj = permissionObj;
          return state
      default:
          return state
  }
}
