import personManagement from '../../../api/ManagePage/Personnel/Personnel'
class Personnel {
  // 创建单例
  static getInstance() {
    if (!this.personInstance) {
      this.personInstance = new Personnel()
    }
    return this.personInstance
  }
  // 获取表头
  personHeader() {
    return personManagement.personHeader()
  }
  // 获取表格数据
  peoplesList(page,size,data) {
    return personManagement.peoplesList(page,size,data)
  }
  // 获取表格数据
  peopleDetails(id) {
    return personManagement.peopleDetails(id)
  }
  // 获取表格数据
  getDetails(data) {
    return personManagement.getDetails(data)
  }
  // 获取审批记录
  approvalHistory(id) {
    return personManagement.approvalHistory(id)
  }
  // 获取性别字典
  getSexDict() {
    return personManagement.getSexDict()
  }
  // 获取政治面貌
  getPoliticalFaceDict() {
    return personManagement.getPoliticalFaceDict()
  }
  // 获取从业人员类型
  queryPostType() {
    return personManagement.queryPostType()
  }
  // 获取学历字典
  getEducationDict() {
    return personManagement.getEducationDict()
  }
  // 获取职务
  dictionaryList(id) {
    return personManagement.dictionaryList(id)
  }
  // 修改保存
  peoplePut(data) {
    return personManagement.peoplePut(data)
  }
  // 新增
  peoplePost(data) {
    return personManagement.peoplePost(data)
  }
  // 注销
  resign(data) {
    return personManagement.resign(data)
  }
  // 获取房产经纪字典
  houseAgent() {
    return personManagement.houseAgent()
  }

  // 行为记录
  // 不良行为列表
  badBehaviorList(data) {
    return personManagement.badBehaviorList(data)
  }
  // 黑名单行为列表
  blackListBehaviorList(data) {
    return personManagement.blackListBehaviorList(data)
  }
  // 良好行为列表
  goodBehaviorList(data) {
    return personManagement.goodBehaviorList(data)
  }
  // 详情页面内行为记录
  detailsBehaviorList(data) {
    return personManagement.detailsBehaviorList(data)
  }
  // 表头
  tableHeader(tableTypeCode) {
    return personManagement.tableHeader(tableTypeCode)
  }

  // H5 使用
  // 从业人员信息查询
  employeesInfo(data) {
    return personManagement.employeesInfo(data)
  }
  // 发表评价
  postComments(data) {
    return personManagement.postComments(data)
  }
  // 领取方式
  receiveWay() {
    return personManagement.receiveWay()
  }
  // 所在地区
  location() {
    return personManagement.location()
  }
  // 查询人员能否申请制卡,外部调用判断使用(true能，false不能)
  allowCardMaking(data) {
    return personManagement.allowCardMaking(data)
  }
  // 查询会员类型
  peopleCardDetails(id) {
    return personManagement.peopleCardDetails(id)
  }
  // 查询会员类型
  firmInfo(data) {
    return personManagement.firmInfo(data)
  }
  // 查询会员类型
  external(data) {
    return personManagement.external(data)
  }
  // 获取付款二维码链接
  generateQRCode(data) {
    return personManagement.generateQRCode(data)
  }
  // 查询支付状态
  paymentStatus(data) {
    return personManagement.paymentStatus(data)
  }
}
export default Personnel.getInstance();
