import axios from '../../axios'
import request_url from '../../../assest/utils/request_url'
let baseUrl = request_url('enterpriseRecord')
export default {
  // 获取表头
  filingRecordsHeader() {
    return axios.get(`${baseUrl}archives/merchant/query/filingRecordsHeader`)
  },
  // 获取表格数据
  filingRecordsQuery(id,page,size) {
    return axios.post(`${baseUrl}archives/merchant/query/filingRecordsQuery/${id}/${page}/${size}`)
  },
  // 验证按钮是否可以新增
  institutionFilingStatus(data) {
    return axios.post(`${baseUrl}archives/merchant/management/institutionFilingStatus`,data)
  },
  // 获取详情
  filingRecordsDetail(data) {
    return axios.post(`${baseUrl}archives/merchant/query/filingRecordsDetail`,data)
  },
  // 获取审批记录
  filingAuditHistory(id) {
    return axios.get(`${baseUrl}archives/merchant/management/filingAuditHistory/${id}`)
  },
  // 变更 / 申请备案
  repeatedReview(data) {
    return axios.post(`${baseUrl}archives/merchant/management/repeatedReview`, data)
  },
  // 通过商户ID 获取部门ID
  obtainDeptThroughMerchants(archivesId) {
    return axios.get(`${baseUrl}archives/merchant/query/obtainDeptThroughMerchants/${archivesId}`)
  },
}
