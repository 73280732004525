import loginApi from '../../api/login/login'
class Login {
  static getInstance() {
    if(!this.login) {
      this.login = new Login()
    }
    return this.login
  }
  // 账号密码登录
  async logon(data) {
    return loginApi.logon(data)
  }
  // 忘记密码-校验
  async forgotPasswordCheck(data) {
    return loginApi.forgotPasswordCheck(data)
  }
  // 忘记密码
  async forgotPassword(data) {
    return loginApi.forgotPassword(data)
  }
}
export default Login.getInstance()
