import {useState, useEffect} from "react";
import {Table, Space, Button} from 'antd';
import '../../../styles/ManagePage/Personnel/practitionersRegistrationTable.scss'

export default (prop) => {
  const {
    pageInfo,
    setSelectRow,
    setSelectedRowKeys,
    personList,
    personHead,
    total,
    onPageChange,
    onModify,
    loading,
    onDetail
  } = prop;
  const [head, setHead] = useState([])
  useEffect(() => {
    handleHead()
  }, [personHead, personList])
  const columns = [
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      width: 180,
      className: 'person-action',
      render: (text, row) => {
        return (
          <>
            <Space size="middle">
              <Button type="link" onClick={() => onDetail(row)}>详情</Button>
              {
                !row.history && (row.materialAudit !== '审核通过' || row.onSiteAudit !== '审核通过')? (
                  <div>
                    <span style={{color: '#d9d9d9'}}>|</span>
                    <Button type="link" onClick={() => onModify(row)}>变更</Button>
                  </div>
                ) : null
              }
            </Space>
          </>
        )
      },
    },
  ];
  const handleHead = () => {
    let arr = JSON.parse(JSON.stringify(personHead))
    arr.map((el, ind) => {
      if (el.label === '备案/复核编号') {
        el.title = '登记/复核编号'
        el.dataIndex = el.property
      }else {
        el.title = el.label
        el.dataIndex = el.property
      }
      delete el.label
      delete el.property
    })
    arr = [...arr, ...columns]
    setHead(arr)
  }
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectRow(selectedRows);
      setSelectedRowKeys(selectedRowKeys)
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),

  };
  return (
    <div className="person-table">
      <Table
        scroll={{x: '100%', y: '520px'}}
        columns={[...head]}
        loading={loading}
        bordered
        size={'small'}
        dataSource={personList}
        pagination={
          {
            showSizeChanger: true,
            defaultPageSize: 20,
            total: total,
            onChange: onPageChange,
            current: pageInfo.page
          }
        }/>
    </div>
  )
}
