import {Table, Tabs} from 'antd';
import React, {useEffect, useRef, useState} from "react";
import PractitionersRegistration from "../../service/ManagePage/Personnel/Personnel";
import mitt from "../../assest/utils/mitt";

export default (props) => {
  const { subjectId, subjectType } = props
  const [personList, setPersonList] = useState([]);// 列表
  const [personHead, setPersonHead] = useState([]);// 表头
  const [tableLoading, setTableLoading] = useState(false)// 表格loading
  const [total, setTotal] = useState(0);// 总数
  let tabIndex = 1;// 总数
  const pageInfo = useRef({page: 1, size: 20});
  useEffect(() => {
    getList();
    getHeader();
  }, [])
  let map = new Map([
    [1, {header: 'blackListBehaviorList', list: 110}],
    [2, {header: 'badBehaviorList', list: 111}],
    [3, {header: 'goodBehaviorList', list: 112}]
  ])
  const items = [
    {
      key: 1,
      label: '黑名单',
    },
    {
      key: 2,
      label: '不良行为',
    },
    {
      key: 3,
      label: '良好行为',
    },
  ];
  // tab 切换
  const onChange = async (key) => {
    // settabIndex(key)
    tabIndex = key
    getHeader();
    getList();
  };
  const getHeader = async () => {
    let res = await PractitionersRegistration.tableHeader({requestParam: map.get(tabIndex).header});
    res.map((el, ind) => {
      {
        el.key = el.data
        el.dataIndex = el.data
        el.title = el.headerFirst
      }
    })
    setPersonHead(res)
  }
  const getList = async () => {
    let type = map.get(tabIndex).list
    let res = await PractitionersRegistration.detailsBehaviorList(
      {
        page: pageInfo.current.page,
        size: pageInfo.current.size,
        behaviorType: type, // 行为类型 行为类型(黑名单:110,不良行为:111,良好行为:112)
        subjectId: subjectId, // 人或机构ID
        subjectType: subjectType, // 被认定主体类型(人:130,机构:131)
      });
    setPersonList(res.list)
    setTotal(res.totalCount);
  }
  // 分页
  const onPageChange = (page, size) => {
    pageInfo.current = {page, size};
    getList();
  }
  return (
    <div>
      <Tabs defaultActiveKey={tabIndex} items={items} onChange={onChange}/>
      <Table columns={personHead}
             bordered
             loading={tableLoading}
             pagination={
               {
                 showSizeChanger: true,
                 defaultPageSize: 20,
                 total: total,
                 onChange: onPageChange,
                 current: pageInfo.current.page
               }
             }
             dataSource={personList}/>
    </div>
  )
}
