import axios from '../../axios'
import request_url from '../../../assest/utils/request_url'
let baseUrl = request_url('enterpriseRecord')
export default {
  // 获取表格数据
  institutionalPublicityQuery(data) {
    return axios.post(`${baseUrl}archives/merchant/query/institutionalPublicityQuery`,data)
  },
  // 获取表头
  institutionalPublicityQueryHeader(page,size,data) {
    return axios.get(`${baseUrl}archives/merchant/query/institutionalPublicityQueryHeader`)
  },
}
