import React, {useState, useEffect, useRef} from "react";
import "../../../styles/ManagePage/Enterprise/addOrgan.scss";
import mitt from '../../../assest/utils/mitt'
import PractitionersRegistration from "../../../service/ManagePage/Personnel/Personnel";
import {Button, Col, Row, Spin, Image} from "antd";
import TabTable from "../tabTable";
import member_img from "../../../assest/image/member.png";
import unmember_img from "../../../assest/image/unmember.png";

export default (prop) => {
  const {tableRow, modifySuccess, onCancleModify, onCancleDetail, jumpModify} = prop;
  const [detail, setDetail] = useState()
  useEffect(() => {
    getDetail();
  }, []);
  useEffect(() => {
    mitt.on('refreshDetailPage', mittRefreshDetail)
    return () => {
      mitt.off('refreshDetailPage', mittRefreshDetail);
    }
  }, [])
  const mittRefreshDetail = () => { // 刷新页面
    getDetail();
  }

  // 获取详情
  const getDetail = async () => {
    let {basicId} = tableRow;
    let res = await PractitionersRegistration.getDetails({requestParam:basicId});
    let data = {}
    data.name = res.basicPeopleVO.name
    data.sex = res.basicPeopleVO.sex
    data.postTypeValue = res.workPeopleDetailsVO.postTypeValue // 从业人员类型
    data.registrationCode = res.workPeopleDetailsVO.registrationCode // 从业人员类型
    data.inDeptName = res.workPeopleDetailsVO.inDeptName // 从业机构
    data.entryTime = res.workPeopleDetailsVO.entryTime // 登记有效期
    data.certificateCode = res.houseAgentVO.certificateCode // 资格证号
    data.head = res.head // 头像
    setDetail({...data})
  };
  // 返回列表页
  const onBack = () => {
    onCancleDetail();
  }
  // 判断登记状态
  const getStatus = (id) => {
    switch (id) {
      case 100:
        return '已过期（未提交）'
      case 101:
        return '待审批'
      case 102:
        return '已通过'
      case 103:
        return '已驳回'
      default:
        return null
    }
  }
  return (
    <div id="addOrgan" className="detailOrgan">
      <div className="title">
        从业人员详情
      </div>
      {
        detail !== undefined ? (
          <div className="info">
            <div className="info_left">
              <Image style={{pointerEvents: 'none'}} preview={false} src={detail.head} alt=""/>
            </div>
            <div className="info_right">
              <Row className="row">
                <Col span={12}><span>{detail.name || '-'}<span style={{marginLeft: '10px'}}>({detail.sex === 1002 ? '女' : '男'})</span></span></Col>
                <Col span={12}>信息卡号：<span>{detail.registrationCode || '-'}</span></Col>
                <Col span={12}>从业类型：<span>{detail.postTypeValue || '-'}</span></Col>
                <Col span={12}>资格证号：<span>{detail.certificateCode || '-'}</span></Col>
                <Col span={12}>从业机构：<span>{detail.inDeptName || '-'}</span></Col>
                <Col span={12}>登记有效期：<span>{detail.entryTime || '-'}</span></Col>
                {tableRow.memberUnitType !== "非会员" ?
                    <img className="row_member" src={member_img} alt=""/> :
                    <img className="row_member" src={unmember_img} alt=""/>}
              </Row>
            </div>
          </div>
        ) : <Spin size="large"/>
      }
      <div className="title">
        行为记录
      </div>
      <TabTable subjectId={tableRow.basicId} subjectType={130}></TabTable>
      <div style={{width: '100%', display: "flex", justifyContent: "center", marginTop: '30px'}}>
        <Button style={{marginLeft: '20px'}} onClick={onBack}>返回</Button>
      </div>
    </div>
  );
};
