import React, {useState, useEffect, useRef} from "react";
import "../../../styles/ManagePage/Enterprise/addOrgan.scss";
import mitt from '../../../assest/utils/mitt'
import PractitionersRegistration from "../../../service/ManagePage/Personnel/Personnel";
import {Button, Col, Row, Spin,Image} from "antd";

export default (prop) => {
  const {tableRow, modifySuccess, onCancleModify, onCancleDetail, jumpModify} = prop;
  const [detail, setDetail] = useState()
  const [recording, setrecording] = useState();//审批记录
  useEffect(() => {
    getDetail();
  }, []);
  useEffect(() => {
    mitt.on('refreshDetailPage', mittRefreshDetail)
    return () => {
      mitt.off('refreshDetailPage', mittRefreshDetail);
    }
  }, [])
  useEffect(() => {
    // console.log(detail);
    // console.log(recording);
  }, [detail, recording]);
  const mittRefreshDetail = () => { // 刷新页面
    getDetail();
  }
  // 获取详情
  const getDetail = async () => {
    let {basicId} = tableRow;
    let res = await PractitionersRegistration.peopleDetails(basicId);
    let approvalHistory = await PractitionersRegistration.approvalHistory(basicId);
    let data = {}
    data.idCard = res.basicPeopleVO.idCard
    data.name = res.basicPeopleVO.name
    data.sex = res.basicPeopleVO.sex
    data.phone = res.basicPeopleVO.phone
    data.registrationCode = res.workPeopleDetailsVO.registrationCode // 登记编号
    data.politicalFaceValue = res.basicPeopleVO.politicalFaceValue // 政治面貌
    data.education = res.educationVO.education // 学历
    data.postTypeValue = res.workPeopleDetailsVO.postTypeValue // 从业人员类型
    data.registrationStatus = res.workPeopleDetailsVO.registrationStatus // 登记状态
    data.certificateCode = res.houseAgentVO.certificateCode // 资格证号
    data.head = res.head // 头像
    data.contractImgUrl = res.contractVO.contractImgUrl // 身份证正反面
    data.qualification = res.contractVO.qualification // 资格证书
    data.registrationCertificate = res.contractVO.registrationCertificate // 资格证书
    setDetail({...data})
    setrecording(approvalHistory)
  };
  // 返回列表页
  const onBack = () => {
    onCancleDetail();
  }
  // 跳转到修改页面
  const onModify = () => {
    let {basicId} = tableRow;
    jumpModify({id: basicId});
    onBack();
  }
  // 判断登记状态
  const getStatus = (id) => {
    switch (id) {
      case 100:
        return '已过期（未提交）'
      case 101:
        return '待审批'
      case 102:
        return '已通过'
      case 103:
        return '已驳回'
      default:
        return null
    }
  }
  return (
    <div id="addOrgan" className="detailOrgan">
      <div className="title">
        基本信息
      </div>
      {
        detail !== undefined ? (
          <div className="info">
            <div className="info_left">
              <Image width={102} src={detail.head} alt=""/>
            </div>
            <div className="info_right">
              <Row className="row">
                <Col span={24}>实名登记号：<span>{detail.registrationCode || '-'}</span></Col>
                <Col span={8}>姓名：<span>{detail.name || '-'}</span></Col>
                <Col span={8}>性别：<span>{detail.sex === 1002 ? '女' : '男'}</span></Col>
                <Col span={8}>身份证号码：<span>{detail.idCard || '-'}</span></Col>
                <Col span={8}>政治面貌：<span>{detail.politicalFaceValue || '-'}</span></Col>
                <Col span={8}>学历：<span>{detail.education || '-'}</span></Col>
                <Col span={8}>手机号码：<span>{detail.phone || '-'}</span></Col>
                <Col span={8}>从业人员类型：<span>{detail.postTypeValue || '-'}</span></Col>
                <Col span={16}>资格证号：<span>{detail.certificateCode || '-'}</span></Col>
                <Col span={12}><span className="label">身份证正反面：</span>
                  {detail.contractImgUrl.length !== 0 ?
                    detail.contractImgUrl.map(item => <Image
                      width={102}
                      style={{width: '102px', height: '102px', verticalAlign: 'text-top', marginRight: '20px'}}
                      src={item} alt=""/>)
                    : '-'}
                </Col>
                <Col span={12}><span className="">资格证书：</span>
                  {detail.qualification.length !== 0 ?
                    detail.qualification.map(item => <Image
                      width={102}
                      style={{width: '102px', height: '102px', verticalAlign: 'text-top', marginRight: '20px'}}
                      src={item} alt=""/>)
                    : '-'}
                </Col>
                <Col span={12}><span className="label">登记证书：</span>
                  {detail.registrationCertificate.length !== 0 ?
                    detail.registrationCertificate.map(item => <Image
                      width={102}
                      style={{width: '102px', height: '102px', verticalAlign: 'text-top', marginRight: '20px'}}
                      src={item} alt=""/>)
                    : '-'}
                </Col>
              </Row>
            </div>
          </div>
        ) : <div style={{width: '100%',display: "flex", justifyContent: "center", marginTop: '30px'}}>
          <Spin size="large"/>
        </div>
      }
      <div className="title">
        审核记录
      </div>
      {
        detail !== undefined ? (
          <div className="info">
            <div>
              <span className="state">登记审核状态：{getStatus(detail.registrationStatus)}</span>
              <ul className="recording">
                {
                  recording !== undefined ?
                    recording.map(item => {
                      return (
                        <li>
                          <div className={item.result === '审批通过' ? 'success' : null}></div>
                          <span>{item.time}：{item.result}{item.opinion ? '，原因：' + item.opinion : null}</span>
                        </li>)
                    }) : <Spin size="large"/>
                }
              </ul>
            </div>
          </div>
        ) : null
      }
      <div style={{width: '100%', display: "flex", justifyContent: "center", marginTop: '30px'}}>
        {
          detail !== undefined ? <Button type="primary" onClick={onModify}>变更</Button> : <Spin size="large"/>
        }
        <Button style={{marginLeft: '20px'}} onClick={onBack}>返回</Button>
      </div>
    </div>
  );
};
