import {useState, useEffect} from "react";
import '../../../styles/ManagePage/Personnel/addPersonnelRegister.scss'
import {Button, Col, Row, Spin, Image} from 'antd'
import mitt from '../../../assest/utils/mitt'
import EnterpriseRecord from "../../../service/ManagePage/Enterprise/Enterprise";
// 引入图片路径
import request_url from '../../../assest/utils/request_url'

let baseUrl = request_url('imgUrl')
export default (prop) => {
  const {tableRow, onCancleDetail} = prop;
  const [detail, setDetail] = useState({});//详情信息
  const [recording, setrecording] = useState();//审批记录
  useEffect(() => {
    // 获取详情
    getDetail()
  }, []);
  useEffect(() => {
    // console.log(detail);
    // console.log(recording);
  }, [detail, recording]);
  useEffect(() => {
    mitt.on('refreshDetailPage', mittRefreshDetail)
    return () => {
      mitt.off('refreshDetailPage', mittRefreshDetail);
    }
  }, [])
  const mittRefreshDetail = () => { // 刷新页面
    getDetail();
  }
  // 返回列表
  const onBack = () => {
    onCancleDetail()
  }
  //获取详情
  const getDetail = async () => {
    let {archivesBusinessId, history} = tableRow;
    let res = await EnterpriseRecord.filingRecordsDetail({history, archivesBusinessId})
    setDetail(res)
    res = await EnterpriseRecord.filingAuditHistory(archivesBusinessId)
    setrecording(res)
  }
  return (
    <div id="AddPersonnelRegister" className="detailPersonnelRegister">
      <div className="auditTrails">
        <div className="title">审核记录</div>

        <span className="state">登记审核状态：{detail !== {} ? <span>{detail.record ? '是' : '否'}</span> : null}</span>
        <ul className="recording">
          {
            recording !== undefined ?
              recording.map(item => {
                return (
                  <li>
                    <div className={item.state === '审核通过' ? 'success' : null}></div>
                    <span>{item.createtime}：{item.type} {item.state}{item.opinion ? '，原因：' + item.opinion : null}</span>
                  </li>)
              }) : <Spin size="large" />
          }
        </ul>
      </div>
      <div className="dividing"></div>
      {detail !== {} ? <div className="info">
        <div className="title">
          基本信息
        </div>
        <Row className="row">
          <Col style={{marginTop: 0}} span={8}>企业名称：<span>{detail.archivesName || '-'}</span></Col>
          <Col style={{marginTop: 0}} span={8}>统一信用代码：<span>{detail.businessLicenseNumber || '-'}</span></Col>
          <Col style={{marginTop: 0}} span={8}>注册资本：<span>{detail.registerCapital || '-'}</span></Col>

          <Col span={8}>行业类型：<span>{detail.industryCategoryName || '-'}</span></Col>
          <Col span={8}>协会会员：<span>{detail.memberName || '-'}</span></Col>
          <Col span={8}>所属地区：<span>{detail.administrativeArea || '-'}</span></Col>

          <Col span={8}>经营模式：<span>{detail.managementModelName || '-'}</span></Col>
          <Col span={8}>品牌：<span>{detail.brandName || '-'}</span></Col>
          <Col span={8}>上级机构：<span>{detail.fatherDept || '-'}</span></Col>

          <Col span={8}>机构住所：<span>{detail.registerAddress || '-'}</span></Col>
          <Col span={8}>成立日期：<span>{detail.registerTime || '-'}</span></Col>
          <Col span={8}>营业地址坐标：<span>{detail.businessAddressCoordinate || '-'}</span></Col>

          <Col span={24}>营业地址：<span>{detail.businessAddress || '-'}</span></Col>
          <Col span={24}>经营范围：<span>{detail.businessScope || '-'}</span></Col>

          <Col span={8}>法人姓名：<span>{detail.legalPersonName || '-'}</span></Col>
          <Col span={8}>身份证号码：<span>{detail.idCard || '-'}</span></Col>
          <Col span={8}>手机号码：<span>{detail.phone || '-'}</span></Col>

          <Col span={8}>联系人：<span>{detail.contactPeople || '-'}</span></Col>
          <Col span={16}>联系电话：<span>{detail.complaintsHotline || '-'}</span></Col>
          <Col style={{marginBottom: 0}} span={8}>备案编号：<span>{detail.recordCertificateNumber || '-'}</span></Col>
          <Col style={{marginBottom: 0}}
               span={16}>登记有效期：<span>{detail.deadlineForFilingValidity || '-'}</span></Col>
        </Row>
        <div className="title">
          相关证件
        </div>
        <Row className="row">
          <Col span={12}>
            <div className="label">法人身份证正反面：</div>
            {detail.idCardImageList !== undefined ?
              detail.idCardImageList.map(item => <Image
                width={102}
                style={{width: '102px', height: '102px', verticalAlign: 'text-top', marginRight: '20px'}}
                src={baseUrl + item} alt=""/>)
              : '-'}
          </Col>
          <Col span={12}>
            <div className="label">企业营业执照副本：</div>
            {detail.licenseImageList !== undefined ?
              detail.licenseImageList.map(item => <Image
                width={102}
                style={{width: '102px', height: '102px', verticalAlign: 'text-top', marginRight: '20px'}}
                src={baseUrl + item} alt=""/>)
              : '-'}
          </Col>
          <Col span={12}>
            <div className="label">机构备案证书：</div>
            {detail.othersImageList !== undefined ?
              detail.othersImageList.map(item => <Image
                width={102}
                style={{width: '102px', height: '102px', verticalAlign: 'text-top', marginRight: '20px'}}
                src={baseUrl + item} alt=""/>)
              : '-'}
          </Col>
          <Col span={12}>
            <div className="label">店内经营场所照片：</div>
            {detail.certificateOfFixedBusinessPremises !== undefined ?
                detail.certificateOfFixedBusinessPremises.map(item => <Image
                    width={102}
                    style={{width: '102px', height: '102px', verticalAlign: 'text-top', marginRight: '20px'}}
                    src={baseUrl + item} alt=""/>)
                : '-'}
          </Col>
          <Col span={12}>
            <div className="label">店外经营场所照片：</div>
            {detail.photosOfBusinessPremises !== undefined ?
                detail.photosOfBusinessPremises.map(item => <Image
                    width={102}
                    style={{width: '102px', height: '102px', verticalAlign: 'text-top', marginRight: '20px'}}
                    src={baseUrl + item} alt=""/>)
                : '-'}
          </Col>
        </Row>
      </div> : <Spin size="large" />}
      <Button className="onBack" onClick={onBack}>返回</Button>
    </div>
  );
};
