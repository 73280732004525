import {useState, useEffect} from "react";
import {Table, Space, Button, Rate} from 'antd';
import '../../../styles/ManagePage/Personnel/practitionersRegistrationTable.scss'
import member_img from "../../../assest/image/member.png";
import unmember_img from "../../../assest/image/unmember.png";
export default (prop) => {
  const {
    pageInfo,
    setSelectRow,
    setSelectedRowKeys,
    personList,
    personHead,
    total,
    onPageChange,
    onModify,
    loading,
    onDetail,
  } = prop;
  const [head, setHead] = useState([])
  useEffect(() => {
    handleHead()
  }, [personHead, personList])
  const columns = [
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      width: 130,
      className: 'person-action',
      render: (text, row) => {
        return (
          <>
            <Button type="link" onClick={() => onDetail(row)}>查看</Button>
            <span style={{color: '#d9d9d9'}}>|</span>
            <Button type="link" onClick={() => onModify(row)}>投诉</Button>
          </>
        )
      },
    },
  ];
  const handleHead = () => {
    let arr = JSON.parse(JSON.stringify(personHead))
    arr.map((el, ind) => {
      if (el.label === '诚信评分') {
        el.title = el.label
        el.width = 75
        el.dataIndex = el.property
        el.render = (text, record) => (
          <span>{record.creditScore ? record.creditScore : 15}</span>
        );
      }else if(el.label === '会员'){
        el.title = '会员'
        el.dataIndex = el.property
        el.render = (text, record) => (
          record.memberName !== '非会员' ? <img style={{width: '75px',pointerEvents: "none"}} src={member_img} alt=""/> :
              <img style={{width: '75px',pointerEvents: "none"}} src={unmember_img} alt=""/>
        );
      } else if (el.label === '备案有效期') {
        el.title = '登记有效期'
        el.dataIndex = el.property
      }else if(el.label === '法人'){
        el.title = '法人'
        el.width = 80
        el.dataIndex = el.property
      }else if(el.label === '机构名称'){
        el.title = '机构名称'
        el.width = 250
        el.dataIndex = el.property
      } else {
        el.title = el.label
        el.dataIndex = el.property
      }
      delete el.label
      delete el.property
    })
    arr = [...arr, ...columns]
    setHead(arr)
  }
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectRow(selectedRows);
      setSelectedRowKeys(selectedRowKeys)
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  };
  return (
    <div className="person-table">
      <Table
        scroll={{x: '100%', y: '100%'}}
        columns={[...head]}
        loading={loading}
        bordered
        size={'small'}
        dataSource={personList}
        pagination={
          {
            showTotal: (total, range) => `共 ${total} 条数据`,
            showSizeChanger: true,
            defaultPageSize: 20,
            total: total,
            onChange: onPageChange,
            current: pageInfo.page
          }
        }/>
    </div>
  )
}
