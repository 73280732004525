import {useState, useEffect} from "react";
import '../../../styles/ManagePage/Personnel/addPersonnelRegister.scss'
import {Button, Cascader, Col, DatePicker, Form, Input, message, Modal, Radio, Row, Select, Spin, Upload} from 'antd'
import EnterpriseRecord from "../../../service/ManagePage/Enterprise/Enterprise";
import {PlusOutlined, SendOutlined,} from "@ant-design/icons";
import register from "../../../service/register/register";
import uploadImg from "../../../assest/utils/upload";
import dayjs from "dayjs";
// 引入图片路径
import request_url from '../../../assest/utils/request_url'
import * as rules_vertify from "../../../assest/utils/rules_verfity";
import {ID_CARD_NUMBER} from "../../../assest/utils/rules_verfity";
import Amap from "../../public/amap";
import BrandMode from "../../public/BrandMode";
let baseUrl = request_url('imgUrl')
const getBase64 = (file: RcFile) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default (prop) => {
  const {tableRow, modifySuccess, onCancleModify} = prop;
  const [btnLoading, setBtnLoading] = useState(false);// 按钮loading
  const [spin, setspin] = useState(false);// 按钮loading
  const [detail, setDetail] = useState({});// 详情

  const [newForm] = Form.useForm();
  const [formValue, setFormValue] = useState({branchOrNot: false}); // 表单默认值
  const [archivesId, setArchivesId] = useState(); // 企业id

  const [perviewImg, setPerviewImg] = useState([]); // 预览图片数组
  const [idCardImageList, setidCardImageList] = useState([]); // 法人身份证正反面
  const [licenseImageList, setlicenseImageList] = useState([]); // 法人身份证正反面
  const [othersImageList, setothersImageList] = useState([]); // 法人身份证正反面
  const [certificateOfFixedBusinessPremisesImageList, setCertificateOfFixedBusinessPremisesImageList] = useState([]); // 固定经营场所证明--店内经营场所照片
  const [photosOfBusinessPremisesImageList, setPhotosOfBusinessPremisesImageList] = useState([]); // 经营场所照片--店外经营场所照片
  const [showPreview, setShowPreview] = useState(false); // 预览图片弹窗
  const [branchOrNot, setbranchOrNot] = useState(false); // 上级机构否显示
  const [industryCategoryData, setIndustryCategoryData] = useState(); // 上级机构否显示
  const [mapDisplay, setmapDisplay] = useState(false); // 预览图片弹窗
  const [businessAddressDisabled, setbusinessAddressDisabled] = useState(true); // 营业地址

  const [industryCategory, setIndustryCategory] = useState([]); // 企业类型 字典
  const [memberDict, setMemberDict] = useState([]); // 协会会员等级 字典
  const [operatingModeDict, setOperatingModeDict] = useState([]); // 经营模式 字典
  const [fatherDeptDict, setFatherDeptDict] = useState([]); // 经营模式 字典
  const [administrativeArea, setAdministrativeArea] = useState([]); // 所属地区 字典
  const [operationMode, setOperationMode] = useState(false); // 运转模式字典
  const [brandMode, setBrandMode] = useState(false); // 品牌弹窗
  const [brandData, setBrandData] = useState(); // 品牌数据
  const [brandDict, setBrandDict] = useState([]); // 品牌 字典


  useEffect(() => {
    getDetail();
    getDict();
  }, []); // 获取详情
  // useEffect(() => {
  //   console.log(idCardImageList)
  // }, [idCardImageList]); // 获取详情

  // 品牌弹窗
  const brandClick = () => {
    setBrandMode(true)
  }
  const setBrandDataForm = (value) => {
    setBrandData({brandName:value.brandName, brandLogo: value.brandLogo,archivesBusinessId:archivesId})
    newForm.setFieldsValue({brand:value.id});
  }

  //获取详情
  const getDetail = async () => {
    let {archivesBusinessId, history} = tableRow;
    let res = await EnterpriseRecord.filingRecordsDetail({history, archivesBusinessId})
    setArchivesId(res.archivesId)
    // 字符串时间格式化为 date格式
    res.registerTime = dayjs(res.registerTime)
    res.deadlineForFilingValidity = dayjs(res.deadlineForFilingValidity)
    // 初始化图片
    setidCardImageList(res.idCardImageList.map(item => {
      return {
        url: baseUrl + item,
        uid: item,
        name: item,
      }
    }))
    setlicenseImageList(res.licenseImageList.map(item => {
      return {
        url: baseUrl + item,
        uid: item,
        name: item,
      }
    }))
    setothersImageList(res.othersImageList.map(item => {
      return {
        url: baseUrl + item,
        uid: item,
        name: item,
      }
    }))
    setCertificateOfFixedBusinessPremisesImageList(res.certificateOfFixedBusinessPremises.map(item => {
      return {
        url: baseUrl + item,
        uid: item,
        name: item,
      }
    }))
    setPhotosOfBusinessPremisesImageList(res.photosOfBusinessPremises.map(item => {
      return {
        url: baseUrl + item,
        uid: item,
        name: item,
      }
    }))
    const initialValues = {};
    for (let resKey in res) {
      initialValues[resKey] = res[resKey];
    }
    newForm.setFieldsValue(initialValues);
  }

  const uploadButton = (
    <div>
      <PlusOutlined/>
    </div>
  );

  // 获取默认字典 无需token
  const getDict = async () => {
    setspin(true);
    // 获取企业类型
    let data = await register.industryCategory();
    setIndustryCategory(data)
    // 获取企业类型
    data = await register.getMember();
    setMemberDict(data)
    // 获取品牌
    data = await register.brandDictionary();
    setBrandDict(data)
    // 获取经营模式
    data = await register.operatingMode();
    setOperatingModeDict(data);
    administrativeAreaIdSearch('');
    setspin(false);
    // 获取运转模式字典
    let res = await register.getOperationMode();
    let isFilingFirst = false
    if(res && res[0]) {
      isFilingFirst = res[0].itemCode == 1
    };
    setOperationMode(isFilingFirst);
  };
  const businessAddressOK = (data) => {
    setmapDisplay(false);
    setbusinessAddressDisabled(false);
    // newForm.setFieldsValue({businessAddress:data.businessAddressText});
    newForm.setFieldsValue({businessAddressCoordinate:data.businessAddressCoordinate});
  }
  const mapClick = () => {
    setmapDisplay(true);
  }
  const checkUser = (rule, value) => {
    if (!value) {
      return Promise.reject();
    }
    // 判断是否是数组，因为修改带入的数据是数组的形式
    if(value.constructor===Array) {
      if(value.length > 0) {
        return Promise.resolve();
      }else {
        return Promise.reject();
      }
    }else if(value.constructor===String){
      // value是单张字符串的情况
      if(value){
        return Promise.resolve()
      }
    }else {
      // value是file对象的情况
      if (!value.file.status) {
        return Promise.resolve();
      }
    }
    return Promise.reject();
  };
  // 上传图片 法人身份证正反面
  const beforeUpload = async (file) => {
    let data = await uploadImg(file);
    file.uid = data.name.split('/')[1];
    file.url = data.url;
    let arr = JSON.parse(JSON.stringify(idCardImageList));
    arr.push(file);
    setidCardImageList(arr);
    return false;
  };
  // 上传图片 企业营业执照副本
  const licenseImageListBeforeUpload = async (file) => {
    let data = await uploadImg(file);
    file.uid = data.name.split('/')[1];
    file.url = data.url;
    let arr = JSON.parse(JSON.stringify(licenseImageList));
    arr.push(file);
    setlicenseImageList(arr);
    return false;
  };
  // 上传图片 机构备案证书
  const othersImageListBeforeUpload = async (file) => {
    let data = await uploadImg(file);
    file.uid = data.name.split('/')[1];
    file.url = data.url;
    let arr = JSON.parse(JSON.stringify(othersImageList));
    arr.push(file);
    setothersImageList(arr);
    return false;
  };
  // 上传图片 固定经营场所证明--店内经营场所照片
  const certificateOfFixedBusinessPremisesImageListBeforeUpload = async (file) => {
    let data = await uploadImg(file);
    file.uid = data.name.split('/')[1];
    file.url = data.url;
    let arr = JSON.parse(JSON.stringify(certificateOfFixedBusinessPremisesImageList));
    arr.push(file);
    setCertificateOfFixedBusinessPremisesImageList(arr);
    return false;
  };
  // 上传图片 经营场所照片--店外经营场所照片
  const photosOfBusinessPremisesImageListBeforeUpload = async (file) => {
    let data = await uploadImg(file);
    file.uid = data.name.split('/')[1];
    file.url = data.url;
    let arr = JSON.parse(JSON.stringify(photosOfBusinessPremisesImageList));
    arr.push(file);
    setPhotosOfBusinessPremisesImageList(arr);
    return false;
  };
  // 上级机构远程搜索
  const handleSearch = async (newValue: string) => {
    if (newValue) {
      let data = await register.deptSearch(newValue)
      setFatherDeptDict(data)
    } else {
      setFatherDeptDict([]);
    }
  };
  // 所属地区远程搜索
  const administrativeAreaIdSearch = async (newValue: string) => {
    let data = await register.searchCnare({name: newValue})
    setAdministrativeArea(data)
  };
  // 表单校验成功
  const onFinish = async (value) => {
    let nowDate = dayjs(new Date().getTime()).format('YYYY-MM-DD')
    // 格式化日期为字符串
    value.registerTime = dayjs(value.registerTime).format('YYYY-MM-DD');
    value.deadlineForFilingValidity = dayjs(value.deadlineForFilingValidity).format('YYYY-MM-DD');
    if(value.deadlineForFilingValidity === nowDate)  { return message.error('请核对备案有效期是否正确');}
    if(value.registerTime === nowDate)  { return message.error('请核对执照成立日期是否正确');}
    // 加入id
    value.archivesId = archivesId;
    // 格式化文件上传，只需要上传uid 最新上传
    value.idCardImageList = value.idCardImageList.fileList ? value.idCardImageList.fileList.map(item => item.uid) : value.idCardImageList
    value.licenseImageList = value.licenseImageList.fileList ? value.licenseImageList.fileList.map(item => item.uid) : value.licenseImageList
    value.othersImageList = value.othersImageList.fileList ? value.othersImageList.fileList.map(item => item.uid) : value.othersImageList
    value.certificateOfFixedBusinessPremises = value.certificateOfFixedBusinessPremises.fileList ?value.certificateOfFixedBusinessPremises.fileList.map(item => item.uid): value.certificateOfFixedBusinessPremises
    value.photosOfBusinessPremises = value.photosOfBusinessPremises.fileList ? value.photosOfBusinessPremises.fileList.map(item => item.uid): value.photosOfBusinessPremises
    // 格式化品牌
    // if (typeof value.brand === 'object') {
    //   value.brand = value.brand[0];
    // }
    // 格式化上级机构字段
    value.fatherDept = value.fatherDeptId;
    delete value.fatherDeptId;
    await EnterpriseRecord.repeatedReview(value)
    message.success('变更成功！');
    if(brandData.brandLogo){
      await register.applyBrandLibrary(brandData);
      message.success('品牌申请已提交');
    }
    modifySuccess()
  };
  // 表单校验失败回调
  const onFinishFailed = (value) => {
    console.log(value.values);
    // console.log(value);
    // getRefineDict();
    // onSubmitClick(stepsName + 1)
  };
  // 图片预览
  const handlePreview = async (name, file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPerviewImg(file.url || file.preview);
    setShowPreview(true);
  };
  // 关闭预览图片弹窗
  const handleCancel = () => setShowPreview(false);
  const delImg = (file, name, {fileData, fileMeth}) => {
    let {uid} = file;
    let filInd = fileData.findIndex((el) => uid === el.uid);
    if (filInd !== -1) {
      let newfile = JSON.parse(JSON.stringify(fileData));
      newfile.splice(filInd, 1);
      fileMeth(newfile);
      newForm.setFieldsValue({
        [name]: newfile.map((el) => el.url),
      });
    }
  };
  // 是否分支机构改变
  const branchOrNotChange = (e) => {
    setbranchOrNot(e.target.value);
  }
  // 行业类型改变
  const industryCategoryChange = (e) => {
    setIndustryCategoryData(e);
  }
  const onBack = () => {
    onCancleModify()
  }
  return (
    <div id="AddPersonnelRegister" style={{zIndex: 10}}>
      <Spin tip="加载中..." spinning={spin}>
        <Form
          name="basic"
          form={newForm}
          initialValues={formValue}
          colon={false}
          className="form"
          onFinish={onFinish}
          size={'default'}
          labelAlign="right"
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row gutter={16} style={{width: '100%'}}>
            <Col className="gutter-row" span={24} style={{marginBottom: '20px'}}>
              <span className="title">基本信息</span>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="企业全称"
                validateFirst={true}
                name="archivesName"
                rules={[{required: true, message: "请输入企业全称"}]}
              >
                <Input
                  disabled={true}
                  allowClear={true}
                  placeholder="请输入企业全称"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item label="统一社会信用代码"
                         name="businessLicenseNumber"
                         rules={[{required: true, message: "请输入统一社会信用代码"},
                           ({getFieldValue}) => ({
                             validator(_, value) {
                               if (!/[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g.test(value)) {
                                 return Promise.reject(new Error('请输入正确统一社会信用代码'));
                               }
                               return Promise.resolve();
                             },
                           })]}
              >
                <Input
                  allowClear={true}
                  showCount
                  maxLength={18}
                  placeholder="请输入统一社会信用代码"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="行业类型"
                validateFirst={true}
                name="industryCategory"
                rules={[{required: true, message: "请选择行业类型"}]}
              >
                <Select style={{width: 120}} allowClear={true}  onChange={industryCategoryChange} placeholder="请输入行业类型">
                  {industryCategory.map((el) => {
                    return (
                      <Select.Option key={el.itemCode} value={el.itemCode}>
                        {el.itemValue}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="协会会员等级"
                rules={[{required: true, message: "请选择协会会员等级"}]}
                name="member">
                <Select style={{width: 120}} allowClear={true} placeholder="请输入协会会员等级">
                  {memberDict.map((el) => {
                    return (
                      <Select.Option key={el.itemCode} value={el.itemCode}>
                        {el.itemValue}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="注册资本(万元)"
                validateFirst={true}
                name="registerCapital"
                rules={[{required: true, message: "请输入注册资本(万元)"},
                {
                  validator: (_, value) => {
                    // 使用正则表达式来校验密码 6-12位 字母或者数字组合
                    const reg = /^-?\d*(.\d+)?$/;
                    if (!reg.test(value)) {
                      return Promise.reject('请输入数字格式');
                    }
                    return Promise.resolve();
                  },
                }]}
              >
                <Input
                  allowClear={true}
                  placeholder="请输入注册资本(万元)"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="所在县区"
                validateFirst={true}
                name="administrativeAreaId"
                rules={[{required: true, message: "请选择所在县区"}]}
              >
                <Select style={{width: 120}} allowClear={true} placeholder="请选择所在县区">
                  {administrativeArea.map((el) => {
                    return (
                      <Select.Option key={el.id} value={el.id}>
                        {el.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="经营模式"
                validateFirst={true}
                name="managementModel"
                rules={[{required: true, message: "请输入经营模式"}]}
              >
                <Select style={{width: 120}} allowClear={true} placeholder="请输入经营模式">
                  {operatingModeDict.map((el) => {
                    return (
                      <Select.Option key={el.itemCode} value={el.itemCode}>
                        {el.itemValue}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={9}>
              <Form.Item
                label="品牌"
                validateFirst={true}
                name="brand"
                rules={[{required: true, message: "请选择品牌"}]}
              >
                {/*<Cascader*/}
                {/*  options={brandDict}*/}
                {/*  placeholder="请选择品牌"*/}
                {/*  multiple={false}*/}
                {/*  expandTrigger="hover"*/}
                {/*  fieldNames={{label: 'itemValue', value: 'itemCode', children: 'subItems'}}*/}
                {/*/>*/}
                <Select style={{width: 120}} allowClear={true}  placeholder="请选择品牌" disabled={true}>
                  {brandDict.map((el) => {
                    return (
                        <Select.Option key={el.itemCode} value={el.itemCode}>
                          {el.itemValue}
                        </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={3}>
              <Button type="primary" style={{width: '100%'}} onClick={brandClick}>请选择</Button>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="是否分支机构"
                validateFirst={true}
                name="branchOrNot"
                rules={[{required: true, message: "请选择上级机构"}]}
              >
                <Radio.Group onChange={branchOrNotChange}>
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {branchOrNot ? (<Col className="gutter-row" span={12}>
              <Form.Item
                label="上级机构"
                validateFirst={true}
                name="fatherDeptId"
                rules={[{required: true, message: "请选择上级机构"}]}
              >
                <Select
                  showSearch
                  placeholder="请输入关键字搜索"
                  allowClear={true}
                  filterOption={false}
                  onSearch={handleSearch}
                  options={(fatherDeptDict || []).map(d => ({
                    value: d.id,
                    label: d.name,
                  }))}
                />
              </Form.Item>
            </Col>) : null}
            {operationMode? ( <Col className="gutter-row" span={12}>
            <Form.Item
              label="备案编号"
              validateFirst={true}
              name="filingsNumber"
              rules={industryCategoryData !== 2 ? [{required: true, message: "请输入备案编号"}] : []}
            >
              <Input
                allowClear={true}
                placeholder="请输入备案编号"
              />
            </Form.Item>
          </Col>) : null}
          {operationMode ? (<Col className="gutter-row" span={12}>
            <Form.Item
              label="备案有效期"
              validateFirst={true}
              name="deadlineForFilingValidity"
              rules={[{required: true, message: "请选择备案有效期"}]}
            >
              <DatePicker allowClear={true} format="YYYY-MM-DD"/>
              </Form.Item>
            </Col>) : null}
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="执照成立日期"
                name="registerTime"
                rules={[{required: true, message: "请输入执照成立日期"}]}
              >
                <DatePicker allowClear={true} format={'YYYY-MM-DD'}/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="机构住所"
                name="registerAddress"
                rules={[{required: true, message: "请输入机构住所"}]}
              >
                <Input
                  allowClear={true}
                  placeholder="请输入机构住所"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="经营范围"
                validateFirst={true}
                name="businessScope"
                rules={[{required: true, message: "请输入经营范围"}]}
              >
                <Input
                  allowClear={true}
                  placeholder="请输入经营范围"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={23}>
              <Form.Item
                  label="营业地址坐标"
                  validateFirst={true}
                  name="businessAddressCoordinate"
                  rules={[{required: true, message: "请选择营业地址坐标"}]}
              >
                <Input
                    disabled
                    placeholder="请在后方选择营业地址地图坐标"
                />
              </Form.Item>
            </Col>
            <Col span={1}>
              <Button icon={<SendOutlined />} onClick={mapClick}/>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="营业地址"
                validateFirst={true}
                name="businessAddress"
                rules={[{required: true, message: "请输入营业地址"}]}
              >
                <Input
                  placeholder="请输入营业地址"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="法人姓名"
                validateFirst={true}
                name="legalPersonName"
                rules={[{required: true, message: "请输入法人姓名"},
                  ({getFieldValue}) => ({
                    validator(_, value) {
                      if (!rules_vertify.NAME_REX.test(value)) {
                        return Promise.reject(new Error('请输入正确姓名'));
                      }
                      return Promise.resolve();
                    },
                  })]}
              >
                <Input
                  allowClear={true}
                  placeholder="请输入法人姓名"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="身份证号码"
                validateFirst={true}
                name="idCard"
                validateTrigger="onBlur"
                rules={[{required: true, message: "请输入身份证号码"},
                  ({getFieldValue}) => ({
                    validator(_, value) {
                      if (!rules_vertify.ID_CARD_NUMBER.test(value)) {
                        return Promise.reject(new Error('身份证号码错误'));
                      }
                      return Promise.resolve();
                    },
                  })]}
              >
                <Input
                  allowClear={true}
                  placeholder="请输入身份证号码"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="法人手机号"
                validateTrigger="onBlur"
                validateFirst={true}
                name="phone"
                rules={[{required: true, message: "请输入法人手机号"},
                  ({getFieldValue}) => ({
                    validator(_, value) {
                      if (!rules_vertify.PHONE_NUM.test(value)) {
                        return Promise.reject(new Error('手机号格式错误'));
                      }
                      return Promise.resolve();
                    },
                  })]}
              >
                <Input
                  allowClear={true}
                  placeholder="请输入法人手机号"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="联系人"
                name="contactPeople"
                rules={[{required: true, message: "请输入联系人"},
                  ({getFieldValue}) => ({
                    validator(_, value) {
                      if (!rules_vertify.NAME_REX.test(value)) {
                        return Promise.reject(new Error('请输入正确姓名'));
                      }
                      return Promise.resolve();
                    },
                  })]}
              >
                <Input
                  allowClear={true}
                  placeholder="请输入联系人"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="联系人手机号"
                name="complaintsHotline"
                validateTrigger="onBlur"
                rules={[{required: true, message: "请输入联系人手机号"},
                  ({getFieldValue}) => ({
                    validator(_, value) {
                      if (!rules_vertify.PHONE_NUM.test(value)) {
                        return Promise.reject(new Error('联系电话格式错误'));
                      }
                      return Promise.resolve();
                    },
                  })]}
              >
                <Input
                  allowClear={true}
                  placeholder="请输入联系人手机号"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="账号"
                name="account"
                rules={[{required: true, message: "请输入账号"}]}
              >
                <Input
                  allowClear={true}
                  disabled={true}
                  placeholder="请输入账号"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24} style={{marginBottom: '20px'}}>
              <span className="title">相关证件</span>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="法人身份证正反面"
                className="businessLicense"
                name="idCardImageList"
                rules={[{required: true, message: "请上传法人身份证正反面", validator: checkUser}]}
              >
                <Upload
                  fileList={idCardImageList}
                  beforeUpload={beforeUpload}
                  onPreview={(file) => handlePreview("idCardImageList", file)}
                  onRemove={(file) =>
                    delImg(file, "idCardImageList", {
                      fileData: idCardImageList,
                      fileMeth: setidCardImageList,
                    })
                  }
                  listType="picture-card"
                >
                  {idCardImageList.length >= 2 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="企业营业执照副本"
                className="businessLicense"
                name="licenseImageList"
                rules={[{required: true, message: "请上传企业营业执照副本", validator: checkUser}]}
              >
                <Upload
                  fileList={licenseImageList}
                  beforeUpload={licenseImageListBeforeUpload}
                  onPreview={(file) => handlePreview("licenseImageList", file)}
                  onRemove={(file) =>
                    delImg(file, "licenseImageList", {
                      fileData: licenseImageList,
                      fileMeth: setlicenseImageList,
                    })
                  }
                  listType="picture-card"
                >
                  {licenseImageList.length >= 2 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="机构备案证书"
                className="businessLicense"
                name="othersImageList"
                rules={industryCategoryData !== 2 ? [{required: true, message: "请上传机构备案证书", validator: checkUser}] : []}
              >
                <Upload
                  fileList={othersImageList}
                  beforeUpload={othersImageListBeforeUpload}
                  onPreview={(file) => handlePreview("othersImageList", file)}
                  onRemove={(file) =>
                    delImg(file, "othersImageList", {
                      fileData: othersImageList,
                      fileMeth: setothersImageList,
                    })
                  }
                  listType="picture-card"
                >
                  {othersImageList.length >= 2 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="店内经营场所照片"
                className="businessLicense"
                name="certificateOfFixedBusinessPremises"
                rules={[{required: true, message: "请上传机构店内经营场所照片", validator: checkUser}]}
              >
                <Upload
                  fileList={certificateOfFixedBusinessPremisesImageList}
                  beforeUpload={certificateOfFixedBusinessPremisesImageListBeforeUpload}
                  onPreview={(file) => handlePreview("certificateOfFixedBusinessPremisesImageList", file)}
                  onRemove={(file) =>
                    delImg(file, "certificateOfFixedBusinessPremisesImageList", {
                      fileData: certificateOfFixedBusinessPremisesImageList,
                      fileMeth: setCertificateOfFixedBusinessPremisesImageList,
                    })
                  }
                  listType="picture-card"
                >
                  {certificateOfFixedBusinessPremisesImageList.length >= 1 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="店外经营场所照片"
                className="businessLicense"
                name="photosOfBusinessPremises"
                rules={[{required: true, message: "请上传机构店外经营场所照片", validator: checkUser}]}
              >
                <Upload
                  fileList={photosOfBusinessPremisesImageList}
                  beforeUpload={photosOfBusinessPremisesImageListBeforeUpload}
                  onPreview={(file) => handlePreview("photosOfBusinessPremisesImageList", file)}
                  onRemove={(file) =>
                    delImg(file, "photosOfBusinessPremisesImageList", {
                      fileData: photosOfBusinessPremisesImageList,
                      fileMeth: setPhotosOfBusinessPremisesImageList,
                    })
                  }
                  listType="picture-card"
                >
                  {photosOfBusinessPremisesImageList.length >= 1 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item style={{display: "flex", justifyContent: "center"}}>
            <Button
              type="primary"
              htmlType="submit"
              style={{width: '150px', height: '35px'}}
              loading={btnLoading}>
              提交
            </Button>
            <Button
              htmlType="submit"
              style={{width: '150px', height: '35px', marginLeft: '20px'}}
              onClick={onBack}>
              返回
            </Button>
          </Form.Item>
        </Form>
      </Spin>
      <BrandMode brandMode={brandMode}
                 setBrandMode={setBrandMode}
                 setBrandData={setBrandDataForm}
                 brandDict={brandDict}
      ></BrandMode>
      <Modal open={showPreview} title="预览图片" footer={null} onCancel={handleCancel}>
        <img alt="example" style={{width: '100%'}} src={perviewImg}/>
      </Modal>
      {mapDisplay ? (
          <Amap mapDisplay={mapDisplay} setmapDisplay={setmapDisplay}
                AddressOK={businessAddressOK}
          >
          </Amap>
      ) : null
      }
    </div>
  );
};
