import React from 'react';
import ReactDOM from 'react-dom';
import App from './views/App';
import { ConfigProvider } from 'antd';
import locale from 'antd/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import 'moment/locale/zh-cn';
import store from './store/platform/index'
import { Provider } from 'react-redux'
dayjs.locale('zh-cn');
ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={locale}>
      <App />
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
);
