import axios from "axios";
import { message as antdMessage } from 'antd';
// http request 拦截器
axios.interceptors.request.use(function (config) {
  config.headers.token = window.sessionStorage.getItem("token");
  // config.headers.token = 'rRF9EKZtNRJ8x59tVMUu7I/LQs2cW/Bd2OeAwz7gpERPWTH4yXyXjR6CtgMTw9Y3bVuOpeTDTSScrUMDXxUm5SWbMbK0+G7CkUTHqtKCIHj7EZYkESCiIzVT1nbsPDcZ/giafVsXQrjZszpaEfY+KCv5thzK/EmgI+nFmW5tJw+nRBb6V0+6jx9uwVwy0VqpcgwEY45M5EN2TZ42KF8o4CTvt9u4bgYodz71uwoHvTcJzV6ato+rcRd3ATDow7HtX0piYq5uK7y6kAZLlfPpnWEpKs5Wp4Mir7NSSHZLaoki+aJTGHGHOD2dIsvtdV/THnrskG7UNP/mEy979FgZbA==';
  // 在发送请求之前做些什么
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// http response 拦截器
axios.interceptors.response.use(
  response => {
    let {message,result,statusCode} = response.data
    if (statusCode === 1000) {
      return result
    }else {
      if (message ==='请登录!' || statusCode === 435011) {
        sessionStorage.setItem("isLogin", 'false');
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("info");
        if (window.logout) {
          window.logout()
        }
      }
      statusCode === 500 ? antdMessage.warning(message): antdMessage.error(message);
      return Promise.reject({
        message,
        statusCode,
        result
      })
    }
  },
  error => {
    antdMessage.error('axios报错')
    // antdMessage.error(error)
    return Promise.reject(error)
  }
)
export default axios;
