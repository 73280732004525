import '../../styles/BehaviorRecords/BehaviorRecords.scss'
import {Button, Input, Tabs} from "antd";
import {useState} from "react";
import EmployeePublicity from "./Employee"; // 从业人员公示
import CorporatePublicity from "./Corporate"; // 机构信息公示
export default () => {
  const [key, setKey] = useState(1);// tabs切换
  const [searchName,setSearchName] = useState('') // 搜索名字
  const [isSearch,setIsSearch] = useState(false) // 搜索名字
  const items = [
    {
      key: 1,
      label: '从业人员公示',
    },
    {
      key: 2,
      label: '机构信息公示',
    },
  ];
  const onChange = (key) => {
    setSearchName('')
    setKey(key)
  };
  // 搜索框改变则赋值
  const onSearch = (value) => {
    setSearchName(value.target.value)
  }
  const getList = () => {
    setIsSearch(!isSearch);
  }
  return (
    <div id="Publicity">
      <div className="util">
        <div className="left">
          <Tabs defaultActiveKey={1} items={items}  onChange={onChange} />
        </div>
        <div className="right">
          <Input  placeholder="输入关键字搜索" value={searchName} onChange={onSearch} onPressEnter={getList}/>
          <Button type="primary" onClick={getList}>搜索</Button>
        </div>
      </div>
      <div className="substance">
        { key === 1 ? <EmployeePublicity searchName={searchName} onSearch={isSearch}></EmployeePublicity> : null}
        { key === 2 ? <CorporatePublicity searchName={searchName} onSearch={isSearch}></CorporatePublicity> : null}
      </div>
    </div>
  );
};
