import { combineReducers } from 'redux';

import platformState from './plateform';

import permission from './permission';

const rootReducer = combineReducers({
    platformState,
    permission
})

export default rootReducer;