// 机构注册页面
import LoginPersonRegister from '../../component/Login/LoginPersonRegister'
import "../../styles/register/register.scss";
const Register = () => {
  return (
    <>
      <LoginPersonRegister />
    </>
  );
};
export default Register
