import { useState } from "react";
import '../../styles/ManagePage/Personnel/addPersonnelRegister.scss'
import RegisterForm from '../ManagePage/Enterprise/RegisterForm'
import back_grey from '../../assest/image/back_grey.png'
import {Steps} from 'antd'
import {useHistory} from "react-router-dom";
export default (prop) => {
  let history = useHistory();
  const [current, setCurrent] = useState(0);
  const [backSrc] = useState(back_grey);
  const onBack = () => {
    history.push({pathname: '/login',});
  }
  return (
    <div id="AddPersonnelRegister" className="LoginPersonRegister">
      <div className="addPersonelTop">
        <div className="left-back" onClick={onBack}>
          <img src={backSrc}></img>
        </div>
        <Steps
          type="navigation"
          size="small"
          current={current}
          className="site-navigation-steps"
          items={[
            {
              title: '账号注册',
            },
            {
              title: '完善资料',
            },
            {
              title: '资料审核',
            },
          ]}
        />
      </div>
      <div style={{height: '80px'}}></div>
      <RegisterForm setCurrent={setCurrent} current={current}></RegisterForm>
    </div>
  );
};
