import React from "react";
import Lock from "../assest/utils/lock.js";
import {changePermission} from "../store/platform/actionCreator";
import {connect} from "react-redux";
import mitt from "../assest/utils/mitt";
import {Switch, Route, Link} from "react-router-dom";
import SetPassword from "../component/Login/SetPassword";
import Personnel from "./ManagePage/Personnel";
import Card from "./ManagePage/Card";
import Enterprise from "./ManagePage/Enterprise";
import Error404 from "./error404";
import {Menu, Modal, message} from "antd";
import back_grey from "../assest/image/back_grey.png";
import person_icon from "../assest/image/person_icon.png";
import logo_img from "../assest/image/logo.png";
import {
  ExclamationCircleOutlined,
  AppstoreOutlined,
  ContactsOutlined,
  BankOutlined,
  TeamOutlined} from "@ant-design/icons";

import "../styles/ManagePage/Enterprise/home.scss";
const mapDispatchToProps = (dispatch) => {
  return {
    changePer(val) {
      // 设置权限
      let {buttonResourceVOList} = val;
      val.permissionObj = {};
      buttonResourceVOList &&
      buttonResourceVOList.length &&
      buttonResourceVOList.forEach(
        (el) => (val.permissionObj[el.value] = el.key)
      );
      dispatch(changePermission(val));
    },
  };
};
const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

class App extends React.Component {
  constructor(props) {
    super(props);
    window.logout = () => {
      props.history.push('/login')
    }
    this.state = {
      pathname: props.location.pathname,
      pageStash: [], // 页面栈
      backSrc: back_grey, // 返回图片src
      currentTitle: "", // 标题
      currItem: {}, // 当前选中tab
      permissionTabBar: [
        {
          title: "从业人员实名登记",
          code: '/managePage/Personnel',
          icon: TeamOutlined,
          routerName: "/managePage/Personnel",
        },
        {
          title: "企业登记复核",
          code: '/managePage/Enterprise',
          icon: BankOutlined,
          routerName: "/managePage/Enterprise",
        },
        {
          title: "登记卡进度",
          code: '/managePage/Card',
          icon: ContactsOutlined,
          routerName: "/managePage/Card",
        },
      ],
      defaultSelectedKeys: [], // 默认选中项
      redirectUrl: "", // 重定向路由
      platInfo: JSON.parse(sessionStorage.getItem('info')), // 平台信息
      lockVisible: false, //锁定弹窗
      selectedKeys: [], // tab选中项
      signOutLoding: false, //锁定弹窗中退出登录按钮loading
      resetPassShow: false, // 重置密码弹窗,
    };
  }

  // 返回
  onBack = () => {
    let {currItem} = this.state;
    let pageArr = JSON.parse(JSON.stringify(this.state.pageStash));
    mitt.emit("onBack", pageArr[pageArr.length - 1].page);
    pageArr.pop();
    let title = pageArr.length
      ? pageArr[pageArr.length - 1].pageTitle
      : currItem.title;
    this.setState({
      pageStash: [...pageArr],
      backSrc: back_grey,
      currentTitle: title,
    });
  };
  onSignOut = async () => {
    this.setState({
      signOutLoding: true,
    });
    try {
      this.setState({
        signOutLoding: false,
      });
      sessionStorage.setItem("isLogin", 'false');
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("info");
      this.props.history.replace({pathname: "/login"});
    } catch (error) {
      this.setState({
        signOutLoding: false,
      });
      message.error(error.message);
    }
  };
  tabRender = () => {
    let {permissionTabBar} = this.state;
    return permissionTabBar.map((el) => {
      const IconName = el.icon;
      return (
        <Menu.Item key={el.code}>
          <Link to={el.routerName}>
            <IconName></IconName>
            <span>{el.title}</span>
          </Link>
        </Menu.Item>
      );
    });
  };
  onSetLockVisible = (flag) => {
    this.setState({
      lockVisible: flag,
    });
  };
  // 打开锁屏弹窗回调事件
  onLocking = () => {
    sessionStorage.setItem("isLock", true);
    this.setState({
      lockVisible: true,
    });
  };
  onUnlock = () => {
    this.setState({
      lockVisible: false,
    });
    Lock.addEventFunc(this.onLocking);
  };
  // 登录二次确认弹窗
  onLoginOut = () => {
    Modal.confirm({
      title: "退出登录！",
      icon: <ExclamationCircleOutlined/>,
      content: "确定要退出登录吗？",
      okButtonProps: {
        type: "primary",
        danger: "danger",
      },
      onOk: () => {
        this.onSignOut();
      },
    });
  };

  // 更新完密码
  onFinishPass = () => {
    this.setState({
      resetPassShow: false,
    });
  };

  render() {
    let {
      tabRender,
      onBack,
      onLoginOut
    } = this;
    let {
      resetPassShow,
      backSrc,
      pageStash,
      currentTitle,
      pathname,
      platInfo,
    } = this.state;
    return (
      <div className="home" id="home">
        {(
          <div className="container">
            <div className="base-right">
              <div className="sideR-top">
                <div className="menu-logo">
                  <img src={logo_img}/>
                  <div className="platformName">唐山市房地产中介行业人员实名登记系统</div>
                </div>
                <div className="tit-back">
                  {pageStash.length ? (
                    <div
                      className="left-back"
                      onClick={onBack}
                    >
                      <img src={backSrc}></img>
                    </div>
                  ) : null}
                  <span>{currentTitle}</span>
                </div>
                <div className="login-out">
                  <div>
                    <img src={person_icon}/>
                    <span>{platInfo ? platInfo.archivesName : null}</span>
                  </div>
                  <div className="sign_out" onClick={onLoginOut}>
                    <div className="sign_out_icon"></div>
                    <span>退出登录</span>
                  </div>
                </div>
              </div>
              <div className="sideR-bottom">
                <div className="body-left">
                  <div style={{height: "1.5%"}}></div>
                  <div className="menu-top">
                    <span>企业端</span>
                    <AppstoreOutlined></AppstoreOutlined>
                  </div>
                  <div style={{height: "1.5%"}}></div>
                  <Menu
                    mode="inline"
                    className="plate-menu"
                    defaultSelectedKeys={[pathname]}
                  >
                    {tabRender()}
                  </Menu>
                </div>
                <div className="body-right">
                  <div style={{height: "1.5%"}}></div>
                  <div className="body-page">
                    <Switch>
                      <Route
                        exact
                        path="/managePage/Personnel"
                        component={Personnel}
                      />
                      <Route
                        exact
                        path="/managePage/Enterprise"
                        component={Enterprise}
                      />
                      <Route
                        exact
                        path="/managePage/Card"
                        component={Card}
                      />
                      <Route
                        exact
                        path="/managePage/page404/"
                        component={Error404}
                      />
                      {/*<Redirect to={redirectUrl}/>*/}
                    </Switch>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/*<Modal*/}
        {/*  centered*/}
        {/*  closable={false}*/}
        {/*  keyboard={false}*/}
        {/*  destroyOnClose={true}*/}
        {/*  maskClosable={false}*/}
        {/*  visible={lockVisible}*/}
        {/*  onOk={() => onSetLockVisible(false)}*/}
        {/*  onCancel={() => onSetLockVisible(false)}*/}
        {/*  width={480}*/}
        {/*  footer={null}*/}
        {/*>*/}
        {/*  <LockDialogContent*/}
        {/*    platInfo={platInfo}*/}
        {/*    onSignOut={onSignOut}*/}
        {/*    onUnlock={onUnlock}*/}
        {/*    signOutLoding={signOutLoding}*/}
        {/*  ></LockDialogContent>*/}
        {/*</Modal>*/}
        <Modal
          visible={resetPassShow}
          closable={false}
          destroyOnClose={true}
          maskClosable={false}
          footer={null}
          width={480}
        >
          {resetPassShow ? (
            <SetPassword onFinishPass={this.onFinishPass}></SetPassword>
          ) : null}
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
