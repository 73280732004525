import '../../styles/BehaviorRecords/BehaviorRecords.scss'
import {Button, Input, Table, Tabs} from "antd";
import {useState} from "react";
import BadBehavior from "./BadBehavior";
import GoodBehavior from "./goodBehavior";
import Blacklist from "./blacklist";
export default () => {
  const [key, setKey] = useState(3);// tabs切换
  const [searchName,setSearchName] = useState('') // 搜索名字
  const [isSearch,setIsSearch] = useState(false) // 搜索名字
  const items = [
    {
      key: 3,
      label: '黑名单',
    },
    {
      key: 2,
      label: '不良行为',
    },
    {
      key: 1,
      label: '良好行为',
    },
  ];
  const onChange = (key) => {
    setSearchName('');
    setKey(key);
  };
  // 搜索框改变则赋值
  const onSearch = (value) => {
    setSearchName(value.target.value)
  }
  const getList = () => {
    setIsSearch(!isSearch);
  }
  return (
    <div id="behavior">
      <div className="util">
        <div className="left">
          <Tabs defaultActiveKey={3} items={items}  onChange={onChange} />
        </div>
        <div className="right">
          <Input  placeholder="输入关键字搜索" value={searchName} onChange={onSearch} onPressEnter={getList}/>
          <Button type="primary" onClick={getList}>搜索</Button>
        </div>
      </div>
      <div className="substance">
        { key === 1 ? <GoodBehavior searchName={searchName} onSearch={isSearch}></GoodBehavior> : null}
        { key === 2 ? <BadBehavior searchName={searchName} onSearch={isSearch}></BadBehavior> : null}
        { key === 3 ? <Blacklist searchName={searchName} onSearch={isSearch}></Blacklist> : null}
      </div>
    </div>
  );
};
