import {useState, useEffect} from "react";
import {Form, Input, Button, Checkbox, message as antdMessage, Modal, Col, Row} from "antd";
import VertigyCode from '../public/vertigyCode'
import "../../styles/login/loginForm.scss";
import loginInstan from '../../service/login/login'
import {useHistory} from "react-router-dom";
import register from "../../service/register/register";

export default (prop) => {
  let history = useHistory();
  const {activeKeyLogin, loginSuccess} = prop; // 2 人员登录  1 机构登录
  const [verifiyCode, setVerifiyCode] = useState('');
  // 忘记密码弹窗
  const [forgetPaw, setForgetPaw] = useState(false);
  // 忘记密码弹窗表单数据
  const [forgetPawForm] = Form.useForm();
  // 忘记密码第二步弹窗
  const [forgetPawStep, setForgetPawStep] = useState(false);
  // 验证码倒计时
  const [countDown, setCountDown] = useState(60);

  // 忘记密码第二步弹窗表单数据
  const [forgetPawStepForm] = Form.useForm();

  //忘记密码个人信息
  const [forgetPawPersonal,setForgetPawPersonal] = useState({name: '',phone: '',account:null});

  const [newForm] = Form.useForm();
  useEffect(() => {
    changeTab()
  }, [activeKeyLogin]);
  const changeTab = () => {
    newForm.resetFields()
  }
  // 校验不通过回调
  const onFinishFailed = (errorInfo) => {
  };
  /**
   * @name  登录成功回调
   * @description
   * @params {}
   * @return { void }
   * @author WangDL
   * @version 2023/11/10 16:03
   */
  const onFinish = async (values) => {
    try {
      // 登录
      let data = await loginInstan.logon(values);
      loginSuccess(data)
    } catch (error) {
      console.log(error);
    }
  };
  const changCode = (code) => {
    setVerifiyCode(code)
  }
  const onRegister = () => {
    history.push({pathname: '/register',});
  }
  const onForgotPaw = () => {
    setForgetPaw(true)
  }
  const onForgetPawFinish = async (values) => {
    let res = await loginInstan.forgotPasswordCheck(values);
    if (!res) return
    setForgetPawPersonal(res);
    // let data = {
    //   name: '测试人',phone: '15028559558'
    // }
    // setForgetPawPersonal(data)
    setForgetPaw(false);
    setForgetPawStep(true);
    forgetPawForm.resetFields();
    // 开始倒计时
    setCountDown(60);
  }

  const maskMiddleName = (name) => {
    if (name.length === 2) {
      return name[0] + '*';
    } else if (name.length > 2) {
      return name[0] + '*'.repeat(name.length - 2) + name[name.length - 1];
    }
    return name;
  }
  const maskMiddleFourDigits = (phone) => {
    return phone.replace(/(\d{3})\d{4}(\d{4})/, '$1xxxx$2');
  }

  // 忘记密码保存
  const onforgetPawStepFinish = async (values) => {
    try {
      await loginInstan.forgotPassword({...values,account:forgetPawPersonal.account});
      // 修改成功提示
      antdMessage.success('修改成功！');
      setForgetPawStep(false);
      forgetPawStepForm.resetFields();
    } catch (err) {
      console.log(err);
    }
  };

  // 发送验证码
  const countDownClick = async () => {
    if (countDown === 0) {
      await register.sendVerificationCode({phone: forgetPawPersonal.phone});
      setCountDown(60);
    }
  };
  useEffect(() => {
    // 当倒计时大于0时，每秒减少1
    if (countDown > 0) {
      const timer = setTimeout(() => setCountDown(countDown - 1), 1000);
      // 清除定时器
      return () => clearTimeout(timer);
    }
  }, [countDown]);

  const forgetPawStepCancel = () => {
    setForgetPawStep(false);
    forgetPawStepForm.resetFields();
  };
  const forgetPawCancel = () => {
    setForgetPaw(false);
    forgetPawForm.resetFields();
  }
  return (
      <div id="loginForm">
        <Form
            name="basic"
            form={newForm}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            colon={false}
            autoComplete="off"
        >
          <Form.Item
              label="用户名"
              validateFirst={true}
              name="account"
              rules={[{required: false, message: "请输入用户名"}, {
                validator: (_, value) => {
                  // 使用正则表达式来校验密码 6-12位 字母或者数字组合
                  const passwordPattern = /^[0-9a-zA-Z]{6,12}$/;
                  if (!passwordPattern.test(value)) {
                    return Promise.reject('请输入6-12位字母或数字组合，区分大小写!');
                  }
                  return Promise.resolve();
                },
              },]}>
            <Input placeholder="请输入用户名"/>
          </Form.Item>
          <Form.Item
              label="密码"
              validateFirst={true}
              name="password"
              rules={[{required: false, message: "请输入密码"}, {
                validator: (_, value) => {
                  // 使用正则表达式来校验密码 6-12位 字母或者数字组合
                  const passwordPattern = /^[0-9a-zA-Z]{6,12}$/;
                  if (!passwordPattern.test(value)) {
                    return Promise.reject('请输入6-12位字母或数字组合，区分大小写!');
                  }
                  return Promise.resolve();
                },
              },]}
          >
            <Input.Password placeholder="请输入密码"/>
          </Form.Item>
          <Form.Item
              label="验证码"
              validateFirst={true}
              name="code1"
              rules={[{required: false, message: "请输入动态密码"},
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (verifiyCode !== getFieldValue('code1')) {
                      return Promise.reject(new Error('输入错误'));
                    }
                    return Promise.resolve();
                  },
                }),]}>
            <div className="form-row">
              <Input placeholder="请输入验证码"/>
              <div className='vertigyCode'>
                <VertigyCode changCode={changCode}></VertigyCode>
              </div>
            </div>
          </Form.Item>
          <Form.Item>
            <div style={{
              width: '100%', display: "flex", alignItems: "center",
              justifyContent: "space-between", boxSizing: "border-box", paddingLeft: '60px'
            }}>
              <div onClick={onForgotPaw}>
                <span className="register-text">忘记密码</span>
              </div>
              <div onClick={onRegister}>
                <span className="register-text"><span style={{color: "black"}}>没有账号？</span>立即注册</span>
              </div>
            </div>
          </Form.Item>
          <Form.Item>
            <div style={{width: '100%', display: "flex", alignItems: "center", justifyContent: "center"}}>
              <Button type="primary" htmlType="submit" shape='round'>
                登录
              </Button>
            </div>
          </Form.Item>
        </Form>

        {/* 忘记密码弹窗 */}
        <Modal title="忘记密码" visible={forgetPaw} onCancel={forgetPawCancel}
               footer={null}
        >
          <Form
              style={{marginTop: '30px'}}
              name="basic"
              form={forgetPawForm}
              onFinish={onForgetPawFinish}
              labelCol={{span: 4}}
              wrapperCol={{span: 20}}
              colon={false}
              autoComplete="off"
          >
            <Form.Item
                label="机构名称"
                validateFirst={true}
                name="archivesName"
                rules={[{required: true, message: "请输入机构名称"}]}>
              <Input placeholder="请输入机构名称"/>
            </Form.Item>
            <Form.Item
                label="账号"
                validateFirst={true}
                name="account"
                rules={[{required: true, message: "请输入账号"}]}>
              <Input placeholder="请输入账号"/>
            </Form.Item>
            <Form.Item labelCol={{span: 0}} wrapperCol={{span: 24}} style={{display: "flex", justifyContent: "center"}}>
                <Button type="primary" htmlType="submit">下一步</Button>
            </Form.Item>
          </Form>
        </Modal>

        {/*  忘记密码第二步弹窗 */}
        <Modal title="忘记密码" visible={forgetPawStep} onCancel={forgetPawStepCancel} footer={null}>
          <Form
              name="basic"
              style={{marginTop: '20px'}}
              form={forgetPawStepForm}
              onFinish={onforgetPawStepFinish}
              onFinishFailed={onFinishFailed}
              labelCol={{span: 4}}
              wrapperCol={{span: 20}}
              colon={false}
              autoComplete="off"
          >
            <div style={{paddingLeft: '20px',color: 'rgb(245, 154, 35)',marginBottom: "20px"}}>
              *已发送短信验证码至 {maskMiddleName(forgetPawPersonal.name)} {maskMiddleFourDigits(forgetPawPersonal.phone)} 手机上
            </div>
            <Form.Item
                label="验证码"
                validateFirst={true}
                name="verificationCode"
                rules={[{required: true, message: "请输入账号"}]}>
              <div style={{display:"flex"}}>
                <Input placeholder="请输入账号" style={{width: '100%'}}/>
                <Button type="primary" disabled={countDown !== 0} style={{marginLeft: '10px'}} onClick={countDownClick}>重新发送{countDown !== 0 ? "(" + countDown + ")" : ""}</Button>
              </div>
            </Form.Item>
            <Form.Item
                label="密码"
                validateFirst={true}
                name="password"
                rules={[{required: true, message: "请输入新密码"}, {
                  validator: (_, value) => {
                    // 使用正则表达式来校验密码 6-12位 字母或者数字组合
                    const passwordPattern = /^[0-9a-zA-Z]{6,12}$/;
                    if (!passwordPattern.test(value)) {
                      return Promise.reject('请输入6-12位字母或数字组合，区分大小写!');
                    }
                    return Promise.resolve();
                  },
                }]}>
              <Input.Password placeholder="请输入新密码"/>
            </Form.Item>
            <Form.Item
                label="确认密码"
                name="confirmPassword"
                dependencies={['password']} // 修改密码时从新校验
                validateTrigger="onBlur"
                rules={[{required: true, message: "请确认密码"},
                  ({getFieldValue}) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject('两次密码不一致,请重新输入!');
                    },
                  })]}
            >
              <Input.Password
                  allowClear={true}
                  placeholder="请确认密码"
              />
            </Form.Item>
            <Form.Item labelCol={{span: 0}} wrapperCol={{span: 24}} style={{display: "flex", justifyContent: "center"}}>
              <Button onClick={() => setForgetPawStep(false)}>取消</Button>
              <Button style={{marginLeft: '20px'}} type="primary" htmlType="submit">确认修改</Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
  );
};
