import React, {useState, useEffect, useRef} from "react";
import "../../../styles/ManagePage/Personnel/downloadCard.scss";
import registration_logo from '../../../assest/image/registration_logo.png'
import watermark_img from '../../../assest/image/watermark.png'
import practitionersRegistration from "../../../service/ManagePage/Personnel/Personnel";
import QRCode from 'qrcode.react'
import {Spin} from "antd";
export default (prop) => {
  const {inDeptId,id,watermark} = prop;
  const [details, setDetails] = useState({});
  const [show, setShow] = useState(false);
  useEffect(() => {
    getDetails();
  }, [inDeptId,id]);
  const getDetails =  async () => {
    setShow(false)
    let res = await practitionersRegistration.peopleCardDetails(id)
    let memberTypeRes = await practitionersRegistration.firmInfo({inDeptId})
    if(memberTypeRes) {
      // 是否显示下方 logo和文字
      res.memberType = memberTypeRes.memberType || 1;
      // 行业类型是否==2(房地产开发)
      res.downBool = Boolean(memberTypeRes.industryCategoryId === 2)
    }
    setDetails(res);
    setTimeout(()=> {
      setShow(true)
    },1000);
  };
  // 1为agent 2为associate 3为business 4为develop
  const map = new Map([
      [1, "agent"],
      [2, "associate"],
      [3, "business"],
      [4, "develop"]
  ]);
  return (
      <div id="printTemplate" className={details.downBool ? 'develop' : map.get(details.postType)}>
        {
          show ? <div>
                <img className="avatar_head" src={details.head} alt=""/>
                <div className="img-box">
                  {
                    details.memberType !== '非会员' ? <img src={registration_logo} alt=""/> :
                        <div style={{height: '79px'}}></div>
                  }
                  <QRCode style={{width: '65px', height: '65px'}} value={`https://treamp.tsfzx.com/appraise/${id}`}/>
                </div>
                <div className="information">
                  <div>{details.name}</div>
                  <div style={{marginTop: '10px'}}>{details.cardNumber || 'XXXXXXXXXXX'}</div>
                  <div style={{marginTop: '10px'}}></div>
                  <div style={{marginTop: '10px'}}><img src={details.iconAddress} alt=""/></div>
                  <div style={{marginTop: '10px'}}>{details.inDeptName}</div>
                </div>
                {watermark ? <img src={watermark_img} className="watermark" alt=""></img> : null}
              </div>
              : <div style={{width: '100%', display: "flex",justifyContent: "center",alignItems:"center",height: '100%'}}>
                <Spin size="large"/>
              </div>
        }
      </div>
  );
};
