import { Result } from 'antd';
export default () => {
  return (
    <div>
      <Result
        status="404"
        title="暂无权限或页面丢失"
        subTitle="Sorry, the page you visited does not exist."
      />
    </div>
  )
}
