// 机构详情 H5
import React, {useState, useEffect, useRef} from "react";
import '../../styles/Details_H5/InstitutionalDetails.scss'
import {Col, Divider, Rate, Row, Spin, Image, Empty} from "antd";
import { message as antdMessage } from 'antd';
import Details from "../../service/Details/InstitutionalDetails.js";
import member_img from "../../assest/image/member.png";
import normal from "../../assest/image/normal.png";
import abnormal from "../../assest/image/abnormal.png";

export default (props) => {
  const pageInfoBlackList = useRef({page: 1, size: 20});
  const pageInfoBadBehavior = useRef({page: 1, size: 20});
  const pageInfoGoodBehavior = useRef({page: 1, size: 20});
  const {id} = props;
  // 机构详情数据
  const [detail, setDetail] = useState({});
  // 详情加载数据
  const [detailShow, setDetailShow] = useState(false);
  // 黑名单列表数据
  const [blackList, setBlackList] = useState([]);
  // 不良行为列表数据
  const [badBehavior, setBadBehavior] = useState([]);
  // 良好行为列表数据
  const [goodBehavior, setGoodBehavior] = useState([]);

  // 获取详情
  useEffect(() => {
    getDetail()
    getBlackList()
  }, [])

  const getDetail = async () => {
    setDetailShow(false)
    try {
      let res = await Details.institutionDetail({institutionId: id})
      setDetail(res)
      setDetailShow(true)
    } catch (e) {
      antdMessage.error(JSON.stringify(e))
    }
  }

  // 获取行为记录
  const getBlackList = async () => {
    let res = await Details.behaviorRecords(pageInfoBlackList.current.page, pageInfoBlackList.current.size, {
      behaviorTypeId: 110, // 行为类型 行为类型(黑名单:110,不良行为:111,良好行为:112)
      institutionId: id,
    })
    setBlackList(res.list)
    res = await Details.behaviorRecords(pageInfoBadBehavior.current.page, pageInfoBadBehavior.current.size, {
      behaviorTypeId: 111, // 行为类型 行为类型(黑名单:110,不良行为:111,良好行为:112)
      institutionId: id,
    })
    setBadBehavior(res.list)
    res = await Details.behaviorRecords(pageInfoGoodBehavior.current.page, pageInfoGoodBehavior.current.size, {
      behaviorTypeId: 112, // 行为类型 行为类型(黑名单:110,不良行为:111,良好行为:112)
      institutionId: id,
    })
    setGoodBehavior(res.list)
  }

  return (
      <div id="InstitutionalDetailsH5">
        {
          detailShow ? <div>
            <header>机构登记备案档案</header>
            <main>
              <div className="cardDetails">
                <h4 className="title">{detail.archivesName}</h4>
                <Row>
                  <Col span={24} style={{display: "flex",alignItems: "center"}}>诚信星级
                    <Rate defaultValue={detail.star} disabled/>
                    <div>
                      {
                        detail.statusName === '正常' ?
                          <span className="abnormal">
                            <img src={normal} alt=""/>
                            正常
                          </span> :
                          <span className="normal">
                            <img src={abnormal} alt=""/>
                            异常
                          </span>
                      }
                    </div>
                  </Col>
                  <Col span={24}>经营模式 <span style={{color: "black"}}>{detail.managementModelName}</span></Col>
                  <Col span={24}>注册资本<span style={{color: "black"}}>{detail.registerCapital}万元</span></Col>
                  <Col span={24}>法定代表人<span style={{color: "black"}}>{detail.legalPersonName}</span></Col>
                  <Col span={24}>成立日期<span style={{color: "black"}}>{detail.registerTime}</span></Col>
                  <Col span={24}>品牌<span style={{color: "black"}}>{detail.brandName}</span></Col>
                  <Col span={24}>联系人<span style={{color: "black"}}>{detail.contactPeople}</span></Col>
                  <Col span={24}>联系电话<span style={{color: "black"}}>{detail.complaintsHotline}</span></Col>
                  <Col span={24}>所属地区<span style={{color: "black"}}>{detail.administrativeArea}</span></Col>
                  <Col span={24}>登记备案有效期<span style={{color: "black"}}>{detail.deadlineForFilingValidity}</span></Col>
                  <Col span={24}>统一社会信用代码<span
                      style={{color: "black"}}>{detail.businessLicenseNumber}</span></Col>
                  <Col span={24}>登记备案编号<span
                      style={{color: "black"}}>{detail.recordCertificateNumber}</span></Col>
                  <Col span={24}>机构住所<span
                      style={{color: "black"}}>{detail.registerAddress}</span></Col>
                  <Col span={24}>经营范围<span
                      style={{color: "black"}}>{detail.businessScope}</span></Col>
                  <Col span={24}>
                    <Image
                        style={{display: "block", margin: "auto"}}
                        src={'https://qjprod-images.oss-cn-beijing.aliyuncs.com/tsfzx_institution/' + detail.certificateOfFixedBusinessPremises}
                        alt=""/>
                    <span style={{
                      width: '100%',
                      textAlign: "center",
                      display: "block",
                      marginTop: '10px',
                      marginLeft: '0'
                    }}>店内经营场所照片</span>
                  </Col>
                  <Col span={24}>
                    <Image
                        style={{display: "block", margin: "auto"}}
                        src={'https://qjprod-images.oss-cn-beijing.aliyuncs.com/tsfzx_institution/' + detail.photosOfBusinessPremises}
                        alt=""/>
                    <span style={{
                      width: '100%',
                      textAlign: "center",
                      display: "block",
                      marginTop: '10px',
                      marginLeft: '0'
                    }}>店外经营场所照片</span>
                  </Col>
                </Row>
                {
                  detail.memberName !== '非会员' ? < img className="member" src={member_img} alt=""/> : null
                }
              </div>
              <div className="cardDetails" style={{marginTop: '10px'}}>
                <h4 className="title">行为记录</h4>
                <div style={{textAlign: "center", marginTop: '20px', fontSize: '0.8rem'}}>
                  黑名单
                  <Divider style={{marginTop: '10px', marginBottom: '10px'}}/>
                </div>
                {
                  blackList.length !== 0 ? <div>
                    {
                      blackList.map(item => {
                        return <Row style={{marginTop: '10px'}}>
                          <Col className="grey table borderBottom" span={11}>机构名称</Col>
                          <Col className="grey table borderLeft borderBottom" span={13}>{item.name}</Col>

                          <Col className="table borderBottom" span={11}>统一社会信用代码</Col>
                          <Col className="table borderLeft borderBottom" span={13}>
                            {item.socialCreditCode}
                          </Col>

                          <Col className="grey table borderBottom" span={11}>认定机构</Col>
                          <Col className="grey table borderLeft borderBottom" span={13}>
                            {item.accreditationAgency}
                          </Col>

                          <Col className="table borderBottom" span={11}>诚信指标</Col>
                          <Col className="table borderLeft borderBottom" span={13}>
                            {item.name}
                          </Col>

                          <Col className="grey table " span={11}>禁业期限</Col>
                          <Col className="grey table borderLeft" span={13}>
                            {item.publicityTime}
                          </Col>
                        </Row>
                      })
                    }
                  </div> : <Empty description="暂无黑名单记录"/>
                }
                <div style={{textAlign: "center", marginTop: '20px', fontSize: '0.8rem'}}>
                  不良行为
                  <Divider style={{marginTop: '10px', marginBottom: '10px'}}/>
                </div>
                {
                  badBehavior.length !== 0 ? <div>
                    {
                      badBehavior.map(item => {
                        return <Row style={{marginTop: '10px'}}>
                          <Col className="grey table borderBottom" span={11}>机构名称</Col>
                          <Col className="grey table borderLeft borderBottom" span={13}>{item.name}</Col>

                          <Col className="table borderBottom" span={11}>统一社会信用代码</Col>
                          <Col className="table borderLeft borderBottom" span={13}>
                            {item.socialCreditCode}
                          </Col>

                          <Col className="grey table borderBottom" span={11}>认定机构</Col>
                          <Col className="grey table borderLeft borderBottom" span={13}>
                            {item.accreditationAgency}
                          </Col>

                          <Col className="table borderBottom" span={11}>诚信指标</Col>
                          <Col className="table borderLeft borderBottom" span={13}>
                            {item.name}
                          </Col>

                          <Col className="grey table " span={11}>公示时间</Col>
                          <Col className="grey table borderLeft" span={13}>
                            {item.publicityTime}
                          </Col>
                        </Row>
                      })
                    }
                  </div> : <Empty description="暂无不良行为记录"/>
                }
                <div style={{textAlign: "center", marginTop: '20px', fontSize: '0.8rem'}}>
                  良好行为
                  <Divider style={{marginTop: '10px', marginBottom: '10px'}}/>
                </div>
                {
                  goodBehavior.length !== 0 ? <div>
                    {
                      goodBehavior.map(item => {
                        return <Row style={{marginTop: '10px'}}>
                          <Col className="grey table borderBottom" span={11}>机构名称</Col>
                          <Col className="grey table borderLeft borderBottom" span={13}>{item.name}</Col>

                          <Col className="table borderBottom" span={11}>统一社会信用代码</Col>
                          <Col className="table borderLeft borderBottom" span={13}>
                            {item.socialCreditCode}
                          </Col>

                          <Col className="grey table borderBottom" span={11}>认定机构</Col>
                          <Col className="grey table borderLeft borderBottom" span={13}>
                            {item.accreditationAgency}
                          </Col>

                          <Col className="table borderBottom" span={11}>诚信指标</Col>
                          <Col className="table borderLeft borderBottom" span={13}>
                            {item.name}
                          </Col>

                          <Col className="grey table " span={11}>公示时间</Col>
                          <Col className="grey table borderLeft" span={13}>
                            {item.publicityTime}
                          </Col>
                        </Row>
                      })
                    }
                  </div> : <Empty description="暂无良好行为记录"/>
                }
              </div>
              <div></div>
            </main>
            <footer>
              <div>
                <span>地址: 唐山铭扬国际4层</span>
                <span className="footer_left">电话: 0315-8080089</span>
              </div>
              <div style={{
                marginTop: '10px',
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }} className="">
                <span>Copyright @ 2024 唐山市房地产中介服务行业协会 版权所有</span>
                <span style={{marginTop: '10px'}}>ICP备案编号: 冀ICP备2023040514</span>
              </div>
            </footer>
          </div> : <div
              style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Spin size="large"/>
          </div>
        }
      </div>
  );
};

