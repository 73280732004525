import employeePublicity from '../../../api/Publicity/Employee/employeePublicity'
class EmployeePublicity {
  // 创建单例
  static getInstance() {
    if(!this.enterpriseRecord) {
      this.enterpriseRecord = new EmployeePublicity()
    }
    return this.enterpriseRecord
  }
  // 获取表格数据
  peoplesPublicity(data) {
    return employeePublicity.peoplesPublicity(data)
  }
  // 表头
  peoplesPublicityTableHeader() {
    return employeePublicity.peoplesPublicityTableHeader()
  }
}
export default EmployeePublicity.getInstance()
