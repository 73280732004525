// 人员详情
import React, {useState, useEffect, useRef} from "react";
import '../../styles/Details_H5/PersonnelDetails.scss';
import {Col, Divider, Empty, Image, Modal, Rate, Row, Spin} from "antd";
import personnel from '../../assest/image/personnel.svg';
import mechanism from '../../assest/image/mechanism.svg';
import qualification from '../../assest/image/qualification.svg';
import mechanismInfo from '../../assest/image/mechanismInfo.svg';
import Details from "../../service/Details/PersonnelDetails";
import member_img from "../../assest/image/member.png";
import normal from "../../assest/image/normal.png";
import abnormal from "../../assest/image/abnormal.png";

export default (props) => {
  const {id} = props
  const pageInfoBlackList = useRef({page:1,size:20});
  const pageInfoBadBehavior = useRef({page:1,size:20});
  const pageInfoGoodBehavior = useRef({page:1,size:20});
  // 人员信息数据
  const [personnelInfo, setPersonnelInfo] = useState({});
  // 人员信息加载动画
  const [personnelLoading, setPersonnelLoading] = useState(false);
  const [recordCertificateOpen, setRecordCertificateOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // 黑名单列表数据
  const [blackList, setBlackList] = useState([]);
  // 不良行为列表数据
  const [badBehavior, setBadBehavior] = useState([]);
  // 良好行为列表数据
  const [goodBehavior, setGoodBehavior] = useState([]);
  useEffect(() => {
    getPersonnelDetail()
    getBlackList()
  }, [])

  // 获取人员详情
  const getPersonnelDetail = async () => {
    setPersonnelLoading(false)
    let res = await Details.peopleInfo({basicId: id})
    setPersonnelInfo(res)
    setPersonnelLoading(true)
  }
  const handleOk = () => {
    setRecordCertificateOpen(false);
    setIsModalOpen(false);
  }
  const handleCancel = () => {
    setRecordCertificateOpen(false);
    setIsModalOpen(false);
  }

  // 获取行为记录
  const getBlackList = async () => {
    let res = await Details.behaviorDetails({
      page: pageInfoBlackList.current.page,
      size: pageInfoBlackList.current.size,
      subjectType: 130,
      behaviorType: 110, // 行为类型 行为类型(黑名单:110,不良行为:111,良好行为:112)
      subjectId: id,
    })
    setBlackList(res.list)
    res = await Details.behaviorDetails({
      page: pageInfoBadBehavior.current.page,
      size: pageInfoBadBehavior.current.size,
      subjectType: 130,
      behaviorType: 111,
      subjectId: id,
    })
    setBadBehavior(res.list)
    res = await Details.behaviorDetails({
      page: pageInfoGoodBehavior.current.page,
      size: pageInfoGoodBehavior.current.size,
      subjectType: 130,
      behaviorType: 112,
      subjectId: id,
    })
    setGoodBehavior(res.list)
  }
  return (
      <div id="PersonnelDetailsH5">
        {
          personnelLoading ? <div>
            <header>从业人员个人档案</header>
            <main>
              <div className='cardDetails' style={{marginTop: '0px'}}>
                <h4 className="cardDetails_title">人员信息</h4>
                <Row gutter={10}>
                  <Col span={7} className="cardDetails_information_img">
                    <img
                        src={personnelInfo.photoUrl}
                        alt=""/>
                  </Col>
                  <Col span={13} className="cardDetails_information">
                    <h4>
                      {personnelInfo.name}
                      <div>
                        {
                          personnelInfo.processedState === '正常' ?
                              <span className="abnormal">
                                <img src={normal} alt=""/>
                                正常
                              </span> :
                              <span className="normal">
                                <img src={abnormal} alt=""/>
                                异常
                              </span>
                        }
                      </div>
                    </h4>
                    <div>
                      从业类型 <h5>{personnelInfo.postType}</h5>
                    </div>
                    <div>
                      诚信星级 <Rate disabled defaultValue={personnelInfo.star}/>
                    </div>
                  </Col>
                  <Col span={24} style={{fontSize: '0.9em', marginTop: '15px'}}>
                    资格证编号 <h4>{personnelInfo.qualificationsNumber}</h4>
                  </Col>
                  <Col span={24} style={{fontSize: '0.9em', marginTop: '15px'}}>
                    信息卡号 <h4>{personnelInfo.registrationNumber}</h4>
                  </Col>
                  <Col span={24} style={{fontSize: '0.9em', marginTop: '15px'}}>
                    从业机构 <h4>{personnelInfo.archivesName}</h4>
                  </Col>
                </Row>
                {personnelInfo.memberName !== "非会员" ?
                    <img className="member" style={{right: '10px', width: '80px'}} src={member_img} alt=""/> : null}
              </div>
              <div className='cardDetails'>
                <h4 className="cardDetails_title">证件照片</h4>
                <div className="certificates" style={ personnelInfo.cardUrl === "" ? {background: 'rgb(215, 215, 215)'} : null}>
                  <img src={personnel} alt=""/>
                  <h4 className="text">
                    人员实名登记卡
                  </h4>
                  {
                    personnelInfo.cardUrl !== "" ?
                        <span className="text_date">
                          <span style={{fontSize: '0.7rem'}}>有效期至</span> <br/> {personnelInfo.entryTime}
                        </span>
                        :
                        <span className="text_date">
                          <span style={{fontSize: '0.7rem'}}>暂无</span>
                        </span>
                  }
                  {
                    personnelInfo.cardUrl !== "" ?
                        <span className="text_click" onClick={() => setIsModalOpen(true)}>
                          点击查看
                        </span>
                        :
                        <span className="text_click">
                          暂无
                        </span>
                  }
                </div>
                <div className="certificates"
                     style={personnelInfo.postType === "业务员" ? {background: 'rgb(215, 215, 215)'} : null}>
                  <img src={mechanism} alt=""/>
                  <h4 className="text">
                    资格认证照片
                  </h4>
                  <span className="text_date">
                    暂无
                  </span>
                  <span className="text_click">
                    {personnelInfo.postType === "业务员" ? '暂无' : '已获取证书'}
                  </span>
                </div>
                <div className="certificates" style={{background: 'rgb(2, 167, 240)'}}>
                  <img src={qualification} alt=""/>
                  <h4 className="text">
                    机构登记备案
                  </h4>
                  <span className="text_date">
                    <span style={{fontSize: '0.7rem'}}>有效期至</span> <br/>2025-06-01
                  </span>
                  <span className="text_click" onClick={() => setRecordCertificateOpen(true)}>
                    点击查看
                  </span>
                </div>
                {/*<Row className="certificates_img">*/}
                {/*  <Col span={12}>*/}
                {/*    <img src="https://img02.mockplus.cn/preview/2024-06-17/nL1Lpvn3mw/48bb6f74-19fc-4f2f-b08e-88ed2108be39/images/%E9%80%82%E9%85%8D%E6%89%8B%E6%9C%BA%E7%AB%AF/u60.png" alt=""/>*/}
                {/*    <span>店内经营场所照片</span>*/}
                {/*  </Col>*/}
                {/*  <Col span={12}>*/}
                {/*    <img src="https://img02.mockplus.cn/preview/2024-06-17/nL1Lpvn3mw/48bb6f74-19fc-4f2f-b08e-88ed2108be39/images/%E9%80%82%E9%85%8D%E6%89%8B%E6%9C%BA%E7%AB%AF/u61.png" alt=""/>*/}
                {/*    <span>店外经营场所照片</span>*/}
                {/*  </Col>*/}
                {/*</Row>*/}
              </div>
              <div className='cardDetails'>
                <h4 className="cardDetails_title">机构信息</h4>
                <div className="mechanism_title">
                  <img src={mechanismInfo} alt=""/>
                  <h4>{personnelInfo.archivesName}</h4>
                </div>
                <Row className="mechanism">
                  <Col span={24}>
                    经营模式 <span>{personnelInfo.managementModelName}</span>
                  </Col>
                  <Col span={24}>
                    注册资本 <span>{personnelInfo.registerCapital}万元</span>
                  </Col>

                  <Col span={24}>
                    法定代表人 <span>{personnelInfo.legalPersonName}</span>
                  </Col>
                  <Col span={24}>
                    成立日期 <span>{personnelInfo.registerTime}</span>
                  </Col>

                  <Col span={24}>
                    品牌 <span>{personnelInfo.brandName}</span>
                  </Col>
                  <Col span={24}>
                    联系人 <span>{personnelInfo.contactPeople}</span>
                  </Col>

                  <Col span={24}>
                    联系电话 <span>{personnelInfo.complaintsHotline}</span>
                  </Col>
                  <Col span={24}>
                    所属地区 <span>{personnelInfo.administrativeArea}</span>
                  </Col>

                  <Col span={24}>
                    登记备案有效期 <span>{personnelInfo.deadlineForFilingValidity}</span>
                  </Col>

                  <Col span={24}>
                    登记备案编号 <span>{personnelInfo.recordCertificateNumber}</span>
                  </Col>

                  <Col span={24}>
                    统一社会信用代码 <span>{personnelInfo.businessLicenseNumber}</span>
                  </Col>

                  <Col span={24}>
                    机构住所 <span>{personnelInfo.registerAddress}</span>
                  </Col>

                  <Col span={24} style={{margin: '0'}}>
                    经营范围 <span>{personnelInfo.businessScope}</span>
                  </Col>
                </Row>
              </div>
              <div className='cardDetails'>
                <h4 className="cardDetails_title">行为记录</h4>
                <div style={{textAlign: "center", marginTop: '20px', fontSize: '0.8rem'}}>
                  黑名单
                  <Divider style={{marginTop: '10px', marginBottom: '10px'}}/>
                </div>
                {
                  blackList.length !== 0 ? <div>
                    {
                      blackList.map(item => {
                        return <Row style={{marginTop: '10px'}}>
                          <Col className="grey table borderBottom" span={11}>人员</Col>
                          <Col className="grey table borderLeft borderBottom" span={13}>{item.name}</Col>

                          <Col className="table borderBottom" span={11}>身份证号</Col>
                          <Col className="table borderLeft borderBottom" span={13}>
                            {item.socialCreditCode}
                          </Col>

                          <Col className="grey table borderBottom" span={11}>认定机构</Col>
                          <Col className="grey table borderLeft borderBottom" span={13}>
                            {item.accreditationAgency}
                          </Col>

                          <Col className="table borderBottom" span={11}>诚信指标</Col>
                          <Col className="table borderLeft borderBottom" span={13}>
                            {item.name}
                          </Col>

                          <Col className="grey table " span={11}>禁业期限</Col>
                          <Col className="grey table borderLeft" span={13}>
                            {item.publicityTime}
                          </Col>
                        </Row>
                      })
                    }
                  </div> : <Empty description="暂无黑名单记录"/>
                }
                <div style={{textAlign: "center", marginTop: '20px', fontSize: '0.8rem'}}>
                  不良行为
                  <Divider style={{marginTop: '10px', marginBottom: '10px'}}/>
                </div>
                {
                  badBehavior.length !== 0 ? <div>
                    {
                      badBehavior.map(item => {
                        return <Row style={{marginTop: '10px'}}>
                          <Col className="grey table borderBottom" span={11}>人员</Col>
                          <Col className="grey table borderLeft borderBottom" span={13}>{item.name}</Col>

                          <Col className="table borderBottom" span={11}>身份证号</Col>
                          <Col className="table borderLeft borderBottom" span={13}>
                            {item.socialCreditCode}
                          </Col>

                          <Col className="grey table borderBottom" span={11}>认定机构</Col>
                          <Col className="grey table borderLeft borderBottom" span={13}>
                            {item.accreditationAgency}
                          </Col>

                          <Col className="table borderBottom" span={11}>诚信指标</Col>
                          <Col className="table borderLeft borderBottom" span={13}>
                            {item.name}
                          </Col>

                          <Col className="grey table " span={11}>公示时间</Col>
                          <Col className="grey table borderLeft" span={13}>
                            {item.publicityTime}
                          </Col>
                        </Row>
                      })
                    }
                  </div> : <Empty description="暂无不良行为记录"/>
                }
                <div style={{textAlign: "center", marginTop: '20px', fontSize: '0.8rem'}}>
                  良好行为
                  <Divider style={{marginTop: '10px', marginBottom: '10px'}}/>
                </div>
                {
                  goodBehavior.length !== 0 ? <div>
                    {
                      goodBehavior.map(item => {
                        return <Row style={{marginTop: '10px'}}>
                          <Col className="grey table borderBottom" span={11}>人员</Col>
                          <Col className="grey table borderLeft borderBottom" span={13}>
                            {item.name}</Col>

                          <Col className="table borderBottom" span={11}>身份证号</Col>
                          <Col className="table borderLeft borderBottom" span={13}>
                            {item.socialCreditCode}
                          </Col>

                          <Col className="grey table borderBottom" span={11}>认定机构</Col>
                          <Col className="grey table borderLeft borderBottom" span={13}>
                            {item.accreditationAgency}
                          </Col>

                          <Col className="table borderBottom" span={11}>诚信指标</Col>
                          <Col className="table borderLeft borderBottom" span={13}>
                            {item.name}
                          </Col>

                          <Col className="grey table " span={11}>公示时间</Col>
                          <Col className="grey table borderLeft" span={13}>
                            {item.publicityTime}
                          </Col>
                        </Row>
                      })
                    }
                  </div> : <Empty description="暂无良好行为记录"/>
                }
              </div>
            </main>
            <footer>
              <div>
                <span>地址: 唐山铭扬国际4层</span>
                <span className="footer_left">电话: 0315-8080089</span>
              </div>
              <div style={{
                marginTop: '10px',
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }} className="">
                <span>Copyright @ 2024 唐山市房地产中介服务行业协会 版权所有</span>
                <span style={{marginTop: '10px'}}>ICP备案编号: 冀ICP备2023040514</span>
              </div>
            </footer>
          </div> : <div
              style={{width: '100%', display: "flex", alignItems: "center", justifyContent: "center", height: '100vh'}}>
            <Spin size="large"/>
          </div>
        }
        {/* 登记卡预览 */}
        <Modal
            title="登记卡预览"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            width={'500px'}
            style={{top: '100px'}}
            footer={null}
        >
          <img
              style={{width: '100%'}}
              src={`${personnelInfo.cardUrl}?x-oss-process=image/watermark,text_6aKE6KeI77yM5LiN5pSv5oyB5omT5Y2w5LiL6L29,color_dbd6d1,size_100,rotate_40,t_70,g_sw/watermark,text_6aKE6KeI77yM5LiN5pSv5oyB5omT5Y2w5LiL6L29,color_dbd6d1,size_100,rotate_40,t_70,g_ne`}
              alt=""/>
        </Modal>
        <Modal
            title="备案证书"
            open={recordCertificateOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            width={'500px'}
            style={{top: '100px'}}
            footer={null}
        >
          <Image
              preview={false}
              src={'https://qjprod-images.oss-cn-beijing.aliyuncs.com/tsfzx_institution/' + personnelInfo.othersImageList}
              alt=""/>
        </Modal>
      </div>
  );
};
