import axios from '../../axios'
import request_url from '../../../assest/utils/request_url'
let baseUrl = request_url('practitionersRegistration')
export default {
  // 获取表格数据
  peoplesPublicity(data) {
    return axios.post(`${baseUrl}organization/peoplesPublicity`,data)
  },
  // 获取表头
  peoplesPublicityTableHeader() {
    return axios.post(`${baseUrl}organization/tableHeader`, {requestParam:'peoplesPublicity'})
  },
}
