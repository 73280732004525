let requestUrls = {
  enterpriseRecord:{
    dev:"/api1",
    pred:"https://tqjcrm.qianjiaonline.com/qjcrm/",
    prod:"https://tqjcrm.qianjiaonline.com/qjcrm/"
  },
  practitionersRegistration: {
    dev: "/api2",
    pred:"https://thuman.qianjiaonline.com/organization/v2/",
    prod:"https://thuman.qianjiaonline.com/organization/v2/"
  },
  imgUrl: {
    dev: "https://qjdev-pred-images.oss-cn-beijing.aliyuncs.com/merchant/",
    pred: "https://qjdev-pred-images.oss-cn-beijing.aliyuncs.com/merchant/",
    prod:"https://qjprod-images.oss-cn-beijing.aliyuncs.com/tsfzx_institution/"
  },
  institutionDetail: {
    dev: "/api3",
    pred: "https://tqjcrmapi.tsfzx.com/",
    prod: "https://tqjcrmapi.tsfzx.com/"
  },
}
export default (name) => {
  return requestUrls[name][process.env.REACT_APP_ENV]
}
